/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 */

import { Config } from "src/config";

import { BoardAPI } from "./Board";
import { ContactAPI } from "./Contact";
import { ItemAPI } from "./Item";
import { SessionAPI } from "./Session";
import { UserAPI } from "./User";
import { TeamAPI } from "./Team";

class API {
  constructor() {
    this.host = Config.get("host");
    this.version = "1.0";

    // Actual API.
    this.Session = new SessionAPI(this);
    this.Board = new BoardAPI(this);
    this.Item = new ItemAPI(this);
    this.Team = new TeamAPI(this);
    this.User = new UserAPI(this);
    this.Contact = new ContactAPI(this);
  }

  secureURL(suffix) {
    if (suffix[0] === "/") {
      suffix = suffix.substring(1);
    }
    return `${this.host}/api/${this.version}/${suffix}`;
  }

  sendRequest(suffix, options) {
    options.url = this.secureURL(suffix);
    return $.ajax(options);
  }

  // Fixes for specific cases.

  // Some versions of Firefox like to add unicode carraige returns.
  // We need to make sure these aren't sent to the server. This might
  // be able to be done on the server side; however, it's traditionally
  // done on the client.
  removeUnicodeCarriageReturns(string) {
    string = string.replace(/\\u000a\\u000d/g, "\\r\\n");
    string = string.replace(/\\u000a/g, "\\r\\n");
    return string;
  }
}

class NoteApp {
  constructor() {
    this.API = new API();
  }
}

const instance = new NoteApp();
export { instance as NoteApp };
