import { Config } from "src/config";
import { NoteApp } from "src/api/v1";

import { EventManager } from "src/helpers/EventManager";

class AccessControl {
  // Allow components to subscribe to updates.
  eventManager;

  // Permissions hash returned from load();
  permissions = null;

  // Not sure if the next two should be used.
  SUBSCRIBER_COOKIE_NAME = "subscriber_credentials";
  CORK_COOKIE_NAME = "cork_credentials";
  ACCESS_TYPE = "access_type";
  OPEN = "open";

  PRIVATE = "private";
  POST_AND_EDIT_ALLOWED = "post_and_edit_allowed";
  DELETE_ALLOWED = "delete_allowed";
  CHAT_ALLOWED = "chat_allowed";
  UPLOAD_ALLOWED = "upload_allowed";

  constructor() {
    this.eventManager = new EventManager();
    this.permissions = {};
  }

  // Set the permissions hash. Used on cork load.
  setPermissions(permissions) {
    this.permissions[this.ACCESS_TYPE] = permissions[this.ACCESS_TYPE];
    this.permissions[this.POST_AND_EDIT_ALLOWED] = permissions[this.POST_AND_EDIT_ALLOWED];
    this.permissions[this.DELETE_ALLOWED] = permissions[this.DELETE_ALLOWED];
    this.permissions[this.CHAT_ALLOWED] = permissions[this.CHAT_ALLOWED];
    this.permissions[this.UPLOAD_ALLOWED] = permissions[this.UPLOAD_ALLOWED];
  }

  // Set individual permissions. Used when settings changed.
  setPermission(permission, value) {
    this.permissions[permission] = value;
  }

  getPermission(permission) {
    return this.permissions[permission];
  }

  isLoggedIn() {
    return Config.get("user").isLoggedIn();
  }

  isAccountAdmin() {
    const board = Config.get("board");
    const account_id = board.belongs_to;

    return Config.get("user").admin_accounts.has(account_id);
  }

  isAccountMember() {
    const board = Config.get("board");
    const account_id = board.belongs_to;

    return Config.get("user").accounts.has(account_id);
  }

  isBoardOwned() {
    return Config.get("board").owned;
  }

  isAllowedIn() {
    const user = Config.get("user");
    const board = Config.get("board");

    let { user_limit } = board.account.plan;
    if (new Date(board.account.created_at) < new Date("Jan 11 2014")) {
      user_limit = 0;
    }

    return user_limit === 0 || user.entrance_time_position() + 1 <= user_limit;
  }

  // TODO: Remove reliance on Config.isViewOnlyURL() completely,
  // and have server return correct permissions.
  postAndEditAllowed() {
    return (
      !Config.isViewOnlyURL() &&
      (this.isAccountAdmin() || this.getPermission(this.POST_AND_EDIT_ALLOWED))
    );
  }

  deleteAllowed() {
    return (
      !Config.isViewOnlyURL() &&
      (this.isAccountAdmin() || this.getPermission(this.DELETE_ALLOWED))
    );
  }

  chatAllowed() {
    return (
      !Config.isViewOnlyURL() &&
      (this.isAccountAdmin() || this.getPermission(this.CHAT_ALLOWED))
    );
  }

  uploadAllowed() {
    return (
      !Config.isViewOnlyURL() &&
      (this.isAccountAdmin() || this.getPermission(this.UPLOAD_ALLOWED))
    );
  }

  // This is only intended to be called once per session for now.
  setLoggedIn() {
    this.eventManager.dispatch("loggedin", Config.get("user"));
  }

  login(email, password, success, failure) {
    const intermediary = (data, textStatus, jqXHR) => {
      Config.get("user").set(data);
      this.setLoggedIn();
      if (success != null) {
        success(data, textStatus, jqXHR);
      }
    };

    NoteApp.API.Session.create({
      email,
      password,
      success: intermediary,
      failure
    });
  }

  logout(callback) {
    NoteApp.API.Session.destroy({
      success: callback
    });
  }

  loginCork(string_identifier, password, success, failure) {
    NoteApp.API.Session.Board.create({
      string_identifier,
      password,
      success,
      failure
    });
  }

  subscribe(event, handler) {
    this.eventManager.subscribe(event, handler);
  }
}

const instance = new AccessControl();
export { instance as AccessControl };
