import React from "react";

import { Config } from "src/config";

import defaultImg from "img/users/default-24x24.png";

class UserIcon extends React.Component {
  static DEFAULT_USER_IMAGE_URL = defaultImg;

  constructor(props, context) {
    super(props, context);
    const user = props.user;

    if (user) {
      this.state = {
        containerClass: user === Config.get("user") ? "current" : "guest",
        imageClass: user.clientId,
        imageUrl: user.image_url || UserIcon.DEFAULT_USER_IMAGE_URL,
        title: user.displayName()
      };
    } else {
      this.state = {
        containerClass: "guest",
        imageUrl: UserIcon.DEFAULT_USER_IMAGE_URL
      };
    }
  }

  componentDidMount() {
    this.setupEventHandlers();
  }

  setupEventHandlers() {
    const user = this.props.user;
    if (!user) {
      return;
    }

    user.on("image_url_change", (event, newURL) =>
      this.setState({ imageUrl: newURL })
    );

    user.on("clientId_change", (event, newClientId, oldClientId) => {
      this.setState({ imageClass: newClientId });
    });

    user.on("first_name_change", () => this.setState({ title: user.displayName() }));

    user.on("last_name_change", () => this.setState({ title: user.displayName() }));
  }

  render() {
    return (
      <div title={this.props.title} className={`user-icon ${this.state.containerClass}`}>
        <img
          className={this.state.imageClass}
          title={this.state.title}
          src={this.state.imageUrl}
        ></img>
      </div>
    );
  }
}

export { UserIcon };
