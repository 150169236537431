/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */

import { Point } from "./Point";

class Rectangle {
  x1 = null;
  y1 = null;
  x2 = null;
  y2 = null;

  constructor(x1, y1, x2, y2) {
    if (x1 instanceof Point && y1 instanceof Point) {
      const first = x1;
      const second = y1;
      x1 = first.x;
      y1 = first.y;
      x2 = second.x;
      y2 = second.y;
    } else if (x1 instanceof jQuery) {
      const position = x1.position();
      const width = x1.width();
      const height = x1.height();
      x1 = position.left;
      y1 = position.top;
      x2 = x1 + width;
      y2 = y1 + height;
    }
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
  }

  left() {
    return this.x1;
  }

  right() {
    return this.x2;
  }

  top() {
    return this.y1;
  }

  bottom() {
    return this.y2;
  }

  topLeft() {
    return new Point(this.x1, this.y1);
  }

  bottomRight() {
    return new Point(this.x2, this.y2);
  }

  center() {
    return new Point(this.x1 + this.width() / 2, this.y1 + this.height() / 2);
  }

  width() {
    return Math.abs(this.x2 - this.x1);
  }

  height() {
    return Math.abs(this.y2 - this.y1);
  }

  contains(rectangle) {
    return (
      rectangle.x1 >= this.x1 &&
      rectangle.y1 >= this.y1 &&
      rectangle.x2 <= this.x2 &&
      rectangle.y2 <= this.y2
    );
  }

  include(rectangle) {
    if (!this.contains(rectangle)) {
      this.x1 = Math.min(rectangle.x1, this.x1);
      this.y1 = Math.min(rectangle.y1, this.y1);
      this.x2 = Math.max(rectangle.x2, this.x2);
      this.y2 = Math.max(rectangle.y2, this.y2);
    }
  }

  scale(scale) {
    this.x1 *= scale;
    this.y1 *= scale;
    this.x2 *= scale;
    this.y2 *= scale;
  }

  translate(x, y) {
    this.x1 += x;
    this.x2 += x;
    this.y1 += y;
    this.y2 += y;
  }
}

export { Rectangle };
