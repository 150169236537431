import { Config } from "src/config";

import { Container } from "src/base/Container";
import { Attachment } from "src/models/Attachment";
import { Item } from "src/models/Item";

import { AccessControl } from "./AccessControl";

import { MathExtras } from "src/utils/MathExtras";

class UploadManager {
  // Used to set some default values and normalize file upload events
  // into application specific events.

  // See below for more info:
  // https://github.com/blueimp/jQuery-File-Upload/wiki/Basic-plugin
  // https://github.com/blueimp/jQuery-File-Upload/wiki/Options

  // Normalized events that will be triggered on zone:
  //
  // 	"uploaddrop"     : data, dropEvent
  //   "uploadstarted"  : uploadID, data
  //   "uploadprogress" : uploadID, data, percentage
  //   "uploaddone"     : uploadId, data
  //
  // - dropEvent holds the location of the drop as a mouse event.
  // - percentage is the current upload progress percentage.
  // - data holds information returned from the server, like the URL.
  //
  // TODO: This is heavily coupled to uploading attachments. It shouldn't be.
  watch(zone) {
    const manager = this;
    let zoneElement = zone;
    if (zone instanceof Container) {
      // For instance, the board.
      zoneElement = zone.container;
      zone = $(zone);
    } else if (zone instanceof Item) {
      // Specific drop zone for items -- use the view.
      zoneElement = zone.view.container;
      zone = zone.view;
    } else {
      // Something else? Ensure it's a jQuery element.
      // This is a rare case.
      zone = $(zone);
    }

    // Create an input element used for the upload.
    // This isn't added to the DOM.
    const input = $(document.createElement("input"));
    input.attr("type", "file");
    input.attr("name", "file");
    input.on("fileuploaddragover", (event, data) =>
      zone.trigger("uploaddragover")
    );

    input.on("fileuploaddrop", function (event, data) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.originalEvent.stopPropagation();
      event.originalEvent.stopImmediatePropagation();

      // Do they have permissions?
      const allowed = manager.checkPermissions();
      if (allowed === false) {
        return false;
      }

      // If there are no files in this drop, then something else was
      // dropped. What? Who knows. But this will prevent errors down
      // the chain.
      if (!data.files || !data.files[0]) {
        return false;
      }

      // Check file size here to prevent new note from being created
      // if files are too big.
      if (manager.checkFileSize(data) === false) {
        return false;
      }
      const dropEvent = event.originalEvent.delegatedEvent;
      dropEvent.stopPropagation();
      dropEvent.stopImmediatePropagation();

      // Trigger an uploaddrop event. This can be one or multiple files.
      const dropResult = zone.triggerHandler("uploaddrop", [data, dropEvent]);

      // Allow handler to stop an upload (for instance, an drop attempted on
      // a free board).
      if (dropResult === false) {
        return false;
      }
    });

    this.addSharedInputHandlers(input, zone);
    return input.fileupload(this.options({ dropZone: zoneElement }));
  }

  // Handle file uplaods from a given input. In this case, the item
  // is already known, so its view is
  handle(input, receiver, board) {
    if (!receiver) {
      receiver = input;
    }

    // Force the name to be "file" because that's required by
    // the server.
    input.attr("name", "file");
    this.addSharedInputHandlers(input, receiver, board);
    return input.fileupload(this.options());
  }

  // receiver can be a drop zone or any object receiving fileupload events.
  addSharedInputHandlers(input, receiver, board) {
    const manager = this;
    receiver = $(receiver);

    input.on("fileuploadsubmit", function (event, data) {
      // Check permissions here because this is the last line of
      // defense if not checked elsewhere.
      const allowed = manager.checkPermissions();
      if (allowed === false) {
        return false;
      }

      // Check file size here because this is the last line of
      // defense if not checked elsewhere.
      if (manager.checkFileSize(data) === false) {
        return false;
      }

      // Set the URL here add just in case the identifier changes between
      // watch()/handle() time and drop time.
      if (!board) {
        board = Config.get("board");
      }

      data.url = `/cork/${board.string_identifier}/attachment`;

      for (let file of data.files) {
        // Create an attachment object not in the main index.
        // Add it to the file object so we can get it in other events later.
        file.attachment = new Attachment({
          content_type: file.type,
          size_in_bytes: file.size
        });

        // For the datastore; since we can't use the datastore through this
        // plugin, let's alert the datastore we have requests processing.
        if (Config.isSet("datastore")) {
          Config.get("datastore").requestStarted();
        }
        receiver.trigger("uploadstarted", [file.attachment, data]);
      }
    });

    input.on("fileuploadprogress", function (event, data) {
      event.stopPropagation();
      event.stopImmediatePropagation();

      // Note that since we turned singleFileUploads on, we receive this
      // for each request, meaning there's only one file per event.
      const { attachment } = data.files[0];
      const progress = parseInt((data.loaded / data.total) * 100, 10);
      receiver.trigger("uploadprogress", [attachment, progress, data]);
    });

    input.on("fileuploaddone", function (event, data) {
      event.stopPropagation();
      event.stopImmediatePropagation();

      // Note that since we turned singleFileUploads on, we receive this
      // for each request, meaning there's only one file per event.
      const { attachment } = data.files[0];
      attachment.set(data.result.attachment);

      // Add the attachment to the main list.
      Attachment.add(attachment);

      // For the datastore; since we can't use the datastore through this
      // plugin, let's alert the datastore the request is finished.
      if (Config.isSet("datastore")) {
        Config.get("datastore").requestFinished();
      }

      receiver.trigger("uploaddone", [attachment, data]);
    });

    input.on("fileuploadfail", function (event, data) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (Config.isSet("datastore")) {
        Config.get("datastore").requestFinished();
      }
      receiver.trigger("uploadfail", [data.uploadId]);
      const humanReadable = MathExtras.megabytes(Config.get("maximumFileSize"));
      alert(
        "Error processing upload. Make sure files are less than " +
        humanReadable +
        " Mb each. If you continue to see this error, contact support at support@noteapp.com."
      );
    });
  }

  checkFileSize(data) {
    // Ensure no files added exceed the limit.
    for (let file of data.files) {
      if (file.size > Config.get("maximumFileSize")) {
        const humanReadable = MathExtras.megabytes(
          Config.get("maximumFileSize")
        );

        alert(
          "Can't upload: Files must be less than " + humanReadable + " Mb each."
        );

        return false;
      }
    }

    return true;
  }

  options(custom) {
    custom = custom || {};
    const defaults = {
      singleFileUploads: true,
      sequentialUploads: false,
      progressInterval: 20
    };

    for (let key in custom) {
      if (custom.hasOwnProperty(key)) {
        defaults[key] = custom[key];
      }
    }
    return defaults;
  }

  checkPermissions() {
    // If we're running inside the dashboard, the dashboard
    // will manage the permissions.
    if (!Config.isSet("application")) {
      return true;
    }

    const app = Config.get("application");
    if (!AccessControl.postAndEditAllowed()) {
      app.showReadOnlyMessage();
      return false;
    }
    if (!AccessControl.uploadAllowed()) {
      app.showReadOnlyMessage(
        "You don't have permission to upload files and images. Contact the owner of this board for more information."
      );
      return false;
    }
    return true;
  }
}

const instance = new UploadManager();
export { instance as UploadManager };
