/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
import Rangy from "rangy";
import React from "react";
import { debounce } from "lodash";

import { Config } from "src/config";

import { ItemView } from "./ItemView";

import { FileTypeStyle } from "src/base/FileTypeStyle";

import { RichTextArea } from "src/controls/RichTextArea";

import { AccessControl } from "src/helpers/AccessControl";
import { FocusManager } from "src/helpers/FocusManager";
import { UploadManager } from "src/helpers/UploadManager";

import { Device } from "src/utils/Device";

class PostItView extends ItemView {
  wrapper = null;
  input = null;
  setFocus = false;
  minWidth = 200;
  minHeight = 100;
  color = null;
  showRemoveButtons = false;
  attachmentContainer = null;
  progressIndex = null;

  constructor(item) {
    super(item);
    this.progressIndex = {};
    this.color = this.item.object.color || "yellow";
    const view = this;
    this.wrapper = $(document.createElement("div"));
    this.wrapper.addClass("wrapper antiscroll-wrap");
    this.input = RichTextArea.create(null, "data", !Device.isMobile());
    this.input.addClass("antiscroll-inner");

    // Start disabled if iOS.
    if (Device.isMobile()) {
      RichTextArea.disable(this.input);
    }
    RichTextArea.setHtml(this.input, item.object.content);
    this.setColor(this.item.object.color);
    this.wrapper.append(this.input);
    this.container.addClass("post_it");
    this.container.width(item.object.width || "");
    this.container.height(item.object.height || "");
    const shadow = $(document.createElement("div"));
    shadow.addClass("shadow ei-bottom-left-shadow");
    this.container.append(shadow);

    // Attachments related.
    this.attachmentsContainer = $(document.createElement("div"));
    this.attachmentsContainer.addClass("attachments");
    const separator = $(document.createElement("div"));
    separator.addClass("separator");
    this.deleteAttachments = $(document.createElement("div"));
    this.deleteAttachments.addClass("delete-attachments");
    this.deleteAttachments.addClass("ei-item-delete-attachments");
    this.deleteAttachments.on("mousedown mousemove", function (event) {
      // Prevent drag on the delete attachments button.
      event.preventDefault();
      return event.stopPropagation();
    });

    this.deleteAttachments.on("click", function () {
      view.toggleRemoveButtons();
      return view.resizeAttachmentTitles();
    });

    this.attachmentsContainer.append(separator);
    this.attachmentsContainer.append(this.deleteAttachments);
    this.progressContainer = $(document.createElement("div"));
    this.progressContainer.addClass("progress");

    // TODO: Pull this out in its own control.
    this.progressBar = $(document.createElement("div"));
    this.progressBar.addClass("ui-progress-bar ui-container transition");
    const inner = $(document.createElement("div"));
    inner.addClass("ui-progress");
    inner.css("width", "100%");
    this.progressBar.append(inner);
    this.progressContainer.append(this.progressBar);

    // Start hidden.
    this.progressContainer.hide();
    this.attachmentsContainer.append(this.progressContainer);
    this.attachmentsContainer.hide();
    this.container.append(this.attachmentsContainer);
    item.on("width_change", function (event, width) {
      if (width < view.minWidth) {
        view.item.object.set("width", view.minWidth);
      }

      // Just to make sure, say, we resize after showing
      // the remove buttons.
      view.resizeAttachmentTitles();
      return view.addScrollbarsConditionally();
    });

    item.on("height_change", function (event, height) {
      const minimum = view.minHeight + view.attachmentsContainer.outerHeight();
      if (height < minimum) {
        // will trigger this function again.
        view.item.object.set("height", minimum);
      } else {
        // Ensure the wrapper and the attachments container
        // are always positioned evenly.

        //height: "auto",
        view.wrapper.css({ bottom: view.attachmentsContainer.outerHeight() });
      }
      return view.addScrollbarsConditionally();
    });

    item.object.on("color_change", (event, color) => view.setColor(color));

    item.object.on("content_change", function (event, content) {
      RichTextArea.setHtml(view.input, content);
      return view.addScrollbarsConditionally();
    });

    this.addHandlers();

    // After all the other rendering, make sure things look good.
    this.resizeAttachmentTitles();
  }

  addHandlers() {
    this.item.on("remove", () =>
      // Some browsers have a habit of leaving the the cursor where it was
      // graphically even though the input does not exist on the dom anymore.
      this.input.trigger("blur")
    );

    // Update the view if the PostIt gets an attachment.
    this.item.object.attachments.on("add", (event, attachment) =>
      this.addAttachment(attachment)
    );

    // Update the view if an attachment is removed.
    this.item.object.attachments.on("remove", (event, attachment) =>
      this.removeAttachment(attachment)
    );

    UploadManager.watch(this);
    this.input.on(
      "changed",
      debounce(() => {
        // Update the new content but don't fire a change. That'll cause the
        // content to be updated a second time and cause weird UI issues.
        this.item.object.set("content", RichTextArea.getHtml(this.input), {
          quiet: true
        });

        // Save the change on the server.
        this.item.save();

        // Add scrollbars, but don't blink them if they're already there.
        // Allow them to blink in IE, or else we'll get traditional scrollbars.
        this.addScrollbarsConditionally();
      }, this.SAVE_THRESHOLD)
    );

    this.container.on("mousedown", () => FocusManager.focus(this.item));

    // This is for if the padding around the input is clicked on.
    // Because of this, we need the function below. May cause trouble.
    // Also don't propagate selection down to the container, which
    // is draggable -- if it's propagated, selection won't be
    // allowed.
    //this.input.on("mousedown selectstart", function(event) {
    //			event.stopPropagation();
    //		});
    this.input.on("mousedown click", event => {
      // See container's mousedown.
      event.stopPropagation();

      // Focus the item.
      FocusManager.focus(this.item);
    });
  }

  handleUploadStart(file) {
    this.showProgressBar(file);
  }

  handleUploadProgress(file, progress) {
    this.setProgress(file, progress);
  }

  handleUploadComplete(attachment) {
    this.item.object.attachments.add(attachment);
    this.saveAfterAnimation(attachment);
  }

  // Add an attachment to the view. This does not add it to the item,
  // but is called when an attachment is added to the item.
  showProgressBar(file) {
    // If we're already showing, do nothing.
    if (!this.progressContainer.is(":hidden")) {
      return;
    }
    this.attachmentsContainer.show();
    this.animateDiv(this.progressContainer);
    this.setProgress(file, 100);
  }

  setProgress(file, percentage) {
    this.progressIndex[file.id] = percentage;
    const bar = this.progressContainer.find(".ui-progress");

    // If we're going down from 100, this must mean it's
    // the first progress indicator we've gotten.
    // Let's start at zero.
    if (this.progressIndex[file.id] === 100 && percentage !== 100) {
      bar.width(0);
    }

    let totalPercentage = 0;
    let count = 0;

    for (let id in this.progressIndex) {
      if (this.progressIndex[id] < 100) {
        totalPercentage += this.progressIndex[id];
        count += 1;
      }
    }

    // Find out the total percentage, being extra
    // careful about divide by zeroes.
    totalPercentage /= count || 1;
    if (totalPercentage < 5) {
      totalPercentage = 5;
    }
    bar.stop();
    bar.animate({ width: totalPercentage + "%" }, { duration: 200 });
  }

  // Remember: This adds an attachment to the *view*,
  // not the item. Handlers listen to changes in the
  // item and automatically call this function.
  addAttachment(finished) {
    const view = this;

    // If addAttachment is called w/o showProgressBar, for instance,
    // on load, we need to show this explicitly here.
    this.attachmentsContainer.show();

    // If we get to this point, the attachment is 100% downloaded.
    // Let make sure that's explicitly set.
    this.progressIndex[finished.clientId] = 100;
    const div = $(document.createElement("div"));
    div.addClass("attachment");
    div.attr("id", finished.clientId);
    const image = $(document.createElement("span"));
    image.addClass("image");
    image.addClass(
      FileTypeStyle.get(finished.content_type, finished.original_filename)
    );
    const title = $(document.createElement("a"));
    title.addClass("title");
    title.attr("href", finished.url);
    title.attr("target", "_blank");

    // Mobile Safari doesn't respect content-disposition because there's
    // nowhere to save stuff. In this case, ensure we have a new tab open.
    if (Device.isMobile()) {
      title.attr("target", "_blank");
    }
    title.append(image);
    title.append(finished.title);
    const remove = $(document.createElement("span"));
    remove.addClass("remove");
    remove.addClass("ei-item-remove-small");
    div.append(title);
    div.append(remove);
    if (this.showRemoveButtons === false) {
      remove.hide();
    }
    remove.on("click", function (event) {
      finished.remove();

      // Save here to ensure we only save on user interaction
      // when removing an attachment.
      //view.item.save();
      view.saveAfterAnimation(finished);

      // Prevent a download of the file...
      event.preventDefault();
      return event.stopPropagation();
    });

    // Prevent browser from underlining attachment.
    remove.on("mouseenter", () => title.css("text-decoration", "none"));

    remove.on("mouseleave", () => title.css("text-decoration", ""));

    remove.on("mousedown mousemove", function (event) {
      event.stopPropagation();
      return event.preventDefault();
    });

    // Prevent dragging from an attachment, and prevent the default
    // behavior of being able to drag a link. Each cause weird errors
    // in some browsers.
    title.on("mousedown", function (event) {
      event.stopPropagation();
      return event.preventDefault();
    });

    let stillDownloading = false;
    for (let clientId in this.progressIndex) {
      if (this.progressIndex[clientId] < 100) {
        stillDownloading = true;
        break;
      }
    }

    //this.progressContainer.hide();
    if (stillDownloading === false) {
      this.animateDiv(this.progressContainer, "hide", false);
    }
    div.hide();

    // Add the item after the last attachment in the container;
    // we do this instead of append to ensure proper behavior
    // when the progress bar is showing.
    const lastAttachment = this.attachmentsContainer.find(".attachment").last();
    if (lastAttachment.get(0) == null) {
      this.attachmentsContainer.prepend(div);
    } else {
      lastAttachment.after(div);
    }

    // If we're initializing, no animation needed.
    if (Config.get("loaded") === false) {
      div.show();

      // We're not animating? Just update the wrapper.
      view.wrapper.css({ bottom: this.attachmentsContainer.outerHeight() });
    } else {
      // Woot! Animation!
      this.animateDiv(div, "show");
    }
    return this.resizeAttachmentTitles();
  }

  removeAttachment(attachment) {
    const div = $("#" + attachment.clientId);
    this.animateDiv(div, "hide");
    if (!this.hasAttachments()) {
      this.attachmentsContainer.fadeOut(230);
      return this.toggleRemoveButtons(false);
    }
  }

  hasAttachments() {
    return this.item.object.attachments.length > 0;
  }

  toggleRemoveButtons(newValue) {
    newValue = newValue === undefined ? !this.showRemoveButtons : newValue;
    const removeButtons = this.attachmentsContainer.find(".remove");
    if (newValue === true) {
      // Toggle it on.
      this.deleteAttachments.addClass("on");
      removeButtons.show(); //fadeIn(200);
    } else {
      // Toggle it off.
      this.deleteAttachments.removeClass("on");
      removeButtons.hide();
    }
    return (this.showRemoveButtons = newValue);
  }

  // Animate this attachment in or out. Second parameter
  // is whether or not to show or hide it; valid values are
  // "show" or "hide". Unless remove === false, "hide" will
  // remove the div from the DOM. Otherwise it hides it.
  //
  // Uses technique from here for animation:
  // http://stackoverflow.com/questions/3216230/jquery-recreate-slidedown-effect-using-the-animation-function
  animateDiv(div, display, remove) {
    const view = this;
    display = display === "show" || display === "hide" ? display : "show";
    const attachmentsHeight = this.attachmentsContainer.outerHeight(true);
    const containerHeight = this.container.outerHeight(true);

    // Lock the attachmentsContainer in place for the animation.
    // It's a little "wobbly" otherwise.
    this.attachmentsContainer.css({
      top: this.attachmentsContainer.position().top / Config.get("scale"),
      bottom: "auto"
    });

    const setHeight = function () {
      const newAttachmentsHeight = view.attachmentsContainer.outerHeight(true);
      const difference = newAttachmentsHeight - attachmentsHeight;
      return view.item.object.set("height", containerHeight + difference);
    };

    div.animate(
      {
        height: display,
        marginTop: display,
        marginBottom: display,
        paddingTop: display,
        paddingBottom: display
      },
      {
        duration: 230,
        step() {
          return setHeight();
        }
      }
    );

    return div.promise().done(function () {
      setHeight();
      view.attachmentsContainer.css({
        top: "auto",
        bottom: 0
      });

      if (display === "hide") {
        if (remove === false) {
          return div.hide();
        } else {
          return div.remove();
        }
      }
    });
  }

  // Call a save once the attachment's div has finished
  // animating.
  saveAfterAnimation(attachment) {
    const view = this;
    return $("#" + attachment.clientId)
      .promise()
      .done(() => view.item.save());
  }

  resizeAttachmentTitles() {
    const removeButtons = this.attachmentsContainer.find(".remove");
    const titles = this.attachmentsContainer.find(".title");
    if (this.showRemoveButtons === true) {
      // Set the width of the titles to account for the remove buttons.
      return titles.css({
        width: this.attachmentsContainer.width() - removeButtons.outerWidth()
      });
    } else {
      // Reset to original widths.
      return titles.css("width", "");
    }
  }

  // Functions used by the FocusManager.
  focus() {
    if (Device.isMobile()) {
      RichTextArea.enable(this.input);
    } else {
      this.input.trigger("focus");
    }
    return super.focus(...arguments);
  }

  blur() {
    this.blurSelection();
    if (Device.isMobile()) {
      RichTextArea.disable(this.input);
    }
    return super.blur(...arguments);
  }

  render() {
    const view = this;
    this.container.append(this.wrapper);
    this.addScrollbarsConditionally();

    // Load up any attachments. We do this here because there's
    // some height magic involved.
    return this.item.object.attachments.each(attachment =>
      view.addAttachment(attachment)
    );
  }

  setColor(color) {
    this.container.removeClass(this.color);
    this.color = color;
    this.container.addClass(this.color);
    return $(this).trigger("rerender");
  }

  getButtonState() {
    const buttonState = super.getButtonState();

    return {
      ...buttonState,
      color: AccessControl.postAndEditAllowed(),
      upload: AccessControl.postAndEditAllowed() && AccessControl.uploadAllowed()
    }
  }

  hasSelectionFocus() {
    return document.activeElement === this.input.get(0);
  }

  blurSelection() {
    if (this.hasSelectionFocus()) {
      this.input.trigger("blur");
      return Rangy.getSelection().removeAllRanges();
    }
  }

  addScrollbarsConditionally() {
    if (this.wrapper.data("antiscroll")) {
      this.wrapper.data("antiscroll").destroy();
    }
    this.input.css({
      width: "",
      height: ""
    });

    const input = this.input.get(0);
    if (input.offsetHeight < input.scrollHeight) {
      this.wrapper.antiscroll({ x: false });

      // Prevent a drag from happening when the scrollbar is moved.
      return this.container
        .find(".antiscroll-scrollbar")
        .on("mousedown", event => event.stopPropagation());
    }
  }
}

export { PostItView };
