class ContactAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  makeContact({ from_email, body, success, failure, complete }) {
    this.API.sendRequest("/contact", {
      type: "POST",
      data: JSON.stringify({ from_email, body }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

export { ContactAPI };
