class ThemeAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  wallpaper({ string_identifier }) {
    return this.API.secureURL(`/theme/${string_identifier}/wallpaper`);
  }

  // wallpaper is a hash of values corresponding to what the
  // endpoint accepts.
  setWallpaper({ string_identifier, wallpaper, success, failure, complete }) {
    this.API.sendRequest(`/theme/${string_identifier}/wallpaper`, {
      type: "POST",
      data: JSON.stringify(wallpaper),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json"
    });
  }
}

class BoardAPI {
  API;

  constructor(API) {
    this.API = API;

    this.Theme = new ThemeAPI(API);
  }

  // All functions require the correct permissions,
  // and in most cases a session to be created first.

  fetch({ string_identifier, success, failure, complete }) {
    this.API.sendRequest(`/cork/${string_identifier}.json`, {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  fetch_read_only({ string_identifier, success, failure, complete }) {
    this.API.sendRequest(`/cork/${string_identifier}/load_read_only`, {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  create({ team_id, success, failure, complete } = {}) {
    this.API.sendRequest(`/team/${team_id}/add_board`, {
      type: "POST",
      data: JSON.stringify({}),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  copy({ string_identifier, success, failure, complete }) {
    this.API.sendRequest(`/cork/${string_identifier}/copy`, {
      type: "PUT",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  destroy({ string_identifier, success, failure, complete }) {
    this.API.sendRequest(`/cork/${string_identifier}`, {
      type: "DELETE",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  changeIdentifier({ old_identifier, new_identifier, success, failure }) {
    this.API.sendRequest(`/cork/${old_identifier}/change_identifier`, {
      type: "POST",
      data: JSON.stringify({ string_identifier: new_identifier }),
      success,
      error: failure,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  changeName({ string_identifier, new_name, success, failure }) {
    this.API.sendRequest(`/cork/${string_identifier}/change_name`, {
      type: "POST",
      data: JSON.stringify({ name: new_name }),
      success,
      error: failure,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  changePermissions({ string_identifier, permissions, success, failure }) {
    this.API.sendRequest(`/cork/${string_identifier}/save_settings`, {
      type: "POST",
      data: JSON.stringify({ permissions }),
      success,
      error: failure,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  addItem({ string_identifier, item, success, failure, complete }) {
    return this.addItems({
      string_identifier,
      items: item,
      success,
      failure,
      complete
    });
  }

  addItems({ string_identifier, items, success, failure, complete }) {
    if (!(items instanceof Array)) {
      items = [items];
    }
    items = items.map(item => item.serialize());

    let json = JSON.stringify(items);
    json = this.API.removeUnicodeCarriageReturns(json);

    this.API.sendRequest(`/cork/${string_identifier}/items`, {
      type: "PUT",
      data: json,
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  exportToCSV(string_identifier) {
    return window.open(this.API.secureURL(`/cork/${string_identifier}.csv`));
  }

  exportToPNG(string_identifier) {
    return window.open(this.API.secureURL(`/cork/${string_identifier}.png`));
  }

  deleted_items({ string_identifier, page, success, failure, complete }) {
    this.API.sendRequest(`/cork/${string_identifier}/deleted_items/${page}`, {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

export { BoardAPI };
