/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */

import { Point } from "src/base/Point";
import { Rectangle } from "src/base/Rectangle";

class Container {
  // Every Container will have a container object that represents
  // a jQuery element. Note that this element must be instantiated
  // by the extending class.

  // The Container class is only meant to be extended.
  container;

  append(container) {
    if (container instanceof Container) {
      ({ container } = container);
    }
    return this.container.append(container);
  }

  remove() {
    return this.container.remove();
  }

  detach() {
    return this.container.detach();
  }

  hide() {
    return this.container.hide();
  }

  css(propertyName, value) {
    return this.container.css(propertyName, value);
  }

  addClass(className) {
    return this.container.addClass(className);
  }

  removeClass(className) {
    return this.container.removeClass(className);
  }

  width() {
    return this.container.width();
  }

  height() {
    return this.container.height();
  }

  outerWidth(includeMargin) {
    return this.container.outerWidth(!!includeMargin);
  }

  outerHeight(includeMargin) {
    return this.container.outerHeight(!!includeMargin);
  }

  position() {
    const position = this.container.position();
    return new Point(position.left, position.top);
  }

  getContainer() {
    return this.container;
  }
}

export { Container };
