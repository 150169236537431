/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */

import { Point } from "src/base/Point";

import { MathExtras } from "src/utils/MathExtras";

class TouchDetector {
  lastTouches = null;
  baseIdentifier = null;

  // Here to prevent wobbly two finger drags.
  pinchDistanceThreshold = 3;

  nextTouch = null;

  constructor(element) {
    const detector = this;
    element = $(element);
    this.lastTouches = {};
    element.on("touchstart", event =>
      $.each(event.originalEvent.touches, function(index, touch) {
        detector.lastTouches[touch.identifier] = touch;
        if (detector.baseIdentifier == null) {
          detector.baseIdentifier = touch.identifier;
        }
        return detector.fireTouchStart(touch);
      })
    );

    element.on("touchmove", function(event) {
      detector.fireGestures(event, event.originalEvent.targetTouches.length);
      return $.each(
        event.originalEvent.changedTouches,
        (index, touch) =>
          (detector.lastTouches[touch.identifier] = detector.cloneTouch(touch))
      );
    });

    // If this event gets fired
    element.on("touchend touchcancel", function(event) {
      const { touches } = event.originalEvent;
      $.each(event.originalEvent.changedTouches, function(index, touch) {
        delete detector.lastTouches[touch.identifier];

        if (detector.baseIdentifier === touch.identifier) {
          if (touches[0] != null) {
            detector.baseIdentifier = touches[0].identifier;
          } else {
            detector.baseIdentifier = null;
          }
        }
        return detector.fireTouchEnd(touch);
      });

      if (event.originalEvent.touches.length <= 1) {
        return (detector.nextTouch = null);
      }
    });
  }

  canMove() {
    return (
      this.touches[this.baseIdentifier] != null &&
      this.lastTouches[this.baseIdentifier] != null
    );
  }

  calculateMove(touch) {
    const last = this.lastTouches[touch.identifier];
    const currentPoint = new Point(touch.pageX, touch.pageY);
    const lastPoint = new Point(last ? last.pageX : 0, last ? last.pageY : 0);
    return {
      fingers: 1,
      fingersOnTarget: 1,
      current: currentPoint,
      xdiff: currentPoint.x - lastPoint.x,
      ydiff: currentPoint.y - lastPoint.y,
      pageX: currentPoint.x,
      pageY: currentPoint.y
    };
  }

  fireTouchStart(touch) {
    return $(this).trigger("touchstart", [touch]);
  }

  fireTouchEnd(touch, startTouch) {
    return $(this).trigger("touchend", [touch, startTouch]);
  }

  fireTouchMove(moveData, originalEvent) {
    return $(this).trigger("touchmove", [moveData, originalEvent]);
  }
  fireGestures(event, targetTouchCount) {
    const detector = this;
    const { changedTouches } = event.originalEvent;
    let baseTouch = null;
    let nextTouch = null;
    if (changedTouches.length === 0) {
      return;
    }

    // If there's one changed touch, see if it's the base.
    // If it is, use the nextTouch we have stored. If it's
    // not, then use it as the next touch.
    if (changedTouches.length === 1) {
      if (changedTouches[0].identifier === this.baseIdentifier) {
        baseTouch = changedTouches[0];
      } else {
        baseTouch = this.lastTouches[this.baseIdentifier];
        this.nextTouch = changedTouches[0];
      }
    } else {
      // If there are more than one changed touches, use
      // the first as the base and the second as the next.
      baseTouch = changedTouches[0];
      this.nextTouch = changedTouches[1];
    }

    // Sometimes the touch stored in this.nextTouch goes away.
    // If that happens, let's just ignore it and act like it's not there.
    if (
      this.nextTouch != null &&
      this.lastTouches[this.nextTouch.identifier] == null
    ) {
      this.nextTouch = null;
    }
    ({ nextTouch } = this);
    if (baseTouch == null) {
      return;
    }
    if (nextTouch == null) {
      this.fireTouchMove(this.calculateMove(baseTouch), event);
      return;
    }
    const lastBaseTouch = this.lastTouches[baseTouch.identifier];
    const lastNextTouch = this.lastTouches[nextTouch.identifier];
    const newDistance = MathExtras.distance(
      baseTouch.pageX,
      baseTouch.pageY,
      nextTouch.pageX,
      nextTouch.pageY
    );
    const lastDistance = MathExtras.distance(
      lastBaseTouch.pageX,
      lastBaseTouch.pageY,
      lastNextTouch.pageX,
      lastNextTouch.pageY
    );
    const touchAverage = new Point(
      (baseTouch.pageX + nextTouch.pageX) / 2,
      (baseTouch.pageY + nextTouch.pageY) / 2
    );
    const lastAverage = new Point(
      (lastBaseTouch.pageX + lastNextTouch.pageX) / 2,
      (lastBaseTouch.pageY + lastNextTouch.pageY) / 2
    );
    const horizontalDiff = baseTouch.pageX - nextTouch.pageX;
    const verticalDiff = baseTouch.pageY - nextTouch.pageY;
    const angle = Math.atan2(verticalDiff, horizontalDiff);
    const pinchData = {
      fingers: 2,
      fingersOnTarget: targetTouchCount,
      scale: newDistance / lastDistance,
      angle,
      pageX: touchAverage.x,
      pageY: touchAverage.y
    };

    const moveData = {
      fingers: 2,
      fingersOnTarget: targetTouchCount,
      current: touchAverage,
      xdiff: touchAverage.x - lastAverage.x,
      ydiff: touchAverage.y - lastAverage.y,
      pageX: touchAverage.x,
      pageY: touchAverage.y
    };

    if (Math.abs(newDistance - lastDistance) > this.pinchDistanceThreshold) {
      $(this).trigger("pinch", [pinchData, event]);
    }
    return this.fireTouchMove(moveData, event);
  }

  //
  //	 * The browser will update touch objects internally rather than
  //	 * passing new ones per event. This means we'll need a clone in
  //	 * order to save the original state of the touch.
  //
  cloneTouch(touch) {
    // Note: We leave out the target on purpose in order to
    // not reference the DOM when not needed.
    return {
      clientX: touch.clientX,
      clientY: touch.clientY,
      identifier: touch.identifier,
      pageX: touch.pageX,
      pageY: touch.pageY,
      screenX: touch.screenX,
      screenY: touch.screenY
    };
  }
}

export { TouchDetector };
