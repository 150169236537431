class FileTypeStyle {
  DIRECT_TYPE_MAPPING = {
    // Jackpot(s):
    // http://en.wikipedia.org/wiki/Internet_media_type#Type_audio
    // http://www.utoronto.ca/web/htmldocs/book/book-3ed/appb/mimetype.html#audi

    // Adobe and related.
    "application/pdf": "pdf",
    "application/x-pdf": "pdf",
    "application/postscript": "pdf",
    "image/vnd.adobe.photoshop": "psd",

    // Archive
    "application/zip": "archive",

    // Text
    "text/csv": "spreadsheet",
    "text/rtf": "text",
    "application/rtf": "text",

    // Microsoft Office - http://www.wrensoft.com/forum/showthread.php?t=2834
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      "document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      "presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      "presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template":
      "presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "spreadsheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      "spreadsheet",
    "vnd.ms-word.document.macroEnabled.12": "document",
    "vnd.ms-word.template.macroEnabled.12": "document",
    "vnd.ms-powerpoint.template.macroEnabled.12": "presentation",
    "vnd.ms-powerpoint.addin.macroEnabled.12": "presentation",
    "vnd.ms-powerpoint.slideshow.macroEnabled.12": "presentation",
    "vnd.ms-powerpoint.presentation.macroEnabled.12": "presentation",
    "vnd.ms-excel.addin.macroEnabled.12": "spreadsheet",
    "vnd.ms-excel.sheet.binary.macroEnabled.12": "spreadsheet",
    "vnd.ms-excel.sheet.macroEnabled.12": "spreadsheet",
    "vnd.ms-excel.template.macroEnabled.12": "spreadsheet",

    // OpenDocument - http://en.wikipedia.org/wiki/OpenDocument
    "application/vnd.oasis.opendocument.text": "document",
    "application/vnd.oasis.opendocument.presentation": "presentation",
    "application/vnd.oasis.opendocument.spreatsheet": "spreadsheet",

    // Video
    "video/x-ms-wmv": "video",
    "video/mp4": "video",
    "video/mpeg": "video",
    "video/mpeg-2": "video",
    "video/quicktime": "video",
    "video/x-msvideo": "video",
    "video/ogg": "video",
    "video/webm": "video",

    // Audio
    "audio/aiff": "audio",
    "audio/wav": "audio",
    "audio/mpeg": "audio",
    "audio/mpeg-2": "audio",
    "audio/basic": "audio",
    "audio/L24": "audio",
    "audio/mp4": "audio",
    "audio/ogg": "audio",
    "audio/vorbis": "audio",
    "audio/x-ms-wma": "audio",
    "audio/x-ms-wax": "audio",
    "audio/vnd.rn-realaudio": "audio",
    "audio/vnd.wave": "audio",
    "audio/webm": "audio",

    // Image
    "image/gif": "image",
    "image/png": "image",
    "image/jpeg": "image",
    "image/pjpeg": "image",
    "image/bmp": "image",
    "image/x-ms-bmp": "image",
    "image/x-bmp": "image",
    "image/tiff": "image",
    "image/svg+xml": "image",
    "image/vnd.microsoft.icon": "image"
  };

  EXTENSION_MAPPING = {
    exe: "command",
    rar: "archive",
    tar: "archive",
    gz: "archive",

    // Code
    log: "code",
    conf: "code",
    rb: "code",
    php: "code",
    java: "code",
    js: "code",
    html: "code",
    sql: "code",
    css: "code",
    yml: "code",
    xml: "code",
    erb: "code"
  };

  DEFAULT = "default";

  get(contentType, filename) {
    filename = filename || "";
    const extension = filename.substring(filename.lastIndexOf(".") + 1);
    return (
      "ei-file-icon-" +
      (this.DIRECT_TYPE_MAPPING[contentType] ||
        this.EXTENSION_MAPPING[extension] ||
        this.DEFAULT)
    );
  }
}

const instance = new FileTypeStyle();
export { instance as FileTypeStyle };
