class KeyCodes {
  // From $.ui.keyCode (1.8.24)
  static ALT = 18;
  static BACKSPACE = 8;
  static CAPS_LOCK = 20;
  static COMMA = 188;
  static COMMAND = 91;
  static COMMAND_LEFT = 91; // COMMAND
  static COMMAND_RIGHT = 93;
  static CONTROL = 17;
  static DELETE = 46;
  static DOWN = 40;
  static END = 35;
  static ENTER = 13;
  static ESCAPE = 27;
  static HOME = 36;
  static INSERT = 45;
  static LEFT = 37;
  static MENU = 93; // COMMAND_RIGHT
  static NUMPAD_ADD = 107;
  static NUMPAD_DECIMAL = 110;
  static NUMPAD_DIVIDE = 111;
  static NUMPAD_ENTER = 108;
  static NUMPAD_MULTIPLY = 106;
  static NUMPAD_SUBTRACT = 109;
  static PAGE_DOWN = 34;
  static PAGE_UP = 33;
  static PERIOD = 190;
  static RIGHT = 39;
  static SHIFT = 16;
  static SPACE = 32;
  static TAB = 9;
  static UP = 38;
  static WINDOWS = 91; // COMMAND

  static PLUS = 187;
  static MINUS = 189;
  static COMMAND_FIREFOX = 224;

  // key should be a one character string, or a single digit number.
  static codeFor(key) {
    // Codes returned from jQuery return the uppercase value
    // for keys pressed. Lets do the same thing.
    key = `${key}`.toUpperCase();
    return key.charCodeAt(0);
  }
}

export { KeyCodes };
