import { Attachment } from "./Attachment";
import { Model } from "./Model";
import { PhotoView } from "src/views/PhotoView";

class Photo extends Model {
  objectType = "photo";

  viewClass() {
    return PhotoView;
  }

  valid() {
    return this.src != null || this.attachment != null;
  }

  filter(attribute, newValue) {
    if (attribute === "width" || attribute === "height") {
      newValue = parseInt(newValue);
    }
    return newValue;
  }

  static attributes() {
    return ["src", "width", "height", "attachment"];
  }

  static defaults() {
    return {
      width: 222,
      height: 190
    };
  }

  static associations() {
    return {
      attachment: Attachment,
      object: Photo
    };
  }
}

export { Photo };
