/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
import React from "react";
import ReactDOM from "react-dom";

import { UI } from "./UI";

import { Container } from "src/base/Container";
import { Point } from "src/base/Point";

import { UserIcon } from "src/controls/UserIcon";

import { ItemToolbar } from "src/controls/chrome/ItemToolbar";

import { AccessControl } from "src/helpers/AccessControl";
import { FocusManager } from "src/helpers/FocusManager";
import { TouchDetector } from "src/helpers/TouchDetector";

import { Device } from "src/utils/Device";

class ItemView extends Container {
  container = null;
  item = null;

  // Boolean of whether or not the item is currently being dragged.
  dragging = false;
  resizing = false;

  // Boolean for turning resizing on or off at the class level.
  // This takes precedence of any option passed in. It specifically
  // means, "This class does not support resizing" if it is false.
  resizable = true;
  cork = null;
  SAVE_THRESHOLD = 750;

  // Touch detector for detecting touch events.
  detector = null;

  infoMessageTimeout = null;

  constructor(item) {
    super(item);
    this.item = item;
    this.container = $(document.createElement("div"));
    this.container.attr("id", this.item.clientId);
    this.container.addClass("item");
    this.container.css("left", this.item.x);
    this.container.css("top", this.item.y);
    this.container.css("z-index", this.item.z);
    this.detector = new TouchDetector(this.container);

    // Div for displaying information messages about an
    // item, such as "Tim Coulter is editing...".
    this.infoMessage = $(document.createElement("div"));
    this.infoMessage.addClass("info-message");

    // Move grip to signify the item can be moved.
    const moveGrip = $(document.createElement("div"));
    moveGrip.addClass("move-grip ei-item-move-grip no-user-select");
    moveGrip.on("selectstart", () => false);

    this.addDragHandlers();
    this.addResizeHandlers();

    this.container.on("mouseenter", () => {
      if (!Device.isMobile()) {
        return this.container.append(moveGrip);
      }
    });

    this.container.on("mouseleave", () => moveGrip.remove());

    this.item.on("change", () =>
      this.container.css({
        left: item.x,
        top: item.y,
        zIndex: item.z,
        width: item.object.width,
        height: item.object.height
      })
    );
  }

  addDragHandlers() {
    const view = this;
    UI.makeDraggable(this.container);
    let startingPoint = null;
    let startingMousePosition = null;
    let currentMousePosition = null;
    this.container.on("dragstart", function (event) {
      startingPoint = new Point(view.item.x, view.item.y);
      startingMousePosition = view.cork.pointFromPageCoordinates(
        event.originalEvent.pageX,
        event.originalEvent.pageY
      );
      return (currentMousePosition = startingMousePosition);
    });

    this.container.on("drag", function (event) {
      startingPoint =
        startingPoint != null
          ? startingPoint
          : new Point(view.item.x, view.item.y);
      startingMousePosition =
        startingMousePosition != null
          ? startingMousePosition
          : view.cork.pointFromPageCoordinates(
            event.originalEvent.pageX,
            event.originalEvent.pageY
          );

      currentMousePosition = view.cork.pointFromPageCoordinates(
        event.originalEvent.pageX,
        event.originalEvent.pageY
      );
      const x =
        startingPoint.x + (currentMousePosition.x - startingMousePosition.x);
      const y =
        startingPoint.y + (currentMousePosition.y - startingMousePosition.y);
      return view.item.set({
        x,
        y
      });
    });

    this.container.on("dragstop", function (event) {
      view.item.save();

      // For iOS, below. We do it selectively because it
      // screws up IE in rare cases...
      if (Device.isMobile()) {
        return (startingPoint = null);
      }
    });

    // Don't let native events propagate down if this item has focus.
    this.container.on("touchstart touchmove touchend", function (event) {
      if (FocusManager.hasFocus(view.item)) {
        event.stopPropagation();

        // TODO: Figure out why this is here.
        if (event.type === "touchmove") {
          return event.preventDefault();
        }
      }
    });

    $(this.detector).on("touchmove", function (event, data) {
      // In this case, let's only allow dragging the view with 1 finger.
      // Note that we don't want a second finger elsewhere either.
      if (data.fingers !== 1 || data.fingers !== data.fingersOnTarget) {
        return;
      }

      // Let the board handle this one if we're not focussed.
      if (!FocusManager.hasFocus(view.item)) {
        return;
      }

      // Note for below: Double event wrapping is a little nasty,
      // but it's a lazy man's way of making these events look
      // like they came from our custom drag handler.
      if (startingPoint == null) {
        const startEvent = $.Event($.Event(event, data));
        startEvent.type = "dragstart";
        view.container.triggerHandler(startEvent);
      }
      const dragEvent = $.Event($.Event(event, data));
      dragEvent.type = "drag";
      return view.container.triggerHandler(dragEvent);
    });

    $(this.detector).on("touchend touchcancel", (
      event,
      data // Trigger dragstop on the container so things save.
    ) => view.container.triggerHandler("dragstop"));

    // Override setting set by jQuery draggable.
    return this.container.css("position", "absolute");
  }

  addResizeHandlers() {
    const view = this;
    if (Device.isDesktop()) {
      const resizeHandle = $(document.createElement("div"));
      resizeHandle.addClass(
        "ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se"
      );
      resizeHandle.addClass("no-user-select");
      let startingWidth = null;
      let startingHeight = null;
      let startingMousePosition = null;
      let currentMousePosition = null;
      UI.makeDraggable(resizeHandle, { cursorClass: "resize-cursor" });

      resizeHandle.on("dragstart", function (event) {
        startingWidth = view.item.object.width;
        startingHeight = view.item.object.height;
        startingMousePosition = view.cork.pointFromPageCoordinates(
          event.pageX,
          event.pageY
        );
        currentMousePosition = startingMousePosition;
        event.stopPropagation();
        view.resizing = true;
        return $(view).trigger("resizestart");
      });

      resizeHandle.on("drag", function (event) {
        currentMousePosition = view.cork.pointFromPageCoordinates(
          event.pageX,
          event.pageY
        );
        const width =
          startingWidth + (currentMousePosition.x - startingMousePosition.x);
        const height =
          startingHeight + (currentMousePosition.y - startingMousePosition.y);
        view.item.object.set({
          width,
          height
        });

        event.stopPropagation();
        return $(view).trigger("resize");
      });

      resizeHandle.on("dragstop", function (event) {
        view.item.save();
        event.stopPropagation();
        view.resizing = false;
        return $(view).trigger("resizestop");
      });

      this.container.append(resizeHandle);
    }

    // Pinch to resize. All of this should be refactored.
    $(this.detector).on("pinch", function (event, data) {
      if (!FocusManager.hasFocus(view.item)) {
        return;
      }

      // If there's only one finger on this view, that means another is on
      // the board as well. Let's ignore it and let the board handle it.
      // Also, we want to make sure all fingers are on the note, otherwise ignore.
      if (data.fingers === 1 || data.fingers !== data.fingersOnTarget) {
        return;
      }
      const width = view.width();
      const height = view.height();

      // Use the angle to scale the view based on the angle between two touches,
      // taking into account the current board's scale, for better look/feel.
      // TODO: This makes it hard to resize some images. Fix that?
      const xdirection = Math.abs(Math.cos(data.angle)) / view.cork.getScale();
      const ydirection = Math.abs(Math.sin(data.angle)) / view.cork.getScale();
      let newWidth = width * data.scale;
      let newHeight = height * data.scale;

      // Find the size at which this view should move in each direction.
      const xdelta = (newWidth - width) * xdirection;
      const ydelta = (newHeight - height) * ydirection;
      newWidth = width + xdelta;
      newHeight = height + ydelta;
      const position = view.position();

      // Apply the changes. Apply the dimension changes
      // first so we can incorporate any bounds limits
      // into the position calculation (i.e., if the
      // width/height doesn't change, neither should the
      // x/y).
      view.item.object.set({
        width: newWidth,
        height: newHeight
      });

      const xdiff = width - view.item.object.width;
      const ydiff = height - view.item.object.height;
      return view.item.set({
        x: position.x + xdiff / 2,
        y: position.y + ydiff / 2
      });
    });

    $(this.detector).on("touchend touchcancel", () => this.item.save());
  }

  beginEditMode() {
    this.addEditModeToolbar();
  }

  endEditMode() {
    if (this.editModeToolbar) {
      this.editModeToolbar.remove();
    }
  }

  getButtonState() {
    return {
      zoom: true,
      trash: AccessControl.deleteAllowed()
    }
  }

  addEditModeToolbar() {
    this.endEditMode();

    this.editModeToolbar = document.createElement("div");
    this.editModeToolbar.id = "editModeToolbar";
    this.container.append($(this.editModeToolbar));

    const buttonState = this.getButtonState();

    ReactDOM.render(
      <ItemToolbar
        item={this.item}
        buttonState={buttonState}
        onUploadStart={this.handleUploadStart.bind(this)}
        onUploadProgress={this.handleUploadProgress.bind(this)}
        onUploadComplete={this.handleUploadComplete.bind(this)}
      />,
      this.editModeToolbar
    );
  }

  handleUploadStart(file) {
    throw "handleUploadStart() must be overridden by subclasses!";
  }

  handleUploadProgress(file, progress) {
    throw "handleUploadProgress() must be overridden by subclasses!";
  }

  handleUploadComplete(attachment) {
    throw "handleUploadComplete() must be overridden by subclasses!";
  }

  // Base render function. Should be overridden by
  // subclasses, but they must call the super function.
  render() {
    throw "render() must be overridden by subclasses!";
  }

  // Override default Container position method.
  position() {
    return UI.getUnscaledPosition(this.container);
  }

  getZIndex() {
    return parseInt(this.container.css("z-index"));
  }

  zoomTo() {
    return this.cork.zoomTo(this.item);
  }

  setCork(cork) {
    this.cork = cork;
  }

  focus() {
    $(this).trigger("focussed");
  }

  blur() {
    $(this).trigger("blurred");
  }

  setInfoMessage(user, message, timeout = 1500) {
    clearTimeout(this.infoMessageTimeout);

    const usericon = document.createElement("div");
    usericon.classList.add("user-icon");

    ReactDOM.render(
      <UserIcon user={user} />,
      usericon
    );

    const messageDiv = $(document.createElement("div"));
    messageDiv.addClass("message");
    messageDiv.text(`${user.first_name} ${message}`);
    this.infoMessage.contents().remove();
    this.infoMessage.append($(usericon));
    this.infoMessage.append(messageDiv);
    this.infoMessage.stop();
    this.infoMessage.css("opacity", 1);
    this.infoMessage.show();
    this.container.append(this.infoMessage);

    this.infoMessageTimeout = setTimeout(
      () =>
        this.infoMessage.fadeOut(() => {
          this.infoMessage.detach();
          this.infoMessage.contents().remove();
        }),
      timeout
    );
  }
}

export { ItemView };
