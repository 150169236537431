import { NoticeManager } from "src/helpers/NoticeManager";

class Modal {
  application;

  initialize(application) {
    this.application = application;
  }

  show() {
    throw "Modal must override the default show() method!";
  }

  hide(fadeOut) {
    NoticeManager.hideModal(fadeOut);
  }

  setHeader(html) {
    $(".notice .header").html(html);
  }

  // The className is the same as the value set on the radio button.
  createRadioButton(name, value, className, text, checked, fn) {
    const radio = $(document.createElement("input"));
    radio.attr("type", "radio");
    radio.attr("name", name);
    radio.attr("value", value);
    radio.prop("checked", !!checked);
    return this.finishCheckBoxOrRadioButton(radio, className, text, fn);
  }

  createCheckBox(name, className, text, checked, fn) {
    const checkBox = $(document.createElement("input"));
    checkBox.attr("type", "checkbox");
    checkBox.attr("name", name);
    checkBox.prop("checked", !!checked);
    return this.finishCheckBoxOrRadioButton(checkBox, className, text, fn);
  }

  finishCheckBoxOrRadioButton(element, className, text, fn) {
    const enclosingDiv = $(document.createElement("div"));
    enclosingDiv.addClass("selection-div no-user-select");
    const imageDiv = $(document.createElement("div"));
    imageDiv.addClass("selection-image");
    imageDiv.addClass(className);
    const textDiv = $(document.createElement("div"));
    textDiv.html(text);
    enclosingDiv.append(element);
    enclosingDiv.append(imageDiv);
    enclosingDiv.append(textDiv);

    // Support clicking on the checkbox itself. Both events will be fired.
    element.on("click", function (event) {
      if (enclosingDiv.hasClass("disabled")) {
        return;
      }

      if (element.attr("type") === "checkbox") {
        element.prop("checked", !element.is(":checked"));
      }

      if (fn != null) {
        fn();
      }
    });

    enclosingDiv.on("click", function (event) {
      if (enclosingDiv.hasClass("disabled")) {
        return;
      }

      if (element.attr("type") === "checkbox") {
        element.prop("checked", !element.is(":checked"));
      }

      if (fn != null) {
        fn();
      }
    });

    return enclosingDiv;
  }

  createHint(text) {
    const enclosingDiv = $(document.createElement("div"));
    enclosingDiv.addClass("hint-div");
    const textDiv = $(document.createElement("div"));
    textDiv.addClass("hint-text");
    textDiv.html(text);
    enclosingDiv.append(textDiv);
    return enclosingDiv;
  }
}

export { Modal };
