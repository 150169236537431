class EventManager {
  events;

  constructor() {
    this.events = new Map();
  }

  dispatch(event, ...args) {
    if (!this.events.has(event)) {
      return;
    }

    const callbacks = this.events.get(event);
    callbacks.forEach(cb => setTimeout(() => cb.call(null, ...args), 0));
  }

  subscribe(event, callback) {
    if (!this.events.has(event)) {
      this.events.set(event, []);
    }

    this.events.get(event).push(callback);
  }

  off(event) {
    this.events.delete(event);
  }
}

export { EventManager };