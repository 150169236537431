import React from "react";

class GenericTextButton extends React.Component {
  render() {
    return (
      <div className="button text" onClick={this.props.onClick}>
        {this.props.children}
      </div>
    );
  }
}

export { GenericTextButton };
