import { Model } from "./Model";
import { Board } from "./Board";

class Account extends Model {
  static primaryKey = "id";

  static attributes() {
    return ["id", "created_at", "plan", "group_name", "boards"];
  }

  static defaults() {
    return { boards: [] };
  }

  static associations() {
    return { boards: Board };
  }
}

export { Account };
