/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
import Rangy from "rangy";
import { v4 as uuid } from "uuid";

import { Config } from "src/config";
import { Point } from "src/base/Point";

// This smooths out differences between JQuery UI in the desktop browsers
// and other libraries for other devices, specifically, mobile.

// TODO: refactor this so that the UI is controlled by adapters rather than saying "if isIOS...".
class UI {
  // options:
  // {
  //   dragstart: fn(event)
  //   drag: fn(event)
  //   dragstop: fn(event)
  //   cursorClass: "..." (defaults to "move-cursor")
  // }
  makeDraggable(element, options) {
    const ui = this;
    options = options || {};
    options.cursorClass = options.cursorClass || "move-cursor";
    return element.on("mousedown", function (downEvent) {
      const eventNamespace = "." + uuid();

      // If this wasn't a left click, return.
      if (downEvent.which !== 1) {
        return;
      }
      let dragged = false;
      document.body.classList.add("no-user-select");
      document.body.classList.add(options.cursorClass);

      // To prevent selection in IE. Note this also fires in
      // other browsers like Chrome when a textfield of a
      // draggable has text being selected. In that case, be
      // careful not to propogate down to the contiainer.
      const noUserSelectInJS = () => false;

      element.on("selectstart" + eventNamespace, noUserSelectInJS);
      element.find("*").on("selectstart" + eventNamespace, noUserSelectInJS);

      // Get started.
      const startEvent = ui.createDragEvent("dragstart", downEvent);
      if (element.triggerHandler(startEvent) === false) {
        return false;
      }
      let dragHandler = function (moveEvent) {
        // Ensure we *actually* moved somewhere. This is important
        // for IE which will cause a move event even though the mouse
        // hasn't actually been moved.
        if (
          moveEvent.pageX === downEvent.pageX &&
          moveEvent.pageY === downEvent.pageY
        ) {
          return;
        }
        dragged = true;
        const dragEvent = ui.createDragEvent("drag", moveEvent);
        return element.triggerHandler(dragEvent);
      };

      $(document.body).on("mousemove" + eventNamespace, dragHandler);

      // Prevent a click from happening. Note this
      // only fires if we've dragged. Otherwise
      // a handler is not bound.
      const handleClick = function (clickEvent) {
        clickEvent.stopImmediatePropagation();
        clickEvent.stopPropagation();
        clickEvent.preventDefault();
        element.off("click", handleClick);
      };

      const handleDragStop = function (upEvent) {
        document.body.classList.remove("no-user-select");
        document.body.classList.remove(options.cursorClass);
        $(document.body).css("cursor", "auto");

        // Remove noUserSelectInJS function from children.
        element.find("*").off(eventNamespace);
        element.off(eventNamespace);
        $(document.body).off(eventNamespace);
        $(window).off(eventNamespace);
        $(document).off(eventNamespace);

        // If we dragged somewhere, let the drag take over any
        // click event.
        if (dragged) {
          upEvent.stopImmediatePropagation();
          upEvent.stopPropagation();
          upEvent.preventDefault();
          const downElement = downEvent.toElement || downEvent.srcElement;
          const upElement = upEvent.toElement || upEvent.srcElement;
          if (downElement === upElement) {
            element.on("click", handleClick);
          }
          const stopEvent = ui.createDragEvent("dragstop", upEvent);
          element.triggerHandler(stopEvent);
        } else if (options.clickHandler) {
          $(options.clickHandler).trigger("click", upEvent);
        }
      };

      element.on("mouseup" + eventNamespace, handleDragStop);
      $(document.body).on("mouseup" + eventNamespace, handleDragStop);

      // Another hack for IE. Basically, mouseleave will fire innappropriately
      // sometimes. In that case, only stop the drag if the mouse
      // is in fact out of bounds.
      const mouseLeave = function (event) {
        const viewport = $(Config.get("viewport"));
        const position = viewport.position();
        const width = viewport.width();
        const height = viewport.height();
        const { left } = position;
        const right = left + width;
        const { top } = position;
        const bottom = top + height;
        if (
          event.pageX <= left ||
          event.pageX >= right ||
          event.pageY <= top ||
          event.pageY >= bottom
        ) {
          return handleDragStop(event);
        }
      };

      // If the user drags outside of the browser window and then lets the
      // mouse go, we should stop the drag.
      $(window).on("mouseleave" + eventNamespace, mouseLeave);
      $(document).on("mouseleave" + eventNamespace, mouseLeave);
    });
  }

  createDragEvent(type, original) {
    // TODO: The extra values here are deprecated other than type.
    // There were odd issues where they wouldn't be set in some cases.
    const event = $.Event(original, {
      type,
      pageX: original.pageX,
      pageY: original.pageY,
      clientX: original.clientX,
      clientY: original.clientY
    });
    return event;
  }

  // Zooming and scaling functions.
  getUnscaledPosition(element) {
    // Use CSS positioning instead of this.container.position() to
    // use unscaled position coordinates.
    const point = new Point(
      parseInt(element.css("left")),
      parseInt(element.css("top"))
    );
    return point;
  }

  addKeyBindings(element, buttonMapping) {
    element = $(element);
    return element.on("keydown", function (event) {
      const mapping = buttonMapping[event.keyCode];
      if (mapping != null) {
        mapping.selection =
          mapping.selection == null ? false : mapping.selection;

        // If the mapping requires a selection but we don't have one, return.
        if (
          mapping.selection &&
          Rangy.getSelection().isCollapsed
        ) {
          return true;
        }
        mapping.shiftKey = mapping.shiftKey == null ? false : mapping.shiftKey;
        mapping.ctrlKey = mapping.ctrlKey == null ? false : mapping.ctrlKey;
        mapping.altKey = mapping.altKey == null ? false : mapping.altKey;
        mapping.metaKey = mapping.metaKey == null ? false : mapping.metaKey;
        const allMatch =
          (mapping.shiftKey instanceof Function
            ? true
            : mapping.shiftKey === event.shiftKey) &&
          (mapping.ctrlKey instanceof Function
            ? true
            : mapping.ctrlKey === event.ctrlKey) &&
          (mapping.altKey instanceof Function
            ? true
            : mapping.altKey === event.altKey) &&
          (mapping.metaKey instanceof Function
            ? true
            : mapping.metaKey === event.metaKey);

        // Not the right combination. Bail. Let the browser do what it's going to.
        if (allMatch === false) {
          return true;
        }
        if (mapping.shiftKey instanceof Function && event.shiftKey) {
          event.preventDefault();
          mapping.shiftKey(event, element);
          return false;
        }
        if (mapping.ctrlKey instanceof Function && event.ctrlKey) {
          event.preventDefault();
          mapping.ctrlKey(event, element);
          return false;
        }
        if (mapping.altKey instanceof Function && event.altKey) {
          event.preventDefault();
          mapping.altKey(event, element);
          return false;
        }
        if (mapping.metaKey instanceof Function && event.metaKey) {
          event.preventDefault();
          mapping.metaKey(event, element);
          return false;
        }
        if (mapping.fn != null) {
          event.preventDefault();
          mapping.fn(event, element);
          return false;
        }
      }
      return true;
    });
  }
}

const instance = new UI();
export { instance as UI };
