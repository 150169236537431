class BoardSessionAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  create({ string_identifier, password, success, failure, complete }) {
    const data = { password };

    this.API.sendRequest(`/cork/${string_identifier}/login`, {
      type: "POST",
      data: JSON.stringify(data),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

class SessionAPI {
  API;

  constructor(API) {
    this.API = API;
    this.Board = new BoardSessionAPI(API);
  }

  create({ email, password, remember_me, success, failure }) {
    const data = {
      user: {
        email,
        password,
        remember_me
      }
    };

    this.API.sendRequest("/users/sign_in.json", {
      type: "POST",
      data: JSON.stringify(data),
      success,
      error: failure,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  destroy({ success, failure, complete } = {}) {
    this.API.sendRequest("/users/sign_out.json", {
      type: "GET",
      success,
      error: failure,
      complete
    });
  }
}

export { SessionAPI };
