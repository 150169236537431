import { Model } from "./Model";
import { Account } from "./Account";
import { Item } from "./Item";

class Board extends Model {
  static PASSWORD_PROTECTED = "password-protected";
  static primaryKey = "string_identifier";

  isPasswordProtected() {
    return this.permissions.access_type === this.PASSWORD_PROTECTED;
  }

  static attributes() {
    return [
      "string_identifier",
      "api_key",
      "created_at",
      "updated_at",
      "expires_at",
      "belongs_to",
      "registration_seen_count",
      "registration_seen_last",
      "permissions",
      "owned",
      "items",
      "wallpaper",
      "preview_image_url"
    ];
  }

  static defaults() {
    return { items: [] };
  }

  static associations() {
    return {
      items: Item,
      account: Account
    };
  }
}

export { Board };
