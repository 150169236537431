import { Config } from "src/config";
import { NoteApp } from "src/api/v1";

import { CTAButton } from "src/controls/buttons/CTAButton";
import { URLTextBox } from "../URLTextBox";
import { NoticeManager } from "src/helpers/NoticeManager";
import { UploadManager } from "src/helpers/UploadManager";
import { Attachment } from "src/models/Attachment";
import { Modal } from "./Modal";

class CorkSettingsModal extends Modal {
  inputIds = {
    ACCESS_TYPE: "access_type",
    POST_AND_EDIT_ALLOWED: "post_and_edit_allowed",
    DELETE_ALLOWED: "delete_allowed",
    CHAT_ALLOWED: "chat_allowed",
    UPLOAD_ALLOWED: "upload_allowed"
  };

  availableThemes = Config.get("availableThemes");

  // Password to show in place of an empty box if a password
  // was set for this cork in a previous session. This one's unlikely
  // to conflict with a user's real password right?
  placeholderPassword = "$_pass_$$_pass_$";

  constructor(board) {
    super();

    this.board = board;
  }

  show() {
    const tabs = {
      Wallpaper: this.createThemeTab(),
      Permissions: this.createCorkTab(),
      "Public URL": this.createURLTab(),
      "Embed Code": this.createURLsTab()
    };

    NoticeManager.showModal({
      //header: "Board Settings"
      header: this.board.name,
      tabs,
      className: "settings cork-settings white",
      lightbox: false,
      closeable: true
    });
  }

  createThemeTab() {
    let img;
    const modal = this;
    const tab = $(document.createElement("div"));
    tab.addClass("theme");

    const topContainer = $(document.createElement("div"));
    topContainer.addClass("top-container");

    const backgroundsContainer = $(document.createElement("div"));
    backgroundsContainer.addClass("backgrounds-container");

    const cleanOldBackgroundClass = function () {
      if (modal.board.wallpaper.type === "theme") {
        // This should be in cork.coffee somewhere?
        document.body.classList.remove(`ei-${modal.board.wallpaper.theme}-bg`);
      } else {
        document.body.classList.remove(`ei-${modal.board.wallpaper.type}-bg`);
      }
    };

    for (const theme in this.availableThemes) {
      const background = $(document.createElement("div"));
      background.addClass("theme-item ei-loading-small");
      background.data("theme", theme);

      img = $(document.createElement("img"));
      img.addClass("preview");
      img.attr("src", this.availableThemes[theme].preview);
      img.attr("title", this.availableThemes[theme].label)

      background.on("click", function () {
        const element = $(this);
        const new_theme = element.data("theme");

        NoteApp.API.Board.Theme.setWallpaper({
          string_identifier: modal.board.string_identifier,
          wallpaper: {
            type: "theme",
            theme: new_theme
          },
          success(data, textStatus, jqXHR) {
            if (!Config.isSet("application")) {
              // via dashboard
              location.reload();
              return;
            }

            cleanOldBackgroundClass();

            modal.board.wallpaper.type = "theme";
            modal.board.wallpaper.theme = new_theme;
            modal.board.wallpaper.attachment = null;

            const preview = customContainer.find(".preview");
            preview.css("background", modal.board.wallpaper.theme);
            preview.children().remove();

            $("#theme_tag").remove();

            const style = $(document.createElement("style"));
            style.attr("id", "theme_tag");
            style.attr("type", "text/css");
            style.html(data);

            $(document.body).append(style);

            const { cork } = Config.get("application");
            cork.setWallpaper(`ei-${new_theme}-bg`);
            document.body.classList.add(`ei-${new_theme}-bg`);
          },
          failure(jqXHR, textStatus, errorThrown) {
            return console.log(jqXHR, textStatus, errorThrown);
          },
          processData: false,
          contentType: "text/css"
        });
      });

      background.append(img);

      backgroundsContainer.append(background);
    }

    topContainer.append(backgroundsContainer);

    const orSeparator = $(document.createElement("div"));
    orSeparator.addClass("or-separator");

    topContainer.append(orSeparator);

    var customContainer = $(document.createElement("div"));
    customContainer.addClass("custom-container");

    let preview = $(document.createElement("div"));
    preview.addClass("preview theme-item");

    // TODO: Make this its own button class.
    const uploadWrapper = $(document.createElement("a"));
    uploadWrapper.addClass("cta blue small upload-wrapper");
    uploadWrapper.text("Upload custom wallpaper");

    const uploadInput = $(document.createElement("input"));
    uploadInput.attr("type", "file");
    uploadInput.attr("alt", "Add attachment");

    const setCustomWallpaper = (attachment, color) =>
      NoteApp.API.Board.Theme.setWallpaper({
        string_identifier: modal.board.string_identifier,
        wallpaper: {
          type: "custom",
          theme: color || "#FFFFFF",
          attachment: attachment.serialize()
        },
        success(data, textStatus, jqXHR) {
          if (!Config.isSet("application")) {
            // via dashboard
            location.reload();
            return;
          }

          cleanOldBackgroundClass();

          modal.board.wallpaper.theme = color;
          modal.board.wallpaper.attachment = attachment.serialize();
          modal.board.wallpaper.type = "custom";

          $("#theme_tag").remove();

          const style = $(document.createElement("style"));
          style.attr("id", "theme_tag");
          style.attr("type", "text/css");
          style.html(data);

          $(document.body).append(style);

          const { cork } = Config.get("application");
          cork.setWallpaper("ei-custom-bg");
          document.body.classList.add("ei-custom-bg");
        },
        failure(jqXHR, textStatus, errorThrown) {
          return console.log(jqXHR, textStatus, errorThrown);
        },
        processData: false,
        contentType: "text/css"
      });

    // We're using our wrapper as the receiver. For some reason
    // the input can't be used, so we use another object.
    uploadWrapper.on("uploaddone", function (event, attachment, data) {
      preview = customContainer.find(".preview");
      preview.children().remove();

      img = $(document.createElement("img"));
      img.attr("src", attachment.url);

      preview.append(img);

      return setCustomWallpaper(attachment, colorInput.val());
    });

    // Tell the upload manager to handle uploads from this input.
    UploadManager.handle(uploadInput, uploadWrapper, this.board);
    uploadWrapper.append(uploadInput);

    customContainer.append(preview);

    var colorInput = $(document.createElement("input"));
    colorInput.attr("type", "text");
    colorInput.attr("placeholder", "#FFFFFF");
    colorInput.val("#FFFFFF");

    colorInput.on("keyup", function () {
      const hex = (colorInput.val() || "").replace(/[^\w\d]/g, "");

      if (hex.length === 0) {
        preview.css("background", "#000");
        return;
      }

      if (hex.length !== 3 && hex.length !== 6) {
        preview.css("background", "transparent");
        return;
      }

      const color = "#" + hex;
      return preview.css("background", color);
    });

    const colorButton = $(document.createElement("a"));
    colorButton.addClass("cta small green color-button");
    colorButton.text("Set background color");

    colorButton.on("click", () => {
      const hex = (colorInput.val() || "").replace(/[^\w\d]/g, "");
      const color = "#" + hex;

      return setCustomWallpaper(
        new Attachment(this.board.wallpaper.attachment),
        color
      );
    });

    customContainer.append(preview);
    customContainer.append(colorInput);
    customContainer.append(uploadWrapper);
    customContainer.append(colorButton);

    topContainer.append(customContainer);

    //orSeparator = $(document.createElement "div")
    //orSeparator.addClass "or-separator"

    //topContainer.append orSeparator

    if (this.board.wallpaper.type === "custom") {
      preview = customContainer.find(".preview");
      preview.css("background", this.board.wallpaper.theme);

      colorInput.val(this.board.wallpaper.theme);

      if (this.board.wallpaper.attachment != null) {
        preview.children().remove();

        img = $(document.createElement("img"));
        img.attr("src", this.board.wallpaper.attachment.url);

        preview.append(img);
      }
    }

    tab.append(topContainer);

    return tab;
  }

  // TODO: This tab probably needs to be refactored.
  createCorkTab() {
    const modal = this;
    const tab = $(document.createElement("div"));

    var password = $(document.createElement("input"));
    password.addClass("password");
    password.attr("type", "password");
    password.attr("placeholder", "Password");
    if (this.board.permissions.password) {
      password.val(this.placeholderPassword);
    }
    password.on("click", function () {
      if (password.val() === modal.placeholderPassword) {
        password.val("");
        password.trigger("focus");
      }
    });

    password.blur(() => {
      if (this.board.permissions.password && password.val() === "") {
        password.val(modal.placeholderPassword);
      }
    });

    const postAndEditAllowed = this.createCheckBox(
      this.inputIds.POST_AND_EDIT_ALLOWED,
      "none",
      "Create and edit items",
      this.board.permissions.post_and_edit_allowed
    );
    const deleteAllowed = this.createCheckBox(
      this.inputIds.DELETE_ALLOWED,
      "none",
      "Delete items",
      this.board.permissions.delete_allowed
    );
    const chatAllowed = this.createCheckBox(
      this.inputIds.CHAT_ALLOWED,
      "none",
      "Participate in chat",
      this.board.permissions.chat_allowed
    );
    const uploadAllowed = this.createCheckBox(
      this.inputIds.UPLOAD_ALLOWED,
      "none",
      "Upload files & images",
      this.board.permissions.upload_allowed
    );
    const accessSelect = $(document.createElement("select"));
    accessSelect.addClass("access-select");
    const optgroup = $("<optgroup label='Accessibility Options'></optgroup>");
    optgroup.append(
      "<option data-tagline='everyone has access' " +
      (this.board.permissions.access_type === "open"
        ? "selected='selected' "
        : "") +
      "value='open'>Public - everyone has access</option>"
    );
    optgroup.append(
      "<option data-tagline='viewable only with password' " +
      (this.board.permissions.access_type === "password-protected"
        ? "selected='selected' "
        : "") +
      " value='password-protected'>Password Protected</option>"
    );
    optgroup.append(
      "<option data-tagline='only you have access' " +
      (this.board.permissions.access_type === "private"
        ? "selected='selected' "
        : "") +
      " value='private'>Private - only your team</option>"
    );

    accessSelect.append(optgroup);
    const format = function (option) {
      if (!option.id) {
        return option.text;
      }
      const tagline = $(option.element).data("tagline") || "";
      return (
        "<span class='image-dropdown-option ei-" +
        option.id +
        "'>" +
        option.text +
        "<span class='tagline'> - " +
        tagline +
        "</span></span>"
      );
    };

    tab.append(accessSelect);

    const paneWrapper = $(document.createElement("div"));
    paneWrapper.addClass("pane-wrapper");

    // Make sure the password box iniitalizes correctly.
    if (this.board.permissions.access_type !== "password-protected") {
      password.hide();
    }

    tab.append(password);
    tab.append("<br><br>");

    const accessDescription = $(document.createElement("div"));
    accessDescription.addClass("access-description");
    paneWrapper.append(accessDescription);

    const setAccessState = function (access_type, fade = true) {
      accessDescription.hide();

      if (access_type === "open") {
        accessDescription.html(
          "Your current setting allows everyone with the URL to access this board.<br>These users can:"
        );
      }
      if (access_type === "password-protected") {
        accessDescription.html(
          "Your current setting allows Collaborators and anyone with the password to access this board. These users can:"
        );
      }
      if (access_type === "private") {
        accessDescription.html(
          "Your current setting allows only you and other Collaborators to access this board. Collaborators can:"
        );
      }

      if (fade === true) {
        accessDescription.fadeIn();
      } else {
        accessDescription.show();
      }

      // Set the state of the permissions checkboxes.
      const value = accessSelect.val();
      if (value === "password-protected") {
        password.show();
        password.trigger("focus");
      } else {
        password.hide();
      }
    };

    accessSelect.on("change", () => setAccessState(accessSelect.val()));

    setAccessState(this.board.permissions.access_type, false);

    const leftPane = $(document.createElement("div"));
    leftPane.addClass("left-pane");
    const rightPane = $(document.createElement("div"));
    rightPane.addClass("right-pane");
    leftPane.append(postAndEditAllowed);
    leftPane.append(deleteAllowed);
    leftPane.append(chatAllowed);
    rightPane.append(uploadAllowed);

    paneWrapper.append(leftPane);
    paneWrapper.append(rightPane);

    tab.append(paneWrapper);

    const hintText = "These settings also apply when embedding your board.";
    const hint = this.createHint(hintText);
    tab.append("<br>");
    tab.append(hint);
    const changeHint = function (text) {
      hint.hide();
      hint.find(".hint-text").html(text);
      return hint.fadeIn();
    };

    const saveButtonText = "Save";
    const saveButton = CTAButton.createSmall(saveButtonText);
    saveButton.on("click", event => {
      CTAButton.changeText(saveButton, "Please wait...");
      CTAButton.disable(saveButton);
      const settings = {
        access_type: accessSelect.val(),
        post_and_edit_allowed: tab
          .find("input[name=" + modal.inputIds.POST_AND_EDIT_ALLOWED + "]")
          .is(":checked"),
        delete_allowed: tab
          .find("input[name=" + modal.inputIds.DELETE_ALLOWED + "]")
          .is(":checked"),
        chat_allowed: tab
          .find("input[name=" + modal.inputIds.CHAT_ALLOWED + "]")
          .is(":checked"),
        upload_allowed: tab
          .find("input[name=" + modal.inputIds.UPLOAD_ALLOWED + "]")
          .is(":checked")
      };

      if (
        settings.access_type === "password-protected" &&
        password.val() !== this.placeholderPassword
      ) {
        settings.password = password.val();
      }

      const success = (data, textStatus, jqXHR) => {
        changeHint("Saved successfully.");

        // Remove passwords from the UI
        if (settings.access_type !== "password-protected") {
          password.val("");
        } else {
          // Set this so we can set the right value on the line below.
          // If the server saved with the password-protected access type,
          // then there must be a password.
          settings.password = true;
        }

        // Update AccessControl with the newly set settings.
        // AccessControl.setPermissions settings.permissions

        this.board.permissions = settings;

        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      const failure = function (jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }

        changeHint(data.failure);
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      NoteApp.API.Board.changePermissions({
        string_identifier: this.board.string_identifier,
        permissions: settings,
        success,
        failure
      });
    });

    tab.append(saveButton);
    return tab;
  }

  createURLTab() {
    const modal = this;
    const tab = $(document.createElement("div"));
    const url = URLTextBox.create(this.board.string_identifier);
    tab.append(url);
    const hint = this.createHint("");
    hint.hide();
    tab.append(hint);
    const changeHint = function (text) {
      if (hint.is(":hidden")) {
        hint.before("<br><br>");
      }
      hint.hide();
      hint.find(".hint-text").html(text);
      hint.fadeIn();
    };

    const saveButtonText = "Save";
    const saveButton = CTAButton.createSmall(saveButtonText);
    saveButton.on("click", function (event) {
      CTAButton.changeText(saveButton, "Please wait...");
      CTAButton.disable(saveButton);

      const newIdentifier = URLTextBox.getIdentifier(url);

      const success = function (data, textStatus, jqXHR) {
        changeHint("Saved successfully.");

        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);

        modal.board.set({
          string_identifier: newIdentifier
        });

        if (Config.isLoaded()) {
          // For browsers that support window.history.pushState, let's change the URL as well.
          // We only do this when we're not in an embed or view only URL.
          if (
            !Config.isEmbed() &&
            !Config.isViewOnlyURL() &&
            typeof window.history.replaceState === "function"
          ) {
            const url =
              window.location.protocol +
              "//" +
              window.location.host +
              "/" +
              newIdentifier;
            window.history.replaceState(null, newIdentifier, url);
          }

          Config.setIdentifier(newIdentifier);
        }
      };

      const failure = function (jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }

        changeHint(data.failure);
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      NoteApp.API.Board.changeIdentifier({
        old_identifier: modal.board.string_identifier,
        new_identifier: newIdentifier,
        success,
        failure
      });
    });

    tab.append(saveButton);
    return tab;
  }

  createURLsTab() {
    const host = Config.get("host");
    const viewOnlyURL = `${host}/view/${this.board.api_key}`;
    const embedString = `<iframe style="border: solid 1px black;" src="${host}/embed/${this.board.api_key}" width="500" height="400"></iframe>`;

    const tab = $(document.createElement("div"));
    tab.addClass("urls");

    const embedTextArea = $(document.createElement("textarea"));
    embedTextArea.addClass("embed");
    embedTextArea.attr("readonly", "readonly");
    embedTextArea.val(embedString);
    embedTextArea.on("focus", () => embedTextArea.select());

    embedTextArea.on("mouseup", function (event) {
      event.preventDefault();
      return false;
    });

    const viewOnlyTextArea = $(document.createElement("textarea"));
    viewOnlyTextArea.addClass("view-only");
    viewOnlyTextArea.attr("readonly", "readonly");
    viewOnlyTextArea.val(viewOnlyURL);
    viewOnlyTextArea.on("focus", () => viewOnlyTextArea.select());

    viewOnlyTextArea.on("mouseup", function (event) {
      event.preventDefault();
      return false;
    });

    if (
      this.board.account != null &&
      this.board.account.plan.allows_embed != null
    ) {
      tab.append(
        "<h2>These settings only available to Business users. <a href='/pricing'>Upgrade</a></h2>"
      );
      tab.append("<br>");
    }

    tab.append("<h2>Embed string</h2>");
    tab.append(
      "<span>Use this HTML snippet to embed your board into your website or local intranet. This board will have the same permissions as if you were to access it normally from the browser.</span>"
    );
    tab.append(embedTextArea);
    tab.append("<h2>View-only URL</h2>");
    tab.append(
      "<span>Give others this URL if you'd like them to be able to see the contents of the board, but not be able to edit it. This will circumvent any password protections you might have, so keep this URL safe.</span>"
    );
    tab.append(viewOnlyTextArea);
    return tab;
  }
}

export { CorkSettingsModal };
