import { PostIt } from "src/models/PostIt";

const URL_REGEX_STRING =
  "^((http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?)$";

const detectAction = (item, data) => {
  if (item == null) {
    return "add";
  }
  if (
    item.object instanceof PostIt &&
    item.object.contents !== data.object.contents
  ) {
    return "edit";
  }
  if (item.x !== data.x || item.y !== data.y) {
    return "move";
  }
  if (
    item.object.height !== data.object.height ||
    item.object.width !== data.object.width
  ) {
    return "resize";
  }
  return "edit";
};

const escapeHtml = string => {
  const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
    "`": "&#x60;",
    "=": "&#x3D;"
  };

  return String(string).replace(/[&<>"'`=\/]/g, s => entityMap[s]);
};

const setRotation = (element, rotationInDegrees) => {
  const rotation = `rotate(${rotationInDegrees}deg)`;
  element.css({
    "-moz-transform": rotation,
    "-webkit-transform": rotation,
    "-o-transform": rotation,
    "-ms-transform": rotation,
    transform: rotation
  });
};

const setTransform = (element, scale, x, y) => {
  const matrixTransform = getTransform(scale, x, y);
  return element.css("transform", matrixTransform);
};

const getTransform = (scale, x, y) => {
  if (scale == null) {
    throw "scale is required in setTransform()";
  }
  x = x || 0;
  y = y || 0;

  // See here: https://developer.mozilla.org/en/CSS/transform-function
  // "matrix(a, b, c, d, tx, ty) is a shorthand for matrix3d(a, b, 0, 0, c, d, 0, 0, 0, 0, 1, 0, tx, ty, 0, 1)."
  const a = scale;
  const b = 0;
  const c = 0;
  const d = scale;
  const tx = x;
  const ty = y;
  const matrixTransform = `matrix3d(${a}, ${b}, 0, 0, ${c}, ${d}, 0, 0, 0, 0, 1, 0, ${tx}, ${ty}, 0, 1)`;
  return matrixTransform;
};

const pruneHTML = html => {
  // Replace new lines with <br>'s. This should only happen when the data
  // hasn't yet been saved as html.
  html = html.replace(/\r?\n/g, "<br>");

  // Replace multiple <'s and >'s with their respective escape characters.
  // This is to support users entered <'s and >'s before the system started
  // using contenteditable (i.e., they weren't converted to &lt; and &gt;).
  html = html.replace(/<((?![^<]*>)[^<]*)/g, "&lt;$1");

  // Javascript doesn't support lookbehind. So instead, we'll reverse the
  // input, perform our next replacement, then reverse it back. Simple, no?
  html = html
    .split("")
    .reverse()
    .join("");
  html = html.replace(/>((?![^>]*<)[^>]*)/g, ";tg&$1");
  html = html
    .split("")
    .reverse()
    .join("");

  return html;
};

export {
  URL_REGEX_STRING,
  detectAction,
  escapeHtml,
  getTransform,
  pruneHTML,
  setRotation,
  setTransform
};
