class Point {
  x;
  y;

  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  scale(scale) {
    this.x = this.x * scale;
    return (this.y = this.y * scale);
  }

  translate(x, y) {
    if (x instanceof Point) {
      ({ y } = x);
      ({ x } = x);
    }
    this.x = this.x + x;
    this.y = this.y + y;
  }

  equals(x, y) {
    if (x instanceof Point) {
      ({ y } = x);
      ({ x } = x);
    }

    return this.x === x && this.y === y;
  }
}

export { Point };
