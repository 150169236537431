class CTAButton {
  static create(text, className, color) {
    const container = $(document.createElement("div"));
    container.addClass("cta-container");

    const a = $(document.createElement("a"));
    a.addClass("cta");
    a.addClass(color ? color : "gray");
    if (className) {
      a.addClass(className);
    }

    const span = $(document.createElement("span"));
    span.html(text);

    a.append(span);
    container.append(a);

    container.on("click", function(event) {
      if (container.hasClass("disabled")) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        return false;
      }
    });

    return container;
  }

  static createSmall(text, className, color) {
    return this.create(text, "small " + (className || ""));
  }

  static createMedium(text, className, color) {
    return this.create(text, "medium " + (className || ""));
  }

  static disable(ctaButton) {
    ctaButton.addClass("disabled");
  }

  static enable(ctaButton) {
    ctaButton.removeClass("disabled");
  }

  static changeText(ctaButton, text) {
    ctaButton.find("span").html(text);
  }
}

export { CTAButton };
