import { v4 as uuid } from "uuid";

import { AssociationArray } from "./AssociationArray";

import { ModelIndex } from "./ModelIndex";
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
class Model {
  clientId;
  instanciated_at;

  static count = 0;
  static indexes = null;

  // The primary key of this model. It should be set to the name of a
  // property (attribute or other non-function property on the model)
  // whose value will be used to determine model equality. Defaults
  // to clientId. The value should be both indexable and unique.
  // An index is automatically created for this key whether or not
  // it's specified within indexOn().
  static primaryKey = "clientId";

  // Setting events directly on the class seems to give uncertain behavior
  // when using multiple models. Instead, give each model an eventBase object
  // with which to trigger events off of. Outside interface is left intact.
  static eventBase = null;

  constructor(data, buildOptions = { create: false }) {
    const defaults = this.constructor.defaults();
    for (let attribute in data) {
      if (data.hasOwnProperty(attribute)) {
        defaults[attribute] = data[attribute];
      }
    }
    this.clientId = uuid();
    this.instanciated_at = new Date();
    this.set(defaults, { quiet: true }, buildOptions);
  }

  // Also accepts: (hash, options [, buildOptions]).
  // Options are:
  // - quiet: won't trigger events if false.
  //
  // Passing buildOptions should be avoided and you should
  // instead defer to new, Model.build() and Model.create()
  set(attribute, value, options, buildOptions) {
    let newValue, oldValue;
    if (buildOptions == null) {
      buildOptions = { create: false };
    }
    let newValues = {};
    if (typeof attribute === "string") {
      newValues[attribute] = value;
    } else {
      newValues = attribute;
      buildOptions = options;
      options = value;
    }
    options = options || {};


    const clientIdChanged =
      newValues.clientId !== undefined && newValues.clientId !== this.clientId;

    // Flag specifying whether or not we should fire the
    // general changed message.
    let changed = false;

    // Check for a clientId change, and if so, perform the change
    // and update the indexes.
    if (clientIdChanged) {
      let index;
      const oldClientId = this.clientId;
      const newClientId = newValues.clientId;
      const indexes = this.constructor.indexOn();

      // Remove the model from all the indexes, update
      // the clientId, then add it again.
      let i = 0;

      while (i < indexes.length) {
        index = this.constructor.getIndex(indexes[i]);
        index.remove(this);
        i++;
      }
      this.constructor.getIndex("clientId").remove(this);
      this.constructor.getIndex(this.constructor.primaryKey).remove(this);
      this.clientId = newClientId;

      // Add the model back in.
      i = 0;

      while (i < indexes.length) {
        index = this.constructor.getIndex(indexes[i]);
        index.add(this);
        i++;
      }
      this.constructor.getIndex("clientId").add(this);
      this.constructor.getIndex(this.constructor.primaryKey).add(this);

      // Remove the attribute and its value so it's not processed
      // by further portions of this function.
      delete newValues.clientId;

      changed = true;

      if (options.quiet !== true) {
        $(this).trigger("clientId_change", [newClientId, oldClientId]);
      }
    }

    // If the primary value is changing and we've already indexed this item,
    // then do the same thing we did with clientId above, but only for the
    // primary index since the value's not used everywhere.
    const primaryValueChanged =
      newValues[this.constructor.primaryKey] !== undefined &&
      newValues[this.constructor.primaryKey] !== this.primary() &&
      this.constructor.primaryKey !== "clientId" &&
      this.constructor.getIndex("clientId").has(this);
    if (primaryValueChanged) {
      const oldPrimaryValue = this.primary();
      const newPrimaryValue = newValues[this.constructor.primaryKey];
      this.constructor.getIndex(this.constructor.primaryKey).remove(this);
      this[this.constructor.primaryKey] = newPrimaryValue;
      this.constructor.getIndex(this.constructor.primaryKey).add(this);
      delete newValues[this.constructor.primaryKey];

      changed = true;
      if (options.quiet !== true) {
        $(this).trigger(this.constructor.primaryKey + "_change", [
          newPrimaryValue,
          oldPrimaryValue
        ]);
      }
    }

    // Gather the new values and the old values, and set the
    // new values on the model. We set the new values on the
    // model for the associations (see below).
    const oldValues = {};
    for (attribute in newValues) {
      newValue = newValues[attribute];
      oldValue = this[attribute];
      if (newValue === oldValue) {
        delete newValues[attribute];

        continue;
      }
      oldValues[attribute] = oldValue;

      // Override everything from the beginning, association
      // or not. We'll go back over the values and set things
      // properly from there. This allows, for instance,
      // polymorphic associations that rely on model data to
      // be set before determining which association class to
      // choose.
      this[attribute] = newValue;
    }

    const associations = this.constructor.associations(this);

    // Convert any associations.
    for (attribute in associations) {
      oldValue = oldValues[attribute];
      newValue = newValues[attribute];

      // Was there a change to the association? If not, move on.
      if (newValue == null) {
        continue;
      }

      const klass = associations[attribute];

      // Do we have a "has one" association that's
      // already been created?
      if (oldValue instanceof Model) {
        // If we were passed a model, serialize it so we
        // can call the set() method. Note: We specifically
        // allow for a hash to be passed here as well; in
        // that case, the hash itself will be passed to set().
        if (newValue instanceof Model) {
          newValue = newValue.serialize();
        }

        // Reset oldValue -- turns out we need it.
        this[attribute] = oldValue;

        // Call the set() method and update attributes as necessary.
        this[attribute].set(newValue, options, buildOptions);
      } else if (oldValue instanceof AssociationArray) {
        // We have an AssociationArray as the oldValue.
        // Let's keep that array and update the values instead.
        this[attribute] = oldValue;
        oldValue.update(newValue, options, buildOptions);
      } else {
        // If we've gotten here, then we haven't converted this
        // association before (the association is probably null).
        // Let's call create() and it'll do the legwork.

        // TODO: Handle events properly when we overwrite one
        // model instance with another.
        // Note: We already set the model up above.
        if (!(newValue instanceof Model)) {
          this[attribute] = klass.create(newValue, buildOptions);
        }

        // If an AssociationArray was created, let's listen to it.
        if (this[attribute] instanceof AssociationArray) {
          // Create scope.
          this[attribute].on(
            "change",
            this.constructor.createAssociationChangeHandler(this, attribute)
          );
        }
      }
    }

    // Finally, fire changed events, and update indexes for any
    // non-association attributes.
    for (attribute in newValues) {
      // Everything's already been set or converted at this point.
      // this[attribute] is the new value of the attribute.
      oldValue = oldValues[attribute];

      // Run through the filter so we can debug or add any
      // validations before the final setting of data.
      this[attribute] = this.filter(attribute, this[attribute], oldValue);
      newValue = this[attribute];

      // Re-index any attributes that need to be re-index.
      // These are only non-association attributes that have been changed.
      if (associations[attribute] === undefined) {
        const hasIndex = this.constructor.hasIndex(attribute);
        const isIndexed = this.constructor.has(this, [oldValue]);

        // Update the index if it exists.
        if (hasIndex && isIndexed) {
          this.constructor.getIndex(attribute).remove(this, [oldValue]);
          this.constructor.getIndex(attribute).add(this);
        }
      }

      if (newValue != oldValue) {
        changed = true;

        if (!options.quiet) {
          $(this).trigger(attribute + "_change", [newValue, oldValue]);
        }
      }
    }

    if (changed && !options.quiet) {
      $(this).trigger("change", [newValues, oldValues]);
    }
  }

  get(attribute) {
    return this[attribute];
  }

  has(attribute) {
    const value = this[attribute];
    let result = value != null;
    if (value instanceof AssociationArray) {
      result = value.length > 0;
    }
    return result;
  }

  filter(attribute, newValue, oldValue) {
    // Does nothing, but can be overidden for debugging
    // and validations.
    return newValue;
  }

  // Nice wrapper for listening.
  on(event, selector, data, handler) {
    return $(this).on(event, selector, data, handler);
  }

  off(events, selector, handler) {
    return $(this).off(events, selector, handler);
  }

  trigger(eventType, extraParameters) {
    return $(this).trigger(eventType, extraParameters);
  }

  triggerHandler(eventType, extraParameters) {
    return $(this).triggerHandler(eventType, extraParameters);
  }

  // For when a model created with new Klass() needs
  // to be added to the main index.
  add() {
    return this.constructor.add(this);
  }

  // Note: the class handles the triggering of an
  // instance-level add event.

  // For when a model needs to be removed from the main index.
  remove() {
    return this.constructor.remove(this);
  }

  // Note: the class handles the triggering of an
  // instance-level remove event.

  // Return the value of the primary key for this object.
  primary() {
    return this[this.constructor.primaryKey];
  }

  // isChildCall prevents us from interpreting a null value inside some
  // hash or array somewhere as intending to represent the current model.
  // isChildCall only used internally. Ignore it.
  serialize(obj, isChildCall) {
    let data, index;
    if (isChildCall == null) {
      isChildCall = false;
    }
    if (obj == null && isChildCall === false) {
      return this.serialize(this, true);
    } else if (obj instanceof Array || obj instanceof AssociationArray) {
      const newArray = [];
      index = 0;

      while (index < obj.length) {
        newArray.push(this.serialize(obj[index], true));
        index++;
      }
      return newArray;
    } else if (obj instanceof Model) {
      const attributes = obj.constructor.attributes();
      data = {};
      index = 0;

      while (index < attributes.length) {
        const attribute = attributes[index];
        const value = obj[attribute];
        data[attribute] = this.serialize(value, true);
        index++;
      }
      return data;
    } else if (obj instanceof Object) {
      data = {};
      for (let key in obj) {
        const val = obj[key];
        if (obj.hasOwnProperty(key)) {
          data[key] = this.serialize(val, true);
        }
      }
      return data;
    }

    // Else, just return it. We don't want to serialize it.
    return obj;
  }

  equals(model) {
    const ours = this.primary();
    const theirs = model.primary();

    // Fall back to object equality in the case where the
    // primary key is undefined for both.
    if (ours === undefined && theirs === undefined) {
      return this === model;
    }

    // Return true if:
    // 1) They share the exact same class.
    // 2) They both don't have null values for this klass's primary key
    //    (how could you determine equality?)
    // 3) Their primary keys share the same value.
    return (
      this.constructor === model.constructor &&
      ours != null &&
      theirs != null &&
      ours === theirs
    );
  }
  static associations(model) {
    return {};
  }

  static attributes() {
    throw "attributes() must be overridden by subclasses and return an array.";
  }

  static defaults() {
    return {};
  }

  static indexOn() {
    return [];
  }

  // options: Array of hashes or a hash with data to create the model with.
  // buildOnly: [internal] if set to true, will only build the model, won't index it.
  //
  // Style: buildOnly is not meant to be used by anything other than internal
  // model classes. Use new Model() instead, or Model.build()
  static create(options, buildOptions) {
    if (options == null) {
      options = {};
    }
    if (buildOptions == null) {
      buildOptions = { create: true };
    }
    if (options instanceof Array || options instanceof AssociationArray) {
      const newArray = new AssociationArray(this);
      let index = 0;

      while (index < options.length) {
        const data = options[index];

        // See if we already have the model. If we do, update
        // its values and return the one we have rather than
        // create a new one. This allows scenarios where in
        // json block meant for deserialization, it might be
        // easier on the server side to include a model's json
        // twice rather than somehow reference it; upon deserialization,
        // both references will point to the same object.
        const model = this.find(data[this.primaryKey]);

        if (model != null) {
          model.set(data);
          newArray.push(model);
        } else {
          newArray.push(new this(data, buildOptions));

          if (buildOptions.create === true) {
            this.add(newArray[index]);
          }
        }
        index++;
      }
      return newArray;
    } else {
      return this.create([options], buildOptions)[0];
    }
  }

  // Sugar for 'new Model()'
  static build(options) {
    return this.create(options, { create: false });
  }

  static getIndex(attribute) {
    if (this.indexes == null) {
      const indexOn = this.indexOn();

      // Ensure the main indexes variable exists.
      this.indexes = {};

      // Set up empty indexes.
      let index = 0;

      while (index < indexOn.length) {
        attribute = indexOn[index];

        // Ensure the index is there.
        this.indexes[attribute] = new ModelIndex(attribute);
        index++;
      }

      // Set up the default clientId index.
      this.indexes.clientId = new ModelIndex();

      // Set up the primaryKey index.
      if (this.primaryKey !== "clientId") {
        this.indexes[this.primaryKey] = new ModelIndex(this.primaryKey);
      }
    }
    return this.indexes[attribute];
  }

  static hasIndex(attribute) {
    return this.getIndex(attribute) != null;
  }

  // Nice wrapper for listening.
  static on(event, selector, data, handler) {
    this.eventBase = this.eventBase || {};
    return $(this.eventBase).on(event, selector, data, handler);
  }

  static off(events, selector, handler) {
    this.eventBase = this.eventBase || {};
    return $(this.eventBase).off(events, selector, handler);
  }

  static trigger(eventType, extraParameters) {
    this.eventBase = this.eventBase || {};
    return $(this.eventBase).trigger(eventType, extraParameters);
  }

  // Takes a model, or an array or association array of models
  // and adds them to the main index.
  static add(model) {
    let attribute;
    const klass = this;
    if (model instanceof Array || model instanceof AssociationArray) {
      const array = model;
      for (model of Array.from(array)) {
        this.add(model);
      }
      return;
    }

    // Arlight, so we have a single model on hand? Let's go!

    // Don't do anything if we already have it.
    if (this.has(model)) {
      return;
    }
    $(this.eventBase).trigger("beforeadd", [model]);
    const indexOn = this.indexOn();
    let i = 0;

    while (i < indexOn.length) {
      attribute = indexOn[i];

      // And add it to the index.
      const index = this.getIndex(attribute);
      index.add(model);
      i++;
    }

    // Finally, special case for the clientId.
    this.getIndex("clientId").add(model);

    // And another for the primary key.
    this.getIndex(this.primaryKey).add(model);
    this.count += 1;

    // Recurse through associations and ensure all associations are added.
    // This accounts for a model object that was built with associations
    // but was not added to the index.
    const associations = this.associations(model);
    for (attribute in associations) {
      const association = model[attribute];

      // If we don't have a value for the association on this model,
      // then continue on.
      if (association == null) {
        continue;
      }
      if (association instanceof AssociationArray) {
        association.klass.add(association);
      } else {
        // Call the instance-level add function which will
        // call the right function per class.
        association.add();
      }
    }
    $(this.eventBase).trigger("add", [model]);
    return model.triggerHandler("add");
  }

  static remove(model) {
    let index;
    const klass = this;
    if (model instanceof Array || model instanceof AssociationArray) {
      const array = model;
      index = 0;

      while (index < array.length) {
        this.remove(array[index]);
        index++;
      }
      return;
    }

    // Don't do anything if we don't have it.
    if (!this.has(model)) {
      return;
    }
    $(this.eventBase).trigger("beforeremove", [model]);
    const indexOn = this.indexOn();
    let i = 0;

    while (i < indexOn.length) {
      const attribute = indexOn[i];

      // Remove it from the index.
      index = this.getIndex(attribute);
      index.remove(model);
      i++;
    }

    // Finally, special case for the clientId.
    this.getIndex("clientId").remove(model);

    // And delete the primary key.
    this.getIndex(this.primaryKey).remove(model);
    this.count -= 1;
    $(this.eventBase).trigger("remove", [model]);
    return model.triggerHandler("remove");
  }

  // Check to see if a model is within this index. This function
  // check two things:
  // 1) Whether or not we've indexed this clientId before.
  // 2) Whether or not we've index this primary key value before.
  //
  // If either are true we return true. Note that if the primary key's
  // value is null, it hasn't been set yet -- like a database id.
  // In that case we return false explicitly.
  //
  // clientIdValue and primaryValue may be arrays per the ModelIndex.has()
  // function.
  static has(model, clientIdValue, primaryValue) {
    const primary = model.primary();
    const isInClientId = this.getIndex("clientId").has(model, clientIdValue);
    const isInPrimaryKey = this.getIndex(this.primaryKey).has(
      model,
      primaryValue
    );
    return isInClientId || (primary != null && isInPrimaryKey);
  }

  static each(fn) {
    const index = this.getIndex("clientId");
    return index.each(fn);
  }

  static eachValue(attribute, fn) {
    const index = this.getIndex(attribute);
    if (index == null) {
      throw "Couldn't traverse attribute that's not indexed: " + attribute;
    }
    return index.eachValue(fn);
  }

  static all() {
    const array = [];
    this.each(model => array.push(model));

    return array;
  }

  // Note: this will have O(n) performance in older browsers.
  static first() {
    const index = this.getIndex("clientId");
    return index.first();
  }

  // Note: this will have O(n) performance on older browsers.
  static last() {
    const index = this.getIndex("clientId");
    return index.last();
  }

  // Syntactic sugar for finding by the primary key.
  // In this case, we'll only return one model since the primary key
  // is supposed to be unique.
  static find(value) {
    return this.findBy(this.primaryKey, value).first();
  }

  static findBy(attribute, value) {
    if (this.getIndex(attribute) != null) {
      return this.getIndex(attribute).get(value);
    }
    throw "Can't find by a value that's not indexed. (TODO! also easy).";
  }

  static serialize() {
    const array = [];
    this.each(model => array.push(model.serialize()));

    return array;
  }

  static fireAdd(klass, model) {
    return $(klass).trigger("add", model);
  }

  static createAssociationChangeHandler(model, attribute) {
    return event => $(model).trigger(attribute + "_change", [model[attribute]]);
  }
}

export { Model };
