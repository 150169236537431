/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { v4 as uuid } from "uuid";

import { Model } from "./Model";

// NOTE: There are many issues when with overridding arrays.
// For instance, see here:
//
// http://perfectionkills.com/how-ecmascript-5-still-does-not-allow-to-subclass-an-array/
//
// We override an array in this case and many features work nicely.
// However, one big issues is with the length value. e.g.,
//
// var arr = new AssociationArray();
//
// arr[0] = "...something..."
// arr.length # = 0 !!!!
//
// However:
//
// arr.push("...something...")
// arr.length # = 1 :)
//
// Our motto:
//
//     "Use functions, and get length."
//
// Kindof a shitty motto, but you get the point.

class AssociationArray extends Array {
  // Apparently jQuery can't listen on arrays.
  // Use this as our trigger point.
  eventBase;

  // A hash containing key/value pairs of clientIds
  // to their models, to remove duplicates and check
  // for existence.
  modelIndex;

  // Will be set to the klass of the first model
  // added. Used to ensure models of the wrong
  // type are not added.
  klass;

  // Each array has a clientId of its own.
  clientId;

  constructor(klass) {
    super();
    this.klass = klass;
    this.eventBase = {};
    this.modelIndex = {};
    this.clientId = uuid();
  }

  // Nice wrapper for listening.
  on(event, selector, data, handler) {
    return $(this.eventBase).on(event, selector, data, handler);
  }

  off(events, selector, handler) {
    this.eventBase = this.eventBase || {};
    return $(this.eventBase).off(events, selector, handler);
  }

  trigger(eventType, extraParameters) {
    this.eventBase = this.eventBase || {};
    return $(this.eventBase).trigger(eventType, extraParameters);
  }

  add(model, options) {
    const args = [model];

    // Let's not add a null argument if options is null.
    if (options != null) {
      args.push(options);
    }
    return this.push.apply(this, args);
  }

  addAt(index, model, options) {
    const args = [index, 0, model];

    // Let's not add a null argument if options is null.
    if (options != null) {
      args.push(options);
    }
    return this.splice.apply(this, args);
  }

  // O(n); uses indexOf()
  remove(model, options) {
    const index = this.indexOf(model);
    if (index < 0) {
      throw "Could not find model " + model.clientId + " in association array.";
    }
    return this.removeAt(index, options);
  }

  removeAt(index, options) {
    const args = [index, 1];

    // Let's not add a null argument if options is null.
    if (options != null) {
      args.push(options);
    }
    return this.splice.apply(this, args);
  }

  shift(options) {
    return this.removeAt(0, options);
  }

  // Takes an optional options hash as the last argument.
  unshift(...args) {
    // Let's get a real array.
    args = Array.prototype.slice.call(args);
    args.unshift(Array.prototype.unshift);
    return this.pushOrUnshift.apply(this, args);
  }

  pop(options) {
    return this.remove(this.length - 1, options);
  }

  // Takes an optional options hash as the last argument.
  push(...args) {
    // Let's get a real array.
    args = Array.prototype.slice.call(args);
    args.unshift(Array.prototype.push);
    return this.pushOrUnshift.apply(this, args);
  }

  // Takes an optional options hash as the last argument.
  splice(...args) {
    // Let's get a real array.
    args = Array.prototype.slice.call(args);
    const last = args[args.length - 1];
    let options = {};
    if (typeof last === "object" && !(last instanceof Model)) {
      options = args.pop();
    }
    options.quiet = options.quiet == null ? false : options.quiet;
    const returnVal = Array.prototype.splice.apply(this, args);

    // Remove first two arguments since we no longer need them.
    args.shift();
    args.shift();

    // Indoctrinate any added items.
    this.indoctrinate(args);

    // Remove items from the index and detach any handlers associated
    // with this array.
    let index = 0;

    while (index < returnVal.length) {
      const model = returnVal[index];
      model.off("." + this.clientId);
      delete this.modelIndex[model.primary()];
      index++;
    }
    if (!options.quiet) {
      // Handle removed items.
      index = 0;

      while (index < returnVal.length) {
        this.fireRemove(returnVal[index]);
        index++;
      }

      // Handle added items, if any; see standard definition of splice().
      index = 0;

      while (index < args.length) {
        this.fireAdd(args[index]);
        index++;
      }
      this.fireChange();
    }

    // Return what splice() would originally return.
    return returnVal;
  }

  each(fn) {
    let index = 0;

    while (index < this.length) {
      if (fn(this[index]) === false) {
        return;
      }
      index++;
    }
  }

  first() {
    return this[0];
  }

  // Similar to Model.find(). Will use the primary key to locate
  // a model within this association array. Returns nil if not
  // part of this array (note: it could still be a member of the
  // main index, even those it's not found here)
  find(primary) {
    return this.modelIndex[primary];
  }

  last() {
    return this[this.length - 1];
  }

  // Determines if a sepecific model is in this array by
  // using its primary key. The value of the key may be
  // passed to this function instead.
  has(model) {
    let key = model;
    if (model instanceof Model) {
      key = model.primary();
    }
    return key != null && this.modelIndex[key] != null;
  }

  fireAdd(model) {
    return $(this.eventBase).trigger("add", [model]);
  }

  fireRemove(model) {
    return $(this.eventBase).trigger("remove", [model]);
  }

  fireChange() {
    return $(this.eventBase).trigger("change");
  }

  // Private: Expects the prototype function to be
  // the first argument. Like the others, takes an
  // optional options hash as the last argument.
  pushOrUnshift(...args) {
    const array = this;

    // Let's get a real array.
    args = Array.prototype.slice.call(args);
    const protoFn = args.shift();
    const last = args[args.length - 1];
    let options = {};
    if (typeof last === "object" && !(last instanceof Model)) {
      options = args.pop();
    }
    options.quiet = options.quiet == null ? false : options.quiet;
    this.indoctrinate(args);
    const returnVal = protoFn.apply(this, args);
    if (!options.quiet) {
      let index = 0;

      while (index < args.length) {
        this.fireAdd(args[index]);
        index++;
      }
      this.fireChange();
    }
    return returnVal;
  }

  // i.e., make sure everything in the args array is kosher to be included.
  indoctrinate(args) {
    const array = this;

    // Do a little checking to make sure the arguments are kosher.

    // Set a klass if none is set.
    if (this.klass == null && args.length > 0) {
      this.klass = args[0].klass;
    }
    let index = 0;

    return (() => {
      const result = [];
      while (index < args.length) {
        var model = args[index];
        if (!(model instanceof Model)) {
          throw "Tried adding a non-model object to an association array!";
        }
        if (!(model instanceof this.klass)) {
          throw "Tried adding two different model types to association array; failed on " +
            model.clientId;
        }
        if (this.has(model)) {
          throw "Model '" +
            model.primary() +
            "' already exists in the association array!";
        }

        // Add models to the index, since they're kosher.
        this.modelIndex[model.primary()] = model;

        // If the model is removed from the main index,
        // remove it from this array. It's considered dead to
        // the application. Namespace by the clientId
        // so we can remove this handler later.
        model.on("remove." + this.clientId, () => array.remove(model));

        result.push(index++);
      }
      return result;
    })();
  }

  // Update this association array with the data from
  // another, calling standard add/remove functions to
  // ensure all events are called. Note: this does not
  // replace the array, even if it contains completely
  // new data.
  //
  // Like Model.set(), buildOptions should be ignored
  // under normal usage. In fact, normal usage should't
  // require a call to this function.
  update(newArray, options, buildOptions) {
    // TODO: Do we really need to deserialize and
    // then deserialize to do this for added items?

    // Remember: AssociationArray instanceof Array == false
    let model;
    if (newArray instanceof Array) {
      newArray = this.klass.create(newArray, buildOptions);
    }

    // Remove any that aren't in the new array.
    let index = 0;

    while (index < this.length) {
      model = this[index];
      if (!newArray.has(model)) {
        this.remove(model, options);
      }
      index++;
    }

    // Go through the array updating or adding.
    index = 0;

    return (() => {
      const result = [];
      while (index < newArray.length) {
        model = this[index];
        const newModel = newArray[index];
        if (model != null && model.equals(newModel)) {
          // Turn the model into data so we can use the
          // set() function.
          const data = newModel.serialize();
          model.set(data, options);
        } else {
          this.addAt(index, newModel, options);
        }
        result.push(index++);
      }
      return result;
    })();
  }
}

export { AssociationArray };
