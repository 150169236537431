/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */

import { Model } from "./Model";
import { Attachment } from "./Attachment";

import { PostItView } from "src/views/PostItView";

class PostIt extends Model {
  objectType = "post_it";

  constructor(data, buildOptions) {
    super(data, buildOptions);
    // Ensure any added attachments are in the main
    // index.
    this.attachments.on("add", (event, model) => model.add());

    // Any attachments removed from the note itself
    // are dead to us.
    this.attachments.on("remove", (event, model) => model.remove());
  }

  viewClass() {
    return PostItView;
  }

  isBlank() {
    // Use a div to parse out the HTML and get the text.
    const tmp = document.createElement("div");
    tmp.innerHTML = this.content;
    const rawText = tmp.textContent || tmp.innerText || "";
    tmp.remove();

    return rawText === "" && !this.has("attachments");
  }

  valid() {
    return true;
  }

  filter(attribute, newValue) {
    if (attribute === "width" || attribute === "height") {
      newValue = parseInt(newValue);
    }
    return newValue;
  }

  static attributes() {
    return ["content", "width", "height", "color", "attachments"];
  }

  static defaults() {
    return {
      content: "",
      color: "yellow",
      width: 222,
      height: 190,
      attachments: []
    };
  }

  static associations() {
    return { object: PostIt, attachments: Attachment };
  }

  static iconClassForColor(color) {
    return `ei-note-${color}`;
  }
}

const AVAILABLE_COLORS = [
  "yellow",
  "pink",
  "blue",
  "purple",
  "green",
  "white",
  "orange",
  "gray"
];

export { PostIt, AVAILABLE_COLORS };
