import { EventManager } from "src/helpers/EventManager";

class FocusManager {
  // Allow components to subscribe to updates.
  eventManager;

  currentlyFocussedItem;

  constructor() {
    this.eventManager = new EventManager();
  }

  focus(item) {
    // Don't trigger a new event if nothing changed.
    if (!item || this.hasFocus(item)) {
      return;
    }

    this.blur(this.currentlyFocussedItem);
    item.view.focus();
    this.currentlyFocussedItem = item;
    this.eventManager.dispatch("gainedfocus", this.currentlyFocussedItem);
  }

  // Force removal of focus if item is focused.
  blur(item) {
    item = item || this.getFocus();
    if (!item || !this.hasFocus(item)) {
      return;
    }

    this.currentlyFocussedItem = null;
    item.view.blur();
    this.eventManager.dispatch("lostfocus", item);
  }

  hasFocus(item) {
    return item === this.currentlyFocussedItem;
  }

  getFocus() {
    return this.currentlyFocussedItem;
  }

  subscribe(event, handler) {
    this.eventManager.subscribe(event, handler);
  }
}

const instance = new FocusManager();
export { instance as FocusManager };
