import { Point } from "src/base/Point";

class MathExtras {
  // Accepts two Points or four coordinates.
  static distance(x1, y1, x2, y2) {
    if (x1 instanceof Point) {
      x2 = y1.x;
      y2 = y1.y;
      y1 = x1.y;
      x1 = x1.x;
    }
    const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    return distance;
  }

  static megabytes(bytes) {
    return bytes / 1048576; // bytes in 1 Mb.
  }
}

export { MathExtras };
