import Rangy from "rangy";
import "rangy/lib/rangy-classapplier";
import "rangy/lib/rangy-selectionsaverestore";

import { v4 as uuid } from "uuid";

import { KeyCodes } from "src/base/KeyCodes";
import { URL_REGEX_STRING } from "src/utils/Util";

import { UI } from "src/views/UI";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 */
class RichTextArea {
  // | separated, to make it easy for the regex.
  // Must be surrounded by parentheses.
  whitelistedTags = "(a|span|br|strong|em|b|i|u|s|ol|ul|li)";
  whitelistedStyles =
    "(font-size|font-weight|font-style|line-height|text-decoration)";

  // Array of the above whitelisted styles. Set on initialization.
  whitelistedStylesArray = null;

  // This should always be a subset of whitelistedTags
  whitelistedInlineTags = "(a|span|strong|em|b|i|u|s)";

  // Mapping between keys and their functions. Set on initialization.
  buttonMapping = null;

  // This value will be used when scrolling the div. Ideally I'd like to calculate
  // this instead, but setting it here is good enough for now.
  CURSOR_PADDING = 5;
  SELECTION_MARKER_PREFIX = "selectionBoundary";

  constructor() {
    const klass = this;
    this.whitelistedStylesArray = this.whitelistedStyles
      .substring(1, this.whitelistedStyles.length - 1)
      .split("|");
    this.buttonMapping = {};

    // Bolding, italics and underline.
    this.buttonMapping[KeyCodes.codeFor("b")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(
          event,
          input,
          { "font-weight": "bold" },
          true
        );
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.codeFor("i")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(
          event,
          input,
          { "font-style": "italic" },
          true
        );
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.codeFor("u")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(
          event,
          input,
          { "text-decoration": "underline" },
          true
        );
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.codeFor("s")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(
          event,
          input,
          { "text-decoration": "line-through" },
          true
        );
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    // Font size adjustment.
    this.buttonMapping[KeyCodes.UP] = {
      ctrlKey(event, input) {
        return klass.handleFontSizeAdjustment(event, input);
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.DOWN] = this.buttonMapping[KeyCodes.UP];

    // Specific font styles.
    this.buttonMapping[KeyCodes.codeFor("1")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(event, input, {
          "font-size": "20px",
          "line-height": "23px",
          "font-weight": "bold"
        });
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.codeFor("2")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(event, input, {
          "font-size": "14px",
          "line-height": "17px",
          "font-weight": "bold"
        });
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    this.buttonMapping[KeyCodes.codeFor("3")] = {
      ctrlKey(event, input) {
        return klass.handleFontStyle(event, input, {
          "font-size": "13px",
          "line-height": "16px"
        });
      },

      metaKey(event, input) {
        // Do the same thing as the ctrlKey.
        return this.ctrlKey(event, input);
      },

      selection: true
    };

    // Tabs (bulleted lists).
    this.buttonMapping[KeyCodes.TAB] = {
      fn(event, input) {
        return klass.handleTab(event, input);
      },

      shiftKey(event, input) {
        return klass.handleTab(event, input);
      }
    };
  }

  enable(input) {
    input.attr("contentEditable", true);
    return input.attr("readonly", null);
  }

  disable(input) {
    input.attr("contentEditable", false);
    return input.attr("readonly", "readonly");
  }

  create(defaultHtml, className, editable) {
    const klass = this;
    if (editable == null) {
      editable = true;
    }
    const input = $(document.createElement("div"));
    input.addClass("rich-textarea");
    input.addClass(className);
    if (editable === true) {
      //input.attr("contentEditable", true);
      this.enable(input);
    } else {
      this.disable(input);
    }
    UI.addKeyBindings(input, this.buttonMapping);
    input.on("keyup", event => klass.handleKeyUp(event, input));

    input.on("paste", event => klass.handlePaste(event, input));

    this.setHtml(input, defaultHtml);

    // Add click handlers to override default (edit).
    // This will hold true for all future urls added.
    input.on("click", "a", function(event) {
      const a = $(event.target);
      return window.open(a.attr("href"));
    });

    return input;
  }

  handleKeyUp(event, input) {
    const klass = this;

    // If pressed something other than a command key, fire a change!
    // Otherwise, primp URLs and save. Note we don't want to a primp URLs
    // on a non-letter key (like shift or command) because primpURLs()
    // doesn't preserve non-collapsed selections.
    // COMMAND in FF on a Mac - not in keyCode list.
    if (
      event.keyCode === KeyCodes.LEFT ||
      event.keyCode === KeyCodes.RIGHT ||
      event.keyCode === KeyCodes.UP ||
      event.keyCode === KeyCodes.DOWN ||
      event.keyCode === KeyCodes.SHIFT ||
      event.keyCode === KeyCodes.CONTROL ||
      event.keyCode === KeyCodes.ALT ||
      event.keyCode === KeyCodes.WINDOWS ||
      event.keyCode === KeyCodes.COMMAND ||
      event.keyCode === KeyCodes.COMMAND_LEFT ||
      event.keyCode === KeyCodes.COMMAND_RIGHT ||
      event.keyCode === KeyCodes.COMMAND_FIREFOX ||
      event.keyCode === KeyCodes.WINDOWS ||
      event.keyCode === KeyCodes.HOME ||
      event.keyCode === KeyCodes.END ||
      event.keyCode === KeyCodes.TAB ||
      event.keyCode === KeyCodes.ESCAPE ||
      event.keyCode === KeyCodes.INSERT ||
      event.keyCode === KeyCodes.PAGE_UP ||
      event.keyCode === KeyCodes.PAGE_DOWN
    ) {
      return;
    }

    // If either of the following three buttons were pressed,
    // we're keying up after some command combination. No need
    // to primp URLs or fire a change -- changes will be handled
    // in the associated functions.
    if (
      event.ctrlKey === true ||
      event.metaKey === true ||
      event.altKey === true
    ) {
      return;
    }

    // If it's a shift key, do like the above, but only if
    // we didn't just insert a character from the top row of
    // the keyboard.
    if (event.shiftKey === true) {
      const key = parseInt(String.fromCharCode(event.keyCode));
      if (!(key >= 0) || !(key <= 9)) {
        return;
      }
    }

    // Handle the enter key being pressed. Webkit likes to add divs as new lines;
    // IE and Firefox like <p> tags. We'll enforce <br> across all of them.
    if (event.keyCode === KeyCodes.ENTER) {
      this.handleEnter(input);
    } else {
      // If a key we care about was pressed, fire a changed event.
      // ENTER handles its own changed event.
      klass.fireChangedEvent(input);
    }
    clearTimeout(input.data("primpTimeout"));

    // Make typing smoother on slower devices by ensuring 100 milseconds of downtime.
    return input.data(
      "primpTimeout",
      setTimeout(() => klass.primpURLs(input, true), 100)
    );
  }

  handlePaste(event, input) {
    const klass = this;

    // Now save the selection in a way that we can restore it later.
    const selection = Rangy.getSelection();
    const savedRanges = selection.getAllRanges();
    const oldScrollTop = input.scrollTop();

    // Create a hidden contenteditable div that we'll use to isolate paste input.
    // Generally, we're "redirecting" the paste input by quickly focusing the hidden
    // div before the paste finishes.
    const hiddenEditableDiv = $(document.createElement("div"));
    hiddenEditableDiv.addClass("offscreen");
    hiddenEditableDiv.attr("contenteditable", "true");
    $(document.body).append(hiddenEditableDiv);

    // Focus the div to redirect the paste input.
    hiddenEditableDiv.focus();
    const originalContent = input[0].innerHTML;
    const afterPaste = function() {
      // Remove the hidden div. We're done with it.
      hiddenEditableDiv.remove();
      klass.primpURLs(input, true);

      // We pasted something. Throw the changed event.
      // Also throw a more distinquishable afterpaste event.
      klass.fireChangedEvent(input);
      return input.trigger("afterpaste");
    };

    var hasUpdated = function() {
      // Internet Explorer <= 8 doesn't let us redirect the paste. It also errors
      // in Rangy with where "nodeType is null or not an object." Oh well. Let's
      // leave it under "limited support" and let it do its own thing then.
      if (input[0].innerHTML !== originalContent) {
        klass.clean(input);
        afterPaste();
        return;
      }

      // If our hidden div hasn't received any data, then keep waiting until it does.
      // Note that for Internet Explorer, it doesn't.
      if (hiddenEditableDiv[0].innerHTML === "") {
        setTimeout(hasUpdated, 25);
        return;
      }

      // For pasting from Word or OpenOffice in Safari: remove preamble.
      const firstElement = hiddenEditableDiv.contents().first();
      if (
        firstElement
          .text()
          .match(
            /Version:(\s|\w|\.)*StartHTML:(\s|\w|\.)*EndHTML:(\s|\w|\.)*StartFragment:(\s|\w|\.)*EndFragment:(\s|\w|\.)*/g
          )
      ) {
        firstElement.remove();
      }

      // To match most the look of websites, we'll add a <br> tag after
      // every <p> since <p> tags are not allowed. This isn't done in
      // clean because some browsers add <p> tags to represent a new line,
      // whereas in most websites where you'd paste from, the space between
      // two <p> tags is the equivalent of two new lines.
      hiddenEditableDiv.find("p").after("<br>");

      // Focus the contenteditable div and let's get started with the paste.
      input.focus();

      // Restore the scrollTop to what it was.
      input.scrollTop(oldScrollTop);

      // Restore the selection the user had previously.
      selection.setRanges(savedRanges);

      // Clean the data in the text box. This will likely contain ugly and unneeded
      // tags like <meta>, <font>, etc.
      klass.clean(hiddenEditableDiv, false);
      let html = hiddenEditableDiv.html(); //.trim();

      // If we've received html input, kill non-html whitespace.
      if (html.indexOf("<") >= 0 || html.indexOf(">") >= 0) {
        html = html.replace(/\r?\n/g, "");
      }

      // Create a temporary span in which we'll "paste" the contents into.
      // We do this so we can convert html text into dom elements using jQuery.
      const span = $(document.createElement("span"));
      span.html(html);
      klass.insertNodesAtSelection(input, span.contents());
      return afterPaste();
    };

    return setTimeout(hasUpdated, 1);
  }

  handleEnter(contenteditable) {
    const marker = this.insertSelectionMarkerAtCursor(contenteditable);

    // For Chrome: If the cursor is right before a <br> tag, the
    // selection won't be placed in the right spot. Let's move it
    // after the <br> tag.
    let next = marker.next();

    // If we're at the end of a bold tag, say, traverse up the tree until
    // until we find an element with a next item.
    let parent = marker.parent();
    while (next[0] == null && parent[0] !== contenteditable[0]) {
      next = parent.next();
      parent = parent.parent();
    }

    // Here's where we insert the <br> tag.
    if (
      next[0] != null &&
      next[0].nodeType === 1 &&
      next[0].tagName.toLowerCase() === "div"
    ) {
      const br = $(document.createElement("br"));
      next.prepend(br);
      marker.remove();
      br.after(marker);
    }

    // Note that IE and Firefox's line breaks (<p> tags) are handled
    // as a simple replacement within the clean function.

    // We clean without preserving the selection here because we're already
    // doing it above.
    this.clean(contenteditable, false);
    this.selectAndRemoveMarker(contenteditable, marker);
    return this.fireChangedEvent(contenteditable);
  }

  // TODO: Should be broken up from
  // into two functions: tab and shift-tab.
  handleTab(event, input) {
    let next, ul;
    const klass = this;
    event.preventDefault();
    const selection = Rangy.saveSelection();

    // Insert marker at the cursor so we can get it's parent node.
    const marker = klass.insertSelectionMarkerAtCursor(input);
    const parent = marker.parent();
    if (parent[0].tagName.toLowerCase() === "li") {
      // If the user isn't pressing shift, move bullet further in.
      if (!event.shiftKey) {
        ul = $(document.createElement("ul"));
        parent.replaceWith(ul);
        ul.append(parent);
      } else {
        // Else, move bullet in. This requires breaking out of the current
        // ul (i.e., cutting it up and adding another one following it).
        let nextListItems = $();

        // Get next siblings;
        next = parent.next("li, ul");
        while (next.length > 0) {
          nextListItems = nextListItems.add(next);
          next = next.next("li, ul");
        }
        nextListItems.detach();

        // If the parent is an <li>, then the grandparent must be a <ul>.
        ul = parent.parent();
        parent.detach();

        // Create a <ul> to hold the next siblings.
        const newUL = $(document.createElement("ul"));
        newUL.append(nextListItems);

        // If this is a <ul> nested inside another, let's just add the <li> (parent)
        // like it was supposed to be there. But if we've finally shift-tabbed
        // out of the main <ul> entirely, break out of the <li> and add its contents.
        if (ul.parent()[0].tagName.toLowerCase() === "ul") {
          ul.after(parent);
          if (newUL.children().length !== 0) {
            parent.after(newUL);
          }
        } else {
          // If the new <ul> doesn't have any thing in it, opt to add a <br>
          // tag instead.
          if (newUL.children().length === 0) {
            ul.after(parent.contents(), document.createElement("br"));
          } else {
            ul.after(parent.contents(), newUL);
          }
        }

        // If we now have nothing in the original <ul>, remove it.
        if (ul.children().length === 0) {
          ul.remove();
        }
      }
    } else if (!event.shiftKey) {
      // We're adding a new <ul>. Get all text (i.e., inline elements) before and after
      // the marker element and ensure that it's a part of the ul we add.
      // Note: We use the DOM methods for this one because jQuery skips over
      // text nodes.
      const markerElement = marker[0];
      const inlinetagsRegex = new RegExp(
        "^" + klass.whitelistedInlineTags + "$",
        "ig"
      );

      // Get all the previous and next elements. The loops look daunting, but it basically says:
      // If it's a text node, or one of the whitelisted inline tags, I want it.
      const elements = [];
      let previous = markerElement.previousSibling;
      while (
        previous != null &&
        (previous.nodeType === 3 ||
          previous.tagName.match(inlinetagsRegex) != null)
      ) {
        elements.unshift(previous);
        previous = previous.previousSibling;
      }
      elements.push(markerElement);
      next = markerElement.nextSibling;
      while (
        next != null &&
        (next.nodeType === 3 || next.tagName.match(inlinetagsRegex) != null)
      ) {
        elements.push(next);
        next = next.nextSibling;
      }
      ul = $(document.createElement("ul"));
      const li = $(document.createElement("li"));
      ul.append(li);

      // Since we created a new li, insert it.
      klass.insertNodesAtSelection(input, ul);

      // Remove all the previous and next elements and add them to the <li>.
      let index = 0;

      while (index < elements.length) {
        const element = $(elements[index]);
        element.detach();
        li.append(element);
        index++;
      }

      // If there's a <br> directly after the <ul>, remove it, because the ul does that job.
      if (next != null) {
        const afterUL = ul[0].nextSibling;
        const nextTagName = next.tagName;
        if (
          afterUL != null &&
          afterUL.nodeType !== 3 &&
          nextTagName != null &&
          nextTagName.toLowerCase() === "br"
        ) {
          $(afterUL).remove();
        }
      }
    }
    klass.removeMarker(marker);
    Rangy.restoreSelection(selection, false);
    return klass.fireChangedEvent(input);
  }

  handleFontStyle(event, input, css, toggle) {
    toggle = toggle || false;
    if (toggle === true) {
      this.toggleStylesOnSelection(css);
    } else {
      this.applyStylesToSelection(css);
    }
    this.clean(input);
    return this.fireChangedEvent(input);
  }

  handleFontSizeAdjustment(event, input, specificSize) {
    const direction = event.keyCode === KeyCodes.UP ? 2 : -2;
    this.applyStylesToSelection(function(element) {
      element = $(element);
      const currentSize = parseInt(element.css("fontSize"));
      const lineHeight = parseInt(element.css("lineHeight"));
      return element.css({
        fontSize: currentSize + direction + "px",
        lineHeight: lineHeight + direction + "px"
      });
    });

    this.clean(input, true);
    return this.fireChangedEvent(input);
  }

  // This function prepares non-html input for insertion into the contenteditable div.
  // If the input is html, then this should visibly do nothing beyond enforcing the
  // whitelisted set of tags. The primary use of prune() is to provide the same visual
  // output the notes had when they were previously saved as text (non-html).
  prune(html) {
    // Replace new lines with <br>'s. This should only happen when the data
    // hasn't yet been saved as html.
    html = html.replace(/\r?\n/g, "<br>");

    // Replace multiple <'s and >'s with their respective escape characters.
    // This is to support users entered <'s and >'s before the system started
    // using contenteditable (i.e., they weren't converted to &lt; and &gt;).
    html = html.replace(/<((?![^<]*>)[^<]*)/g, "&lt;$1");

    // Javascript doesn't support lookbehind. So instead, we'll reverse the
    // input, perform our next replacement, then reverse it back. Simple, no?
    html = html
      .split("")
      .reverse()
      .join("");
    html = html.replace(/>((?![^>]*<)[^>]*)/g, ";tg&$1");
    html = html
      .split("")
      .reverse()
      .join("");

    // Just in case something goes wrong and we save a selection marker, remove
    // it when the cork loads next time.
    const slectionMarkerRegex = new RegExp(
      "<span[^'\">]+('|\")" + this.SELECTION_MARKER_PREFIX + "[^>]+></span>",
      "ig"
    );
    html = html.replace(slectionMarkerRegex, "");

    // Whitelist specific tags.
    //
    // We create the regular expression from a string to dynamically add whitelisted tags.
    // Models this expression:
    //
    //    /<(\/?(?!\/?(a|p|span|br|div|strong|em|b|i)(\s|>))[^>]*)>/ig
    //
    const whitelistRegex = new RegExp(
      "<(\\/?(?!\\/?" + this.whitelistedTags + "(\\s|>))[^>]*)>",
      "ig"
    );
    html = html.replace(whitelistRegex, "&lt;$1&gt;");
    return html;
  }

  clean(contenteditable, preserveSelection) {
    let selectionPreserved = false;
    if (preserveSelection == null) {
      preserveSelection = true;
    }
    let marker = null;
    if (preserveSelection === true && Rangy.isSelectionValid()) {
      marker = Rangy.saveSelection();
      selectionPreserved = true;
    }
    const klass = this;

    // Regex specifying whitelisted tags.
    const whitelistRegex = new RegExp("^" + this.whitelistedTags + "$", "ig");

    // Regex specifying whitelisted styles.
    const whitelistedStylesRegex = new RegExp(
      "^" + this.whitelistedStyles + "$",
      "ig"
    );

    // Regex to break up the value of the 'style' parameter into pieces that we like.
    const stylesRegex = /([^\s:]+)\s*:\s*([^;]+);?/g;

    // Regex specifying tags with children we're interested in.   ol|ul|li
    const childrenRegex = /^(abbr|acronym|address|body|blockquote|dl|dd|dt|del|ins|label|legend|menu|font|form|small|big|tt|pre|dfn|code|samp|kbd|var|cite|center|table|tbody|thead|tfoot|tr|td|th|sub|sup|h1|h2|h3|h4|h5|h6|p|q|s|strike|div)$/g;

    // Firefox has a habit of adding styles to the contenteditable div.
    // Let's remove the style and add it to the children, wrapping text nodes in spans
    // as necessary.
    const inputStyle = contenteditable.attr("style");
    if (inputStyle != null) {
      contenteditable.contents().each(function(index, element) {
        element = $(element);
        if (element[0].nodeType === 3) {
          const span = $(document.createElement("span"));
          span.attr("style", inputStyle);
          element.replaceWith(span);
          return span.append(element);
        } else {
          return element.attr(
            "style",
            (element.attr("style") || "") + inputStyle
          );
        }
      });

      contenteditable.attr("style", null);
    }

    // Recursive function to clean out tags and styles we don't want.
    // We don't use the index, but it allows this to directly be called from
    // jQuery's each() function.
    var recursiveClean = function(index, element) {
      if (element.nodeType === 3) {
        // Some weird things come in from a paste. These two cause odd visual problems
        // in IE. I believe this is a non-breaking space and a carraige return.
        //
        // BIG DIRTY HACK! This could cause problems elsewhere, though if I understand
        // things correctly the browsers *shouldn't* input these characters through any
        // other means. Most browsers (i.e., not IE) ignore these already, or so it seems.
        //element.nodeValue = unescape(escape(element.nodeValue).replace(/%(A0|0D)/ig, ""));

        // No data anymore? Kill it.
        if (element.nodeValue === "") {
          $(element).remove();
        }
        return;
      }

      // Keep all marker nodes.
      if (klass.isMarkerNode(element)) {
        return;
      }
      const tagName =
        element.tagName != null ? element.tagName.toLowerCase() : "";
      element = $(element);

      // Perform "pre-processing" on specific tags.
      switch (tagName) {
        // <p> tags add extra (and unneeded) vertical spacing. Let's
        // replace them with <br>'s. It does so by adding a br as the last child,
        // where the <p> tag will eventually be replaced by its children.

        // <td> tags, similarly, have no spacing around them, and so pasted <td>
        // tags are all bunched up together on one line. Let's at least make this
        // a little prittier by adding a <br> in there. The same is true for all
        // cases following <td>.
        case "p":
        case "td":
        case "th":
        case "tr":
        case "dt":
        case "dd":
        case "legend":
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          element.append($(document.createElement("br")));
          break;

        // HACK: This is the second half of a workaround for a bug in jQuery.
        // http://bugs.jquery.com/ticket/8815
        case "tab":
          var textNode = $(
            document.createTextNode(String.fromCharCode(KeyCodes.TAB))
          );
          element.replaceWith(textNode);
          return; // Do nothing else.
          break;
        case "br":
          // <br> tags are cool. Let's not waste any time on them.
          return;
          break;
        case "a":
          // Make sure <a> tags' href and value are the same. If the value is not a URL,
          // then remove the tag, keeping the value.
          if (element.attr("href") !== element.text()) {
            element.attr("href", element.text());
          }

          // Make sure <a> tags always go to a new URL. If the a tag does not have a
          // proper href however, then remove the tag in favor of its contents.
          // Nofollow all links to reduce spam.
          if (element.attr("href").match(/^https?:\/\//i)) {
            element.attr("target", "_blank");
            element.attr("rel", "nofollow");
          } else {
            textNode = $(document.createTextNode(element.text()));
            element.replaceWith(textNode);
            return; // Do nothing else.
          }
          break;
      }
      let children = element.contents();

      // Remove any tag that doesn't have any children. Why have it?
      if (children.length === 0) {
        element.remove();
        return;
      }

      // Clean the children before cleaning the parent.
      children.each(recursiveClean);

      // Grab its children again because some may have been removed from the dom.
      children = element.contents();
      if (!tagName.match(whitelistRegex)) {
        // If there are tags with data that we want to keep, let's remove them
        // but keep their children.
        if (tagName.match(childrenRegex)) {
          children.remove();
          return element.replaceWith(children);
        } else {
          // Not one of the above tags? Than it's simply an offending tag.
          // Ditch it, and kill the children!
          element.remove();
          return;
        }
      } else {
        // This is a tag we like... but it could be ugly.
        // Remove all but the few styles we support.
        // Also use this time to clean up any values as we
        // see fit.
        let styles = element.attr("style") || "";
        styles = styles.replace(stylesRegex, function(str, style, value) {
          style = style.toLowerCase();
          value = value.toLowerCase();
          if (!style.match(whitelistedStylesRegex)) {
            return "";
          }

          // Firefox and IE like to use "700" for bold.
          if (style === "font-weight") {
            if (parseInt(value) === 700) {
              value = "bold";
            }
          }
          return style + ":" + value + ";";
        });
        styles = styles.trim();
        if (styles === "") {
          element.attr("style", null);
        } else {
          element.attr("style", styles);
        }

        // Remove any class elements.
        element.attr("class", null);
        switch (tagName) {
          // If we have a span with no style, it's superfluous. Note that we take specific care not
          // to remove spans used to restore selections. If all is well, these should clean themselves
          // up before a save happens.
          case "span":
            if (!klass.isMarkerNode(element) && children.length === 0) {
              return element.remove();
            }
            break;

          // The next two cases unify the implementation around spans.
          case "strong":
          case "b":
            var span = $(document.createElement("span"));
            span.css("font-weight", "bold");
            children.remove();
            span.append(children);
            return element.replaceWith(span);
          case "em":
          case "i":
            span = $(document.createElement("span"));
            span.css("font-style", "italic");
            children.remove();
            span.append(children);
            return element.replaceWith(span);
        }
      }
    };

    contenteditable.contents().each(recursiveClean);

    // Collapse any extra spans.
    this.collapseSpans(contenteditable);
    if (selectionPreserved === true) {
      return Rangy.restoreSelection(marker, false);
    }
  }

  collapseSpans(element, styles) {
    const klass = this;
    element = $(element).get(0);
    styles = styles || {};

    // If we find a text node, wrap it in a span.
    if (element.nodeType === 3 && Object.keys(styles).length > 0) {
      const newSpan = $(document.createElement("span"));
      newSpan.css(styles);
      $(element).wrap(newSpan);
      return;
    }
    if (element.nodeType !== 1) {
      return;
    }

    // Clone the styles so we don't alter the object used on
    // other calls of this function.
    styles = JSON.parse(JSON.stringify(styles));
    const contents = $(element).contents();

    // If we find a span, remove it, replace it with its contents
    // and send its styles down the tree.
    if (
      element.tagName.toLowerCase() === "span" &&
      !this.isMarkerNode(element)
    ) {
      const span = $(element);
      const stylesString = span.attr("style") || "";
      contents.detach();
      span.replaceWith(contents);
      if (stylesString !== "") {
        // Have this span's styles override the styles we were passed
        // (the styles higher in the tree).
        $.each(this.whitelistedStylesArray, function(index, style) {
          // jQuery will always return a value from css() whether or not
          // the style is actually set. Only alter the styles hash if a
          // style is actually set on this span.
          if (stylesString.indexOf(style) >= 0) {
            return (styles[style] = span.css(style));
          }
        });
      }
    }

    return contents.each((index, child) => klass.collapseSpans(child, styles));
  }

  applyStylesToSelection(styles) {
    // Have Rangy and JQuery team up.
    const fakeClass = uuid();
    const cssClassApplier = Rangy.createClassApplier(fakeClass, {
      normalize: true
    });
    cssClassApplier.applyToSelection();
    return $("." + fakeClass).each(function(index, element) {
      element = $(element);
      element.removeClass(fakeClass);
      if (styles instanceof Function) {
        return styles(element);
      } else {
        return element.css(styles);
      }
    });
  }

  toggleStylesOnSelection(styles) {
    const klass = this;
    const selection = Rangy.getSelection();
    const ranges = selection.getAllRanges();
    let spans = [];

    // Get all spans in all ranges.
    $.each(ranges, function(index, range) {
      // Ensure all nodes are split.
      range.splitBoundaries();
      return (spans = spans.concat(
        range.getNodes(
          [1],
          element =>
            element.tagName.toLowerCase() === "span" &&
            range.containsNodeText(element) &&
            !klass.isMarkerNode(element)
        )
      ));
    });

    // If we found no spans, we're most likely on text nodes.
    if (spans.length === 0) {
      const range = ranges[0];

      // If we've selected all text within the parent span,
      // then use the parent span.
      let parent = $(range.getNodes()[0])
        .parent()
        .get(0);
      if (
        parent.tagName.toLowerCase() === "span" &&
        range.containsNodeText(parent)
      ) {
        spans.push(parent);
      } else if (range.canSurroundContents()) {
        // We've selected only partial text of a span.
        // Break the span up
        let span = document.createElement("span");
        range.surroundContents(span);
        span = $(span);
        if (parent.tagName.toLowerCase() === "span") {
          parent = $(parent);

          // ensure styles are specifically set, and we pull the style
          // from the parent.
          $.each(this.whitelistedStylesArray, (index, style) =>
            span.css(style, parent.css(style))
          );

          const contents = parent.contents();
          contents.detach();
          parent.replaceWith(contents);
          contents.each(function(index, child) {
            if (child !== span.get(0)) {
              return $(child).wrap(parent.clone());
            }
          });
        }

        spans.push(span.get(0));
        selection.selectAllChildren(span.get(0));
      }
    }

    // Go through each style and see if it can be applied. Then toggle.
    // It can be applied if:
    // A) No spans already contain this style.
    // B) No spans differ in the application of this style (i.e., bold or non-bold).
    const keys = Object.keys(styles);
    let index = 0;

    return (() => {
      const result = [];
      while (index < keys.length) {
        var style = keys[index];
        const value = styles[style];
        let shouldApplyStyle = true;
        var foundValue = null;
        $.each(spans, function(si, span) {
          let currentStyle = "";
          if (klass.elementHasStyle(span, style)) {
            currentStyle = $(span).css(style);
          }
          if (foundValue == null) {
            return (foundValue = currentStyle);
          } else if (foundValue !== currentStyle) {
            // Styles differ! Don't apply it; remove it.
            shouldApplyStyle = false;
            return false;
          }
        });

        // Special check for browser compatibility;
        // Firefox and IE like to use 700 as the
        // equivalent of bold. Kludgey, but replace
        // 700 with bold if found.
        if (style === "font-weight" && foundValue === "700") {
          foundValue = "bold";
        }

        // Decide whether or not we need to toggle.
        if (shouldApplyStyle === true && (foundValue || "").includes(value)) {
          shouldApplyStyle = false;
        }

        // Now apply or remove.
        if (shouldApplyStyle === true) {
          const styleObj = {};
          styleObj[style] = value;
          klass.applyStylesToSelection(styleObj);
        } else {
          $.each(spans, (
            si,
            span // Remove the style.
          ) => $(span).css(style, ""));
        }

        result.push(index++);
      }
      return result;
    })();
  }

  primpURLs(contenteditable, preserveSelection) {
    const klass = this;
    if (preserveSelection == null) {
      preserveSelection = true;
    }
    const urlRegex = new RegExp(URL_REGEX_STRING, "ig");
    let marker = klass.insertSelectionMarkerAtCursor(contenteditable);
    const markerId = marker.attr("id");

    // First look for any new URLs that were created.
    var recursiveSearch = function(index, element) {
      const { nodeType } = element;
      const { tagName } = element;
      element = $(element);
      if (nodeType === 3) {
        const parent = element.parent()[0];

        // If we've removed this element in the process of primping
        // URLs, move on to the next in the list. Also move on if
        // we're inside an <a> tag - we don't want nested ones!
        if (parent == null || parent.tagName.toLowerCase() === "a") {
          return;
        }

        // Text node look ahead. If we have a bunch of adjacent text nodes,
        // look at them all as one element.
        let next = element[0].nextSibling;
        let siblings = $(element);
        let markerFound = false;
        let markerPosition = element.text().length;

        // Loop through and find all the following text nodes from element,
        // ignoring any markers. If a marker is found, note its position and
        // ignore it. Note we stop looking at one marker, because there should
        // only be one.
        while (
          next != null &&
          (klass.isMarkerNode(next) || next.nodeType !== 1)
        ) {
          if (klass.isMarkerNode(next)) {
            markerFound = true;
            next = next.nextSibling;
            continue;
          } else if (markerFound === false) {
            markerPosition += $(next).text().length;
          }
          siblings = siblings.add($(next));
          next = next.nextSibling;
        }

        // Create a wrapper span so we can get the HTML using .html().
        const wrapper = $(document.createElement("span"));
        wrapper.append(siblings.clone());

        // Don't add an href yet to preserve any markers.
        const current = wrapper.html();
        let html = current.replace(
          urlRegex,
          "<a href='$1' target='_blank' rel='nofollow'>$1</a>"
        );

        // No changes? Move on.
        if (current === html) {
          return;
        }
        if (markerFound === true) {
          // Detach it from where it sits in the dom right now.
          marker.detach();

          // Insert our marker back in using string subsitution by normalizing
          // the text relative to before the <a> tag was inserted.
          index = 0;
          let position = 0;
          let inTag = false;
          while (index < html.length) {
            if (html[index] === "<") {
              inTag = true;
            } else if (html[index] === ">") {
              inTag = false;
            } else if (inTag === false) {
              position += 1;
            }
            index += 1;
            if (position === markerPosition) {
              // Another wrapper. Used strictly for the html() function.
              const markerWrapper = $(document.createElement("span"));
              markerWrapper.append(marker);
              html =
                html.substr(0, index) +
                markerWrapper.html() +
                html.substr(index, html.length);
              break;
            }
          }
        }
        element.replaceWith(html);

        // Set the value of all siblings to "" instead of removing them
        // to preserve selection.
        return siblings.each((index, sibling) => $(sibling).remove());
      } else {
        return element.contents().each(recursiveSearch);
      }
    };

    contenteditable.contents().each(recursiveSearch);

    // Update our reference to the marker because it
    // may have been removed and added as a new node.
    marker = $("#" + markerId);

    // Now lets primp any that are currently being appended right now.
    // Note: this will only return one element; it's not a loop.
    marker.prev("a").each(function(index, element) {
      let nextNode = element.nextSibling;

      // If there's no next node or if it's not a text node, move on.
      if (nextNode == null || nextNode.nodeType !== 3) {
        return;
      }
      element = $(element);
      nextNode = $(nextNode);
      const text = element.text() + nextNode.text();
      const url = __guard__(text.match(urlRegex), x => x[0]);
      element.text(url);
      element.attr("href", url);

      // For some reason the text() function wasn't working...
      if (nextNode != null) {
        nextNode[0].nodeValue = text.replace(url, "");
      }

      // Remove the node if it's empty (for IE).
      if (
        __guard__(
          nextNode != null ? nextNode[0] : undefined,
          x1 => x1.nodeValue
        ) === ""
      ) {
        return nextNode.remove();
      }
    });

    // Now keep href's of all <a> tags up to date. This isn't strictly
    // needed but it's a stop gap for possible strays.
    contenteditable.find("a").each(function(index, element) {
      element = $(element);
      element.attr("href", element.text());
      element.attr("target", "_blank");
      element.attr("rel", "nofollow");
    });

    if (preserveSelection === true) {
      return klass.selectAndRemoveMarker(contenteditable, marker);
    } else {
      return klass.removeMarker(marker);
    }
  }

  getHtml(contenteditable) {
    // Clone the div so we can make small changes for saving.
    const clone = contenteditable.clone();

    // Remove links by replacing them with their text.
    clone.find("a").each(function(index, element) {
      element = $(element);
      return element.replaceWith(element.text());
    });

    // Remove any carraige returns and remove the last <br> that was added to the note.
    const content = clone
      .html()
      .replace(/\n/g, "")
      .replace(/^(.*)<br>$/i, "$1")
      .trim();
    return content;
  }

  setHtml(contenteditable, html) {
    const klass = this;

    // The default text for a new note is "<br>" because Firefox
    // has an odd bug that makes the caret huge if the content is "".
    let content = this.prune(html || "<br>");

    // HACK: Notice the <tab> tag. This is to get around a bug in jquery:
    // http://bugs.jquery.com/ticket/8815. The second half of this
    // workaround is at the beginning of the clean() function.
    // Note: We use RegExp here just because my editor sucks at highlighting.
    content = content.replace(new RegExp("\t", "g"), "<tab></tab>");
    contenteditable.html(content);

    // Clean and primp the urls. Note: we only preserve the selection
    // if the application is loaded, to prevent issues specifically
    // because of errors with Internet Explorer 8 inside of an iframe
    // (without those errors, we'd preserve selection all the time).

    //var preserveSelection = Config.isLoaded();
    this.clean(contenteditable, false);
    return this.primpURLs(contenteditable, false);
  }

  insertNodesAtSelection(contenteditable, nodes, remove, scroll) {
    // Work around a quirk in Internet Explorer in an iframe on initialization.
    // This may be a problem with Rangy's isSelectionValid() method which is
    // causing an Access Denied error, but let's catch it and ignore this call
    // if the selection is not valid, or we can't determine the seleciton.
    let selectionValid = false;
    try {
      selectionValid = Rangy.isSelectionValid();
    } catch (error) {}

    // Error caught. Do nothing.
    if (selectionValid === false) {
      return;
    }

    // Okay, all good. Let's role!
    if (remove == null) {
      remove = true;
    }
    if (scroll == null) {
      scroll = true;
    }

    // Get the scrollTop before we do anything, because it's
    // unfortunately going to revert to zero.
    const oldScrollTop = contenteditable.scrollTop();
    const last = nodes.last();
    const selection = Rangy.getSelection();
    if (remove === true) {
      // If they've highlighted something, let's get rid of it.
      selection.deleteFromDocument();
      selection.refresh();
    }
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      $(nodes.get().reverse()).each(function(index, el) {
        $(el).remove();
        return range.insertNode(el);
      });
    }

    if (scroll === true) {
      return this.scrollToCursor(contenteditable, oldScrollTop);
    }
  }

  scrollToCursor(contenteditable) {
    // Scroll to the current cursor position by inserting an element into the dom
    // at the cursor, getting its offset, then changing the scroll top to match.
    // Note we use offset instead of position because Safari and IE were having
    // trouble calculating the position of an element below the viewable area.
    const oldScrollTop = contenteditable.scrollTop();

    // Insert an element so we can get it's position and scroll the contenteditable
    // div accordingly.
    const hiddenElement = $(document.createElement("div"));
    hiddenElement.css("display", "inline-block");
    hiddenElement.css("position", "relative");
    this.insertNodesAtSelection(contenteditable, hiddenElement, false, false);
    const cursorOffset = hiddenElement.offset();
    const height = hiddenElement.outerHeight();

    // Remove hidden tag.
    hiddenElement.remove();
    const inputOffset = contenteditable.offset();
    const scrollTo =
      cursorOffset.top -
      inputOffset.top +
      oldScrollTop -
      contenteditable.height() +
      this.CURSOR_PADDING; // + this.LINE_HEIGHT;
    if (scrollTo > oldScrollTop) {
      return contenteditable.scrollTop(scrollTo);
    } else {
      return contenteditable.scrollTop(oldScrollTop);
    }
  }

  isMarkerNode(element) {
    const id = $(element).attr("id");
    return id != null && id.indexOf(this.SELECTION_MARKER_PREFIX) !== -1;
  }

  // Rangy's save/restore can by too much for our needs. Sometimes -- and
  // actually the only time -- we need something simple is handling when
  // the enter key is pressed. We use our own marker that has a similar prefix
  // to Rangy's and then jostle it around a bit as needed. See the handleEnter()
  // function.
  insertSelectionMarkerAtCursor(contenteditable) {
    const span = $(document.createElement("span"));
    span.attr("id", this.SELECTION_MARKER_PREFIX + new Date().getTime());
    this.insertNodesAtSelection(contenteditable, span, false, false);
    return span;
  }

  // Return the selection to what it should be by selecting
  // a marker we created earlier.
  selectAndRemoveMarker(contenteditable, marker) {
    contenteditable.focus();
    const selection = Rangy.getSelection();
    const range = Rangy.createRange();
    if (typeof marker[0] === "object") {
      range.collapseAfter(marker[0]);
    }
    selection.setSingleRange(range);
    return this.removeMarker(marker);
  }

  // For a rare case where we don't want to select, but we do want to remove.
  removeMarker(marker) {
    return marker.remove();
  }

  fireChangedEvent(input) {
    // Here to let things like keypresses finish before any action
    // is taken.
    return setTimeout(() => input.trigger("changed"), 1);
  }

  elementHasStyle(element, style) {
    return ($(element).attr("style") || "").indexOf(style) >= 0;
  }
}

const instance = new RichTextArea();
export { instance as RichTextArea };

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null
    ? transform(value)
    : undefined;
}
