import { Model } from "./Model";

class Attachment extends Model {
  static primaryKey = "guid";

  isFinished() {
    return this.url != null;
  }

  static attributes() {
    return ["size_in_bytes", "title", "guid", "url", "content_type"];
  }

  static indexOn() {
    return ["guid"];
  }
}

export { Attachment };
