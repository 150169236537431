class Device {
  static isMobile() {
    return screen.width < 768;
  }

  static isDesktop() {
    return !this.isMobile();
  }
}

export { Device };
