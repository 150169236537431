/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */

import { Config } from "src/config";

import { ItemView } from "./ItemView";

import { FocusManager } from "src/helpers/FocusManager";
import { UploadManager } from "src/helpers/UploadManager";

class PhotoView extends ItemView {
  wrapper = null;
  caption = null;
  image = null;
  src = null;

  constructor(item) {
    super(item);
    this.container.addClass("photo");
    this.container.width(this.item.object.width || "");
    this.container.height(this.item.object.height || "");
  }

  render() {
    const view = this;
    const leftShadow = $(document.createElement("div"));
    leftShadow.addClass("shadow-left ei-bottom-left-shadow");
    const rightShadow = $(document.createElement("div"));
    rightShadow.addClass("shadow-right ei-bottom-right-shadow");
    const middle = $(document.createElement("div"));
    middle.addClass("middle");
    const backgroundWrapper = $(document.createElement("div"));
    backgroundWrapper.addClass("background-wrapper");
    this.container.append(leftShadow);
    this.container.append(rightShadow);
    this.container.append(backgroundWrapper);
    this.container.append(middle);
    const loading = $(document.createElement("div"));
    loading.addClass("loading");
    middle.append(loading);
    this.container.on("mousedown", () => FocusManager.focus(view.item));

    this.image = document.createElement("img");
    $(this.image).on("load", function() {
      middle.children().remove();
      const image = $(view.image);
      image.addClass("image");

      // Add the image to the body, way out in the boonies,
      // to let the image render and give us the real size.
      image.css("position", "absolute");
      image.css("left", "-100000px");
      image.css("top", "-100000px");
      $(document.body).append(image);
      const realWidth = image.width();
      const realHeight = image.height();
      image.detach();
      image.css("position", "");
      image.css("left", "");
      image.css("top", "");
      image.addClass("no-user-select");
      image.on("dragstart selectstart", () => false);

      middle.append(image);

      // Calculate the width and height of the container based on the
      // width set in CSS. This will set the initial dimensions that
      // will stay when the photo is resized.
      const calculateNewHeight = function() {
        const currentWidth = view.item.object.width;
        const leftWidth = parseInt(middle.css("left"));
        const rightWidth = parseInt(middle.css("right"));
        const topHeight = parseInt(middle.css("top"));
        const bottomHeight = parseInt(middle.css("bottom"));
        const availableWidth = currentWidth - (leftWidth + rightWidth);
        return (
          parseInt(availableWidth * (realHeight / realWidth)) +
          topHeight +
          bottomHeight
        );
      };

      view.item.on("width_change", function(event, width) {
        if (width < view.minWidth) {
          view.item.object.set("width", view.minWidth);
          width = view.minWidth;
        }
        return view.container.width(width);
      });

      view.item.on("height_change", function(event, height) {
        let newHeight = calculateNewHeight();
        if (newHeight < view.minHeight) {
          newHeight = view.minHeight;
        }
        view.container.height(newHeight);

        // If we'd tried to set the hieght to something that wasn't
        // in dimensions, lets set it back to closest possible.
        if (newHeight !== height) {
          return view.item.object.set("height", newHeight);
        }
      });

      // Trigger a height change that'll calculate the correct height.
      view.item.trigger("height_change");

      // Remove this handler. We don't want to do all this again
      // if the src were to change.
      return image.off("load");
    });

    this.image.onerror = function() {
      middle.children().remove();
      const image = $(document.createElement("div"));
      image.addClass("image");
      const broken = document.createElement("div");
      broken.innerHTML =
        "This image URL is broken.<br><br>Please delete and try again.";
      $(broken).addClass("broken");
      image.append(broken);
      return middle.append(image);
    };

    // Set the src to set the whole thing in motion.
    this.resetSrc();

    // If it's changed on the item directly, change the view.
    this.item.object.on("src_change attachment_change", () =>
      view.resetSrc()
    );

    $(this).on("resizestop", () => view.resetSrc());

    // For all upload events, if we already have an image, send them down
    // to the cork as a new upload.
    UploadManager.watch(this);
    $(this).on("uploaddrop uploadstarted uploadprogress uploaddone", function(
      event
    ) {
      // Let's get a real array.
      const arguments_ = Array.prototype.slice.call(arguments);

      // Shift off the event.
      arguments_.shift();

      // If we don't have an image src yet, then it's possible
      // to set the attachment (in this case, when dropping a
      // new image on the board). Let's allow it.
      if (view.image.src === "") {
        if (event.type === "uploaddone") {
          // Refer to event definition of uploaddone.
          const attachment = arguments_[0];

          // Set the attachment, and update the iagem.
          view.item.object.set({ attachment });
          view.resetSrc();
          view.item.save();
        }
        return;
      }

      // If we're here, we're sending the event to the cork.
      return $(view.cork).trigger(event.type, arguments_);
    });

    // Prevent the ability to natively drag the image in some browsers (FF, IE).
    return $(this.image).on("mousedown mousemove", event =>
      event.preventDefault()
    );
  }

  resetSrc() {
    let src = "";

    // Use the attachment if it exists, otherwise use the src (old images).
    if (this.item.object.attachment != null) {
      const { url } = this.item.object.attachment;
      src =
        Config.get("image_cdn_host") +
        "/resize?url=" +
        encodeURIComponent(url);
      if (this.item.object.width < this.item.object.height) {
        src += "&w=" + this.item.object.width;
      } else {
        src += "&h=" + this.item.object.height;
      }
    } else if (this.item.object.src != null) {
      ({ src } = this.item.object);
    }
    if (src !== "") {
      return (this.image.src = src);
    }
  }
}

export { PhotoView };
