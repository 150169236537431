class TeamBillingAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  overview({ team_id, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/billing/overview`, {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  receipts({ team_id, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/billing/receipts`, {
      type: "GET",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_receipt_email({ team_id, receipt_email, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/billing/receipt_email`, {
      type: "POST",
      data: JSON.stringify({ email: receipt_email }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_token({
    team_id,
    token_id,
    three_d_token,
    success,
    failure,
    complete
  }) {

    this.API.sendRequest(`/team/${team_id}/billing/token`, {
      type: "POST",
      data: JSON.stringify({ token: token_id, three_d_token: three_d_token }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  change_plan({
    team_id,
    plan_code,
    token_id,
    three_d_token,
    success,
    failure,
    complete
  }) {
    this.API.sendRequest(`/team/${team_id}/billing/change_plan`, {
      type: "POST",
      data: JSON.stringify({
        plan_code,
        token: token_id,
        three_d_token: three_d_token
      }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  updateFormURL(team_id) {
    return this.API.secureURL(`/team/${team_id}/billing/update`);
  }

  invoiceURL(team_id, invoice_id) {
    return this.API.secureURL(`/team/${team_id}/billing/invoice/${invoice_id}`);
  }
}

class TeamAPI {
  API;

  constructor(API) {
    this.API = API;
    this.Billing = new TeamBillingAPI(API);
  }

  invite({ team_id, email, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/invite`, {
      type: "POST",
      data: JSON.stringify({ email }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  // Requires team_id, invite_code, email and either {full_name, password} or {current_user=true}
  accept_invite({
    team_id,
    invite_code,
    email,
    full_name,
    password,
    current_user,
    success,
    failure,
    complete
  }) {
    this.API.sendRequest(
      `/team/${team_id}/accept_invite/${invite_code}`,
      {
        type: "POST",
        data: JSON.stringify({
          full_name,
          email,
          password,
          current_user
        }),
        success,
        error: failure,
        complete,
        processData: false,
        contentType: "application/json",
        dataType: "json"
      }
    );
  }

  change_name({ team_id, name, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/change_name`, {
      type: "POST",
      data: JSON.stringify({ name }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  update_membership({ team_id, user_id, role, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/member/${user_id}`, {
      type: "POST",
      data: JSON.stringify({ role }),
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  remove_member({ team_id, user_id, success, failure, complete }) {
    this.API.sendRequest(`/team/${team_id}/member/${user_id}`, {
      type: "DELETE",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

export { TeamAPI };
