class ItemAPI {
  API;

  constructor(API) {
    this.API = API;
  }

  update({ item, success, failure, complete }) {
    let json = JSON.stringify({ item: item.serialize() });
    json = this.API.removeUnicodeCarriageReturns(json);

    this.API.sendRequest(`/item/${item.id}`, {
      type: "POST",
      data: json,
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }

  destroy({ item_id, success, failure, complete }) {
    this.API.sendRequest(`/item/${item_id}`, {
      type: "DELETE",
      success,
      error: failure,
      complete,
      processData: false,
      contentType: "application/json",
      dataType: "json"
    });
  }
}

export { ItemAPI };
