import { Account } from "./Account";
import { Model } from "./Model";

import { UserIcon } from "src/controls/UserIcon";

class User extends Model {
  constructor(data, buildOptions) {
    super(data, buildOptions);
    const user = this;

    this.entrance_time = this.instanciated_at;

    // Ensure a change event is fired for the display name
    // in the case where the first or last name is updated
    // but no user-defined display name has been set.
    const triggerDisplayNameChange = function(event, name) {
      this.trigger("display_name_change", [user.displayName()]);
    };

    this.on("first_name_change", triggerDisplayNameChange);
    this.on("last_name_change", triggerDisplayNameChange);
  }

  // Full name of the user unless a user-defined
  // display name has been set explicitly.
  displayName() {
    let last_name = "";
    if (this.last_name !== "") {
      last_name = ` ${this.last_name[0].toUpperCase().trim()}.`;
    }
    return `${this.first_name}${last_name}`;
  }

  // First name of the user unless a user-defined
  // display name has been set explicitly.
  chatName() {
    if (this.display_name !== "") {
      return this.first_name;
    }
    return this.display_name;
  }

  fullName() {
    let name = this.first_name;
    if (this.last_name !== "") {
      name += " " + this.last_name;
    }
    return name;
  }

  hasDefaultName() {
    const defaults = User.defaults();
    return (
      this.first_name === defaults.first_name &&
      this.last_name === defaults.last_name
    );
  }

  owns(board) {
    return this.boards.indexOf(board.string_identifier) >= 0;
  }

  isLoggedIn() {
    return this.guest === false;
  }

  isDistinguished() {
    return this.image_url !== UserIcon.DEFAULT_USER_IMAGE_URL;
  }

  // Information that should be public; i.e., can be sent
  // through the chat interface.
  publicInformation() {
    return {
      clientId: this.clientId,
      first_name: this.first_name,
      last_name: this.last_name,
      display_name: this.display_name,
      image_url: this.image_url,
      rtc_user_id: this.rtc_user_id,
      entrance_time: this.entrance_time.getTime()
    };
  }

  createUserIcon() {
    return UserIcon.create(this);
  }

  is_member(account) {
    return this.accounts.has(account);
  }

  is_admin(account) {
    return this.admin_accounts.has(account);
  }

  entrance_time_position() {
    return User.by_entrance_time().indexOf(this);
  }

  // Ensure entrance_time is always a date.
  filter(attribute, newValue) {
    if (attribute === "entrance_time") {
      newValue = new Date(newValue);
    }
    return newValue;
  }

  static attributes() {
    return [
      "id",
      "first_name",
      "last_name",
      "email",
      "accounts",
      "admin_accounts",
      "boards",
      "image_url",
      "rtc_user_id",
      "entrance_time"
    ];
  }

  static defaults() {
    return {
      guest: true,
      first_name: "Guest",
      last_name: "",
      display_name: "",
      email: "",
      boards: [],
      accounts: [],
      admin_accounts: [],
      past_due: false,
      purchase_order: false,
      image_url: UserIcon.DEFAULT_USER_IMAGE_URL,
      rtc_user_id: null
    };
  }
  //entrance_time: @instanciated_at

  static indexOn() {
    return ["id", "rtc_user_id", "image_url"];
  }

  static associations() {
    return {
      accounts: Account,
      admin_accounts: Account
    };
  }

  static by_entrance_time() {
    return User.all().sort(
      (a, b) => a.entrance_time.getTime() - b.entrance_time.getTime()
    );
  }
}

export { User };
