import { Config } from "src/config";
import { NoteApp } from "src/api/v1";

import { Modal } from "./Modal";

import { CTAButton } from "src/controls/buttons/CTAButton";
import { NoticeManager } from "src/helpers/NoticeManager";

class UserSettingsModal extends Modal {
  show(tabs_to_show, title) {
    const available_tabs = {
      Name: this.createNameTab,
      Email: this.createEmailTab,
      Password: this.createPasswordTab
    };

    if (typeof tabs_to_show === "string") {
      title = tabs_to_show;
      tabs_to_show = null;
    }

    if (title == null) {
      title = "Basic Settings";
    }

    if (tabs_to_show == null) {
      tabs_to_show = Object.keys(available_tabs);
    }

    const tabs = {};

    for (let key of tabs_to_show) {
      tabs[key] = available_tabs[key].call(this);
    }

    NoticeManager.showModal({
      header: title,
      tabs,
      className: "user-settings white",
      closeable: true
    });
  }

  createNameTab() {
    const tab = $(document.createElement("div"));
    const fullName = $(document.createElement("input"));
    fullName.attr("type", "text");
    fullName.attr("placeholder", "e.g., Tim Coulter");
    fullName.val(
      Config.get("user").first_name + " " + Config.get("user").last_name
    );
    const hint = this.createHint(
      "We'll show your first name and last initial when you visit and interact with a board."
    );
    const saveButtonText = "Save";
    const saveButton = CTAButton.createSmall(saveButtonText);
    const changeHint = function(text) {
      hint.hide();
      hint.find(".hint-text").html(text);
      hint.fadeIn();
    };

    saveButton.on("click", function() {
      CTAButton.changeText(saveButton, "Please wait...");
      CTAButton.disable(saveButton);

      const success = function(data, textStatus, jqXHR) {
        changeHint("Name changed successfully.");
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);

        // Update the user's name in the config.
        Config.get("user").set({
          first_name: data.first_name,
          last_name: data.last_name
        });
      };

      const failure = function(jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }

        changeHint(data.failure);
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      NoteApp.API.User.change_name({
        full_name: fullName.val().trim(),
        success,
        failure
      });
    });

    tab.append("<label>Full Name:</label>");
    tab.append(fullName);
    tab.append(hint);
    tab.append(saveButton);
    return tab;
  }

  createEmailTab() {
    const tab = $(document.createElement("div"));
    const currentEmail = $(document.createElement("div"));
    currentEmail.addClass("current-email");
    currentEmail.text(Config.get("user").email);
    const newEmail = $(document.createElement("input"));
    newEmail.attr("type", "text");
    newEmail.attr("placeholder", "newemail@example.com");
    const currentPassword = $(document.createElement("input"));
    currentPassword.attr("type", "password");
    currentPassword.attr("placeholder", "********");
    const hint = this.createHint("");
    hint.hide();
    const saveButtonText = "Save";
    const saveButton = CTAButton.createSmall(saveButtonText);
    const changeHint = function(text) {
      hint.hide();
      hint.find(".hint-text").html(text);
      hint.fadeIn();
    };

    saveButton.on("click", function() {
      CTAButton.changeText(saveButton, "Please wait...");
      CTAButton.disable(saveButton);
      const payload = {
        password: currentPassword.val(),
        email: newEmail.val().trim()
      };

      const success = function(data, textStatus, jqXHR) {
        changeHint("Email changed successfully.");
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);

        // Update the user's email. Because of event triggering,
        // this will update all relavent UI elements.
        Config.get("user").set("email", newEmail.val().trim());

        // Remove values to show that they were accepted.
        currentPassword.val("");
        newEmail.val("");
        currentEmail.text(Config.get("user").email);
      };

      const failure = function(jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }

        changeHint(data.failure);
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      NoteApp.API.User.change_email({
        payload,
        success,
        failure
      });
    });

    tab.append("<label>Current Address:</label>");
    tab.append(currentEmail);
    tab.append("<br>");
    tab.append("<label>New Address:</label>");
    tab.append(newEmail);
    tab.append("<br>");
    tab.append("<label>Current Password:</label>");
    tab.append(currentPassword);
    tab.append(hint);
    tab.append(saveButton);
    return tab;
  }

  createPasswordTab() {
    const tab = $(document.createElement("div"));
    const newPassword = $(document.createElement("input"));
    newPassword.attr("type", "password");
    newPassword.attr("placeholder", "********");
    const passwordConfirmation = $(document.createElement("input"));
    passwordConfirmation.attr("type", "password");
    passwordConfirmation.attr("placeholder", "********");
    const currentPassword = $(document.createElement("input"));
    currentPassword.attr("type", "password");
    currentPassword.attr("placeholder", "********");
    const hint = this.createHint("");
    hint.hide();
    const saveButtonText = "Save";
    const saveButton = CTAButton.createSmall(saveButtonText);
    const changeHint = function(text) {
      hint.hide();
      hint.find(".hint-text").html(text);
      hint.fadeIn();
    };

    saveButton.on("click", function() {
      if (newPassword.val().length < 6) {
        changeHint("Your new password must be six characters or longer.");
        return;
      }
      if (newPassword.val() !== passwordConfirmation.val()) {
        changeHint("Your passwords must match.");
        return;
      }
      CTAButton.changeText(saveButton, "Please wait...");
      CTAButton.disable(saveButton);
      const payload = {
        current_password: currentPassword.val(),
        new_password: newPassword.val()
      };

      const success = function(data, textStatus, jqXHR) {
        changeHint("Password changed successfully.");

        // Remove values to show that they were accepted.
        currentPassword.val("");
        newPassword.val("");
        passwordConfirmation.val("");
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      const failure = function(jqXHR, textStatus, errorThrown) {
        let data;
        try {
          data = JSON.parse(jqXHR.responseText);
        } catch (e) {
          data = {
            failure: "Server error. Please try again later or contact support."
          };
        }

        changeHint(data.failure);
        CTAButton.changeText(saveButton, saveButtonText);
        CTAButton.enable(saveButton);
      };

      NoteApp.API.User.change_password({
        payload,
        success,
        failure
      });
    });

    tab.append("<label>New Password:</label>");
    tab.append(newPassword);
    tab.append("<br>");
    tab.append("<label>Confirm Password:</label>");
    tab.append(passwordConfirmation);
    tab.append("<br>");
    tab.append("<label>Current Password:</label>");
    tab.append(currentPassword);
    tab.append(hint);
    tab.append(saveButton);
    return tab;
  }
}

export { UserSettingsModal };
