class Config {
  constructor() { }

  add(hash, value) {
    if (value != null) {
      const toSend = {};
      toSend[hash] = value;
      this.add(toSend);
      return;
    }

    for (let key in hash) {
      if (!hash.hasOwnProperty(key)) {
        continue;
      }
      if (this[key] instanceof Function) {
        throw "Overriding function in Config!";
      }
      const oldValue = this[key];
      let newValue = hash[key];

      // Convert string values to native types if applicable.
      if (typeof newValue === "string") {
        if (newValue === "true") {
          newValue = true;
        } else if (newValue === "false") {
          newValue = false;
        } else if (newValue.match(/^\d+\.?\d*$/) != null) {
          newValue = parseFloat(newValue);
        }
      }
      this[key] = newValue;

      // Trigger an event that this config value has changed.
      if (newValue !== oldValue) {
        $(this).trigger(key + "_changed", [newValue, oldValue]);
      }
    }
  }

  addFromQueryString(query) {
    if (!query) {
      query = document.location.search;
    }

    // Remove first question mark if it exists.
    if (query.indexOf("?") === 0) {
      query = query.substring(1);
    }
    const pairs = query.split("&");
    let index = 0;

    while (index < pairs.length) {
      const pair = pairs[index].split("=");
      const key = pair[0];
      let value = pair[1];

      // i.e., ?upgrade. Ensure's it's set to something.
      this.set(key, value || "");
      index++;
    }
  }

  set(key, value) {
    const hash = {};
    hash[key] = value;
    return this.add(hash);
  }

  // To make it easier to add a hash of data but remove
  // a few items that aren't needed.
  remove(key) {
    const value = this[key];
    delete this[key];

    return value;
  }

  // Will throw an error if value was not set (undefined).
  get(key) {
    const value = this[key];
    if (value === undefined) {
      throw "Tried getting a config variable that was not set: " + key;
    }
    return value;
  }

  isSet(key) {
    return this[key] !== undefined;
  }

  has(key, value) {
    return this.isSet(key) && this.get(key) === value;
  }

  isNew() {
    try {
      return this.get("new");
    } catch (e) {
      return false
    }
  }

  isEmbed() {
    try {
      return this.get("embed");
    } catch (e) {
      return false;
    }
  }

  isViewOnlyURL() {
    try {
      return this.get("readOnly");
    } catch (e) {
      return false
    }
  }

  isLoaded() {
    try {
      return this.get("loaded");
    } catch (e) {
      return false
    }
  }

  isOffendingEmbed() {
    // Only Pro users can embed boards.
    if (!this.isEmbed()) {
      return false;
    }
    if (window === window.top) {
      return false;
    }
    if (!this.get("board")) {
      return true;
    }
    return this.get("board").account.plan.allows_embed === false;
  }

  isOffendingViewOnly() {
    if (!this.isViewOnlyURL()) {
      return false;
    }
    if (!this.get("board")) {
      return true;
    }
    return this.get("board").account.plan.allows_view_only === false;
  }

  hasSeenRegistration() {
    return this.get("registration_seen_count") > 0;
  }

  increaseRegistrationSeenCount() {
    return this.set(
      "registration_seen_count",
      this.get("registration_seen_count") + 1
    );
  }

  getIdentifier() {
    return this.get("string_identifier");
  }

  // If the identifier was changed, let's make sure we know what's going on.
  setIdentifier(newIdentifier) {
    const oldIdentifier = this.getIdentifier();

    // We should really do nothing if identifiers are equal, but I believe this
    // function is called elsewhere to trigger an event. So let's not do any of this.
    // Note: If we were to execute the "delete" below when both new and old
    // identifier are the same, we'd end up deleting it from the list. Not good.
    if (newIdentifier !== oldIdentifier) {
      const { boards } = this.get("user");
      let index = 0;

      while (index < boards.length) {
        const board = boards[index];
        if (board === oldIdentifier) {
          boards[index] = newIdentifier;
          break;
        }
        index++;
      }
    }

    // Finally, update the config which all other objects will listen to.
    this.set("string_identifier", newIdentifier);
  }
}

const instance = new Config();
export { instance as Config };
