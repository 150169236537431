import { KeyCodes } from "src/base/KeyCodes";
import { Config } from "src/config";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
class URLTextBox {
  availableCharacters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";

  // From here:
  // http://stackoverflow.com/questions/263743/how-to-get-cursor-position-in-textarea
  getCaretPosition = el => {
    if (el.selectionStart) {
      return el.selectionStart;
    } else if (document.selection) {
      el.focus();
      const r = document.selection.createRange();
      if (r == null) {
        return 0;
      }
      const re = el.createTextRange();
      const rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint("EndToStart", re);
      return rc.text.length;
    }
    return 0;
  };

  create(defaultText, className) {
    const klass = this;
    const urlPrefix = this.getURLPrefix();
    const url = $(document.createElement("input"));
    url.attr("type", "text");
    url.addClass("control url");
    if (className != null) {
      url.addClass(className);
    }
    url.val(urlPrefix + (defaultText || ""));
    url.on("keydown", function(event) {
      const character = String.fromCharCode(event.which);
      const caretPosition = klass.getCaretPosition(url.get(0));
      if (event.which === KeyCodes.BACKSPACE) {
        const selectedText = klass.getSelectedText(url.get(0));

        // If we have selected text...
        if (selectedText.length > 0) {
          // ... and we're in a spot greater than or equal to the prefix...
          if (caretPosition >= urlPrefix.length) {
            // allow the backspace.
            return true;
          } else {
            return false;
          }
        }
        if (selectedText === "" && caretPosition <= urlPrefix.length) {
          return false;
        }
        return true;
      }

      // Just for the illusion you can't go past that point. Note that the === 0
      // is there because Firefox does not return the correct keyCode on left and right.
      if (
        (event.which === KeyCodes.LEFT || event.which === 0) &&
        caretPosition === urlPrefix.length
      ) {
        return false;
      }
      return true;
    });

    url.on("keypress", function(event) {
      // Allow specific key overrides for Firefox. This is never reached in Chrome.
      if (
        event.which === KeyCodes.BACKSPACE ||
        event.which === KeyCodes.SHIFT ||
        event.which === KeyCodes.CTRL ||
        event.which === KeyCodes.ALT ||
        event.which === KeyCodes.LEFT ||
        event.which === KeyCodes.RIGHT ||
        event.which === 0
      ) {
        return true;
      }
      const character = String.fromCharCode(event.which);
      const caretPosition = klass.getCaretPosition(url.get(0));
      if (klass.availableCharacters.indexOf(character) === -1) {
        return false;
      }
      if (caretPosition < urlPrefix.length) {
        url.val(url.val() + character);
        return false;
      }
      return true;
    });

    url.on("cut", function(event) {
      const caretPosition = klass.getCaretPosition(url.get(0));
      if (caretPosition < urlPrefix.length) {
        return false;
      }
      if (caretPosition === urlPrefix.length) {
        const selectedText = klass.getSelectedText(url.get(0));
        if (selectedText.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    });

    url.on("paste", function(event) {
      const caretPosition = klass.getCaretPosition(url.get(0));
      if (caretPosition < urlPrefix.length) {
        return false;
      }
      const content = url.val();
      var hasUpdated = function() {
        let newVal = url.val();
        if (newVal !== content) {
          newVal = newVal.replace(urlPrefix, "");
          newVal = klass.sanitize(newVal);
          newVal = urlPrefix + newVal;
          return url.val(newVal);
        } else {
          return setTimeout(hasUpdated, 10);
        }
      };

      return setTimeout(hasUpdated, 10);
    });

    return url;
  }

  sanitize(str) {
    // Cheap. Replace all bad characters with spaces then replace all spaces.
    // Probably a more elegant way.
    let index = 0;

    while (index < str.length) {
      if (this.availableCharacters.indexOf(str[index].toString()) === -1) {
        str = str.replace(str[index], "");
        index--;
      }
      index++;
    }
    return str;
  }

  getSelectedText(textComponent) {
    let selectedText = undefined;

    // IE version
    if (document.selection !== undefined) {
      textComponent.focus();
      const sel = document.selection.createRange();
      selectedText = sel.text;

      // Mozilla version
    } else if (textComponent.selectionStart !== undefined) {
      const startPos = textComponent.selectionStart;
      const endPos = textComponent.selectionEnd;
      selectedText = textComponent.value.substring(startPos, endPos);
    }
    return selectedText;
  }

  getIdentifier(urlTextBox) {
    return urlTextBox.val().replace(this.getURLPrefix(), "");
  }

  reset(urlTextBox) {
    const identifier = this.getIdentifier(urlTextBox);
    const currentValue = urlTextBox.val();
    const newValue = currentValue.replace(identifier, "");
    return urlTextBox.val(newValue);
  }

  getURLPrefix() {
    return `${Config.get("host")}/`;
  }
}

const instance = new URLTextBox();
export { instance as URLTextBox };
