/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class NoticeManager {
  // Note: Converted from JS.

  // This could get confusing real quickly. Options are:
  //	 *
  //	 * {
  //	 *     closeable: Optional. Boolean. Sticks a close button in top right.
  //	 *         Default to false.
  //	 *     onclose: Function. If closeable, a function may be specified that will
  //	 *         be called when the modal is closed. If it returns false, the modal
  //	 * 				 won't be closed.
  //	 *     header: Optional. String, HTML or element. Will be inserted in a black bar
  //	 *         at the top of the modal. If not specified, no bar is shown.
  //	 *		 footer: Optional. String, HTML or element. Will be inserted in a black bar
  //	 *         at the bottom of the modal. If not specified, no bar is shown.
  //	 *     element: Must either specify this or tabs. String, HTML or Element. If this
  //	 *         is specified, the modal will be filled with this data.
  //	 *     tabs: Must either specify this or element. Hash of tab names -> String, HTML
  //	 *         or element that represent tabs shown by the modal. Order matters.
  //	 *		 startingTab: name of tab that should be selected when the window is opened.
  //	 *     buttons: Optional. Hash of button names -> click functions. These buttons
  //	 *         will stay visible in the modal regardless of tab change.
  //	 * 		 className: Optional. Class or classes added to the block element.
  //  *     lightbox: Default to true. Setting to false will remove shadow around modal.
  //	 * }
  //	 *
  //	 * Note: The NoticeManager will trigger a "visible" event on each tab once it has
  //	 * become visible.
  //	 *
  //
  showModal(options) {
    const manager = this;

    options = options || {};
    const element = options.element || null;
    const tabs = options.tabs || null;
    const startingTab =
      options.startingTab || Object.keys(tabs || {})[0] || null;

    if (element == null && tabs == null) {
      throw "Must specify either 'element' or 'tabs' to display a modal.";
    }

    // Remove anything that's already there.
    this.hideModal();

    const body = $("body");

    const noticeOuter = $(document.createElement("div"));
    noticeOuter.addClass("notice outer");
    if (options.lightbox != null && options.lightbox === false) {
      noticeOuter.addClass("no-shadow");
    }

    const noticeInner = $(document.createElement("div"));
    noticeInner.addClass("notice inner");

    const horizontalCenter = $(document.createElement("div"));
    horizontalCenter.addClass("notice horizontal-center");

    const noticeBlock = $(document.createElement("div"));
    noticeBlock.addClass("notice block");

    const noticeBody = $(document.createElement("div"));
    noticeBody.addClass("notice body");

    const backgroundRGBAFix = $(document.createElement("div"));
    backgroundRGBAFix.addClass("notice background-rgba-fix");

    body.append(noticeOuter);
    noticeOuter.append(noticeInner);
    noticeInner.append(horizontalCenter);
    horizontalCenter.append(noticeBlock);
    noticeBlock.append(noticeBody);
    noticeBody.append(backgroundRGBAFix);

    // Now actually process the notice. Note that the following code
    //		   was written before notice was encapsulated.
    const closeable = options.closeable === true ? true : false;
    const onclose = options.onclose || function() {};

    const header = options.header || null;
    const footer = options.footer || null;
    const className = options.className || "";

    // Add modal classname to the block.
    noticeBlock.addClass(className);

    // Add special classes to the rgba fix depending on whether
    // there's a header or footer.
    if (header != null) {
      backgroundRGBAFix.addClass("with-header");
    }
    if (footer != null) {
      backgroundRGBAFix.addClass("with-footer");
    }
    if (header != null) {
      noticeBody.addClass("with-header");
    }
    if (footer != null) {
      noticeBody.addClass("with-footer");
    }

    // Prevent click events from propagating.
    noticeBlock.on("click", event => event.stopPropagation());

    const elementContainer = $(document.createElement("div"));
    elementContainer.addClass("element-container");
    if (header != null || tabs != null) {
      const headerWrapper = $(document.createElement("div"));
      headerWrapper.addClass("header-wrapper");
      const headerDiv = $(document.createElement("div"));
      headerDiv.addClass("header");
      headerDiv.append(header);
      if (tabs != null) {
        const tabWrapper = $(document.createElement("div"));
        tabWrapper.addClass("tab-wrapper");
        for (let tabName in tabs) {
          const tabDiv = $(document.createElement("div"));
          tabDiv.addClass("tab");
          if (tabName === startingTab) {
            tabDiv.addClass("selected");
          }
          tabDiv.html(tabName);
          tabDiv.on("click",
            this.createModalTabHandler(
              tabWrapper,
              elementContainer,
              tabDiv,
              tabs[tabName]
            )
          );
          tabWrapper.append(tabDiv);
        }
        headerWrapper.append(tabWrapper);

        // Append the tab's data to the modal.
        elementContainer.append(tabs[startingTab]);
      }
      headerWrapper.prepend(headerDiv);
      noticeBlock.prepend(headerWrapper);
    }
    if (footer != null) {
      const footerWrapper = $(document.createElement("div"));
      footerWrapper.addClass("footer-wrapper");
      const footerDiv = $(document.createElement("div"));
      footerDiv.addClass("footer");
      footerDiv.append(footer);
      footerWrapper.prepend(footerDiv);
      noticeBlock.append(footerWrapper);
    }
    if (element != null) {
      elementContainer.append(element);
    }
    noticeBody.append(elementContainer);
    if (closeable === true) {
      const closeButton = $(document.createElement("div"));
      closeButton.addClass("close ei-close");
      closeButton.on("click", function() {
        const result = onclose();
        if (result !== false) {
          return manager.hideModal();
        }
      });

      noticeOuter.on("click", () => manager.hideModal(true));

      noticeBlock.prepend(closeButton);
    }
    const notice = $(".notice");
    notice.css("display", "");
    notice.css("visibility", "visible");

    // Fire the "visible" event for the first tab.
    if (tabs != null) {
      return $(tabs[startingTab]).trigger("visible");
    }
  }

  hideModal(fadeOut, callback) {
    if (fadeOut == null) {
      fadeOut = false;
    }
    const notice = $(".notice");
    notice.stop();

    // If there are no elements on the page, return.
    if (notice.length === 0) {
      return;
    }
    callback = callback || function() {};

    let count = 0;
    const intermediary = function() {
      count += 1;
      if (count === notice.length) {
        // Everything's faded out nicely. Now remove the elements.
        notice.remove();
        return callback();
      }
    };

    if (fadeOut === true) {
      return notice.fadeOut(500, intermediary);
    } else {
      return notice.fadeOut(0, intermediary);
    }
  }

  setModalHeaderText(text) {
    // Do the wrapper lookup to make sure we scope it.
    const header = $(".header-wrapper").find(".header");
    header.hide();
    header.html(text);
    return header.fadeIn();
  }

  hideModalTabs() {
    return $(".header-wrapper")
      .find(".tab-wrapper")
      .hide();
  }

  showModalTabs() {
    return $(".header-wrapper")
      .find(".tab-wrapper")
      .fadeIn();
  }

  createModalTabHandler(tabWrapper, elementContainer, tabDiv, element) {
    return function(event) {
      tabWrapper.children().removeClass("selected");
      tabDiv.addClass("selected");

      // Fix the height before we change things
      elementContainer.css({ height: elementContainer.height() + "px" });
      elementContainer.contents().detach();
      elementContainer.append(element);
      elementContainer.animate(
        { height: element[0].scrollHeight + "px" },
        {
          complete() {
            return elementContainer.css({ height: "" });
          }
        }
      );

      return $(element).trigger("visible");
    };
  }
}

const instance = new NoticeManager();
export { instance as NoticeManager };
