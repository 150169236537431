import { Config } from "src/config";

import { Model } from "./Model";
import { PostIt } from "./PostIt";
import { Photo } from "./Photo";
import { Point } from "src/base/Point";
import { Rectangle } from "src/base/Rectangle";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
class Item extends Model {
  view;
  saving = false;
  saveQueue;

  constructor(data, buildOptions) {
    super(data, buildOptions);
    const klass = this.object.viewClass();
    this.view = new klass(this);
    this.saveQueue = [];

    const item = this;
    $(this.object).on("width_change height_change", function(
      event,
      newValue,
      oldValue
    ) {
      if (newValue === oldValue) {
        return;
      }

      $(item).trigger(event, [newValue, oldValue]);

      // Simulate the change event.
      const attribute = event.type.substring(0, event.type.indexOf("_"));
      const oldValues = {};
      const newValues = {};
      oldValues[attribute] = oldValue;
      newValues[attribute] = newValue;
      $(item).trigger("change", [newValues, oldValues]);
    });
  }

  position() {
    return new Point(this.x, this.y);
  }

  rectangle() {
    return new Rectangle(
      this.x,
      this.y,
      this.x + this.object.width,
      this.y + this.object.height
    );
  }

  filter(attribute, newValue) {
    if (attribute === "x" || attribute === "y") {
      newValue = parseInt(newValue);
    }

    return newValue;
  }

  // Like set(), but will "animate" x, y, width and height.
  // options is meant to be a serialized item.
  update(options, complete) {
    const item = this;
    complete = complete || function() {};

    this.set("z", options.z);
    const newWidth = options.object.width;
    const newHeight = options.object.height;
    delete options.object.width;

    delete options.object.height;

    item.object.set(options.object);

    // Note: Keys of "width" and "height" causes special computed style
    // checks in jQuery's animate that prevent the items from updating.
    // We use "w" and "h" instead.
    const current = {
      x: item.x,
      y: item.y,
      w: item.object.width,
      h: item.object.height
    };

    // Just to be sure - let's not animate
    // anything that doesn't need animating.
    const params = {
      x: options.x,
      y: options.y,
      w: newWidth,
      h: newHeight
    };

    for (let key in params) {
      if (params.hasOwnProperty(key) && params[key] === current[key]) {
        delete params[key];
      }
    }
    const step = function() {
      item.set({
        x: current.x,
        y: current.y
      });

      return item.object.set({
        width: current.w,
        height: current.h
      });
    };

    return $(current).animate(params, {
      duration: 230,
      easing: "swing",
      step,
      complete() {
        step();
        return complete();
      }
    });
  }

  save(options) {
    const item = this;
    if (!this.object.valid()) {
      return;
    }
    options = options || {};
    options.success = options.success || function() {};

    options.failure = options.failure || function() {};

    this.saveQueue.push(options);

    // Don't do anything more if we're saving.
    if (this.saving === true) {
      return;
    }
    this.saving = true;
    const nextToSave = this.saveQueue;
    this.saveQueue = [];
    const saveAgain = () =>
      // Call setTimeout to get it on a different "thread".
      setTimeout(function() {
        if (item.saveQueue.length !== 0) {
          return item.save();
        }
      }, 1);

    const success = function(data, textStatus, xmlHttpRequest) {
      // This is a specific success function for one item.
      data = data.items[0];

      // If we just saved a new object, set the id.
      if (item.id == null) {
        item.set("id", data.id);
      }
      let index = 0;

      while (index < nextToSave.length) {
        nextToSave[index].success(data, textStatus, xmlHttpRequest);
        index++;
      }
      item.saving = false;
      return saveAgain();
    };

    const failure = function(jqXHR, textStatus, errorThrown) {
      options.failure(jqXHR, textStatus, errorThrown);
      let index = 0;

      while (index < nextToSave.length) {
        nextToSave[index].failure(jqXHR, textStatus, errorThrown);
        index++;
      }
      item.saving = false;
      return saveAgain();
    };

    return Config.get("datastore").save({
      item: this,
      success,
      failure
    });
  }

  del(options) {
    const item = this;
    item.remove();
    if (item.id == null) {
      return;
    }
    options = options || {};
    options.success = options.success || function() {};

    options.failure = options.failure || function() {};

    return Config.get("datastore").del({
      item: this,
      success: options.success,
      failure: options.failure
    });
  }
  static associations(model) {
    if (model.objectType == "post_it") {
      return { object: PostIt };
    }
    if (model.objectType == "photo") {
      return { object: Photo };
    }
    throw "Unsupported object model.";
  }

  static attributes() {
    return ["id", "objectType", "object", "x", "y", "z"];
  }

  static indexOn() {
    return ["id"];
  }
}
Item.primaryKey = "id";

export { Item };
