import React from "react";

import { PostIt, AVAILABLE_COLORS } from "src/models/PostIt";

import { GenericTextButton } from "src/controls/buttons/GenericTextButton";

import { Popup } from "src/controls/chrome/Popup";

import { UploadManager } from "src/helpers/UploadManagerNew";

class ItemToolbar extends React.Component {
  uploadManager;

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentColor: this.props.item.object.color || "yellow",
      trashSelected: false
    };
  }

  componentDidMount() {
    if (this.props.buttonState.upload && !this.uploadManager) {
        this.uploadManager = new UploadManager(
          this.props.onUploadStart,
          this.props.onUploadProgress,
          this.props.onUploadComplete
        );
    }
  }

  componentWillUnmount() {
    this.uploadManager = null;
  }

  toggleTrash(trashSelected) {
    if (this.props.item.object instanceof PostIt) {
      if (this.props.item.object.isBlank()) {
        this.props.item.del();
        return;
      }
    }
    this.setState({ trashSelected });
  }

  handleColorClick() {
    const { currentColor } = this.state;
    let nextColor = currentColor;

    // Find the next color and remove all colors on the view.
    for (let i in AVAILABLE_COLORS) {
      i = parseInt(i);
      const color = AVAILABLE_COLORS[i];
      const next = i + 1 === AVAILABLE_COLORS.length ? 0 : i + 1;
      if (color === currentColor) {
        nextColor = AVAILABLE_COLORS[next];
        break;
      }
    }

    this.props.item.object.set("color", nextColor);
    this.props.item.save();

    this.setState({ currentColor: nextColor });
  }

  handleUploadChange(event) {
    const files = Array.from(event.target.files);
    files.forEach(file => {
      this.uploadManager.addFile({
        source: "file input",
        name: file.name,
        type: file.type,
        data: file
      });
    });
  }

  buildToolbar() {
    const buttons = [];

    if (this.props.buttonState.color) {
      const color = (
        <div key="note-color" className="button note-color">
          <div
            onClick={() => this.handleColorClick()}
            className={`image ${PostIt.iconClassForColor(
              this.state.currentColor
            )}`}
          ></div>
        </div>
      );

      buttons.push(color);
    }

    if (this.props.buttonState.upload) {
      const upload = (
        <div key="upload" className="button upload">
          <div className="image ei-cloud-upload">
            <input type="file" onChange={this.handleUploadChange.bind(this)} />
          </div>
        </div>
      );

      buttons.push(upload);
    }

    if (this.props.buttonState.zoom) {
      // Now add fancy zoomTo and trash buttons that are on every note.
      const zoomTo = (
        <div key="zoom-to" className="button zoom-to">
          <div
            onClick={() => this.props.item.view.zoomTo()}
            className={`image ei-scope`}
          ></div>
        </div>
      );

      buttons.push(zoomTo);
    }

    if (this.props.buttonState.trash) {
      const trash = (
        <div key="trash" className="button trash">
          <div
            onClick={() => this.toggleTrash(!this.state.trashSelected)}
            className={`image ei-trash`}
          ></div>
        </div>
      );

      if (this.state.trashSelected) {
        const areYouSure = (
          <GenericTextButton
            key="are-you-sure"
            className="are-you-sure"
            onClick={() => this.toggleTrash(!this.state.trashSelected)}
          >
            Are you sure?
          </GenericTextButton>
        );

        // Delete the item. This will cause it to be removed
        // and all views with follow suit.
        const ok = (
          <div key="ok" className="button ok">
            <div
              onClick={() => this.props.item.del()}
              className={`image ei-ok`}
            ></div>
          </div>
        );

        const notok = (
          <div key="notok" className="button notok">
            <div
              onClick={() => this.toggleTrash(false)}
              className={`image ei-notok`}
            ></div>
          </div>
        );

        return [trash, areYouSure, ok, notok];
      }

      buttons.push(trash);
    }

    return buttons;
  }

  render() {
    const buttons = this.buildToolbar();

    return (
      <Popup
        visible={true}
        type="edit"
        className={`ribbon`}
      >
        <div className="bar inline no-user-select">
          <div className="background-rgba-fix"></div>
          <div className="right">{buttons}</div>
        </div>
      </Popup>
    );
  }
}

export { ItemToolbar };
