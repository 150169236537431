import { NoticeManager } from "src/helpers/NoticeManager";
import { Modal } from "./Modal";

class YesNoModal extends Modal {
  constructor() {
    super();
    this.options = {};
  }

  show(options) {
    const modal = this;
    options = options || this.options;

    // TODO: Add the yes/no header icon permanently so the
    //			 caller doesn't have to worry about it. (.ei-help)

    // TODO: There's a better way to do this.
    const header = options.header || this.options.header;
    const text = options.text || this.options.text;
    const yesfn = options.yesfn || this.options.yesfn || function() {};

    const nofn = options.nofn || this.options.nofn || function() {};

    const closeable = options.closeable || this.options.closeable;
    const closefn = options.closefn || this.options.closefn || nofn;
    const hideAfter = options.hideAfter || this.options.hideAfter;
    const yesText = options.yesText || this.options.yesText || "Yes";
    const noText = options.noText || this.options.noText || "No";
    const iconClass = options.iconClass || this.options.iconClass || null;
    const className = options.className || this.options.className || "";

    const yesfnwrapper = function() {
      yesfn();
      if (hideAfter) {
        modal.hide();
      }
    };

    const nofnwrapper = function() {
      nofn();
      if (hideAfter) {
        modal.hide();
      }
    };

    const closefnwrapper = function() {
      closefn();
      if (hideAfter) {
        modal.hide();
      }
    };

    const container = $(document.createElement("div"));

    const message = $(document.createElement("div"));
    message.addClass("message");
    message.append(text);

    const yesButton = $(document.createElement("a"));
    yesButton.addClass("cta blue medium inline yes");
    yesButton.text(yesText);

    const noButton = $(document.createElement("a"));
    noButton.addClass("cta green medium inline no");
    noButton.text(noText);

    yesButton.on("click", yesfnwrapper);
    noButton.on("click", nofnwrapper);

    container.append(message);

    //container.append("<br>");
    container.append(yesButton);
    container.append(noButton);

    const headerContainer = $(document.createElement("span"));

    const headerText = $(document.createElement("span"));
    headerText.addClass("text");
    headerText.append(header);

    if (iconClass) {
      const icon = $(document.createElement("span"));
      icon.addClass("icon");
      icon.addClass(iconClass);
      headerContainer.append(icon);
    }

    headerContainer.append(headerText);

    NoticeManager.showModal({
      header: headerContainer,
      element: container,

      //className: "settings",
      onclose: closefnwrapper,
      className: "yesno " + className,
      closeable
    });
  }
}

export { YesNoModal };
