/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */

import { Model } from "./Model";

class ModelIndex {
  indexOn = "clientId";
  index = null;
  _count = 0;

  constructor(indexOn) {
    this.indexOn = indexOn || this.indexOn;
    this.index = {};
  }

  // Always returns a ModelIndex. Empty if nothing was found.
  get(value) {
    // Did an object get sent our way? Let's look for something
    // we index on that might identify it.
    if (value instanceof Object && !(value instanceof ModelIndex)) {
      value = value[this.indexOn];
    }

    const current = this.index[value];

    if (current instanceof ModelIndex) {
      return current;
    }
    const index = new ModelIndex();
    if (current instanceof Model) {
      index.add(current);
    }
    return index;
  }

  add(model) {
    if (this.has(model)) {
      return;
    }
    const value = model[this.indexOn];
    const current = this.index[value];
    if (current === undefined) {
      this.index[value] = model;
    } else if (current instanceof ModelIndex) {
      current.add(model);
    } else if (current instanceof Model) {
      const newIndex = new ModelIndex();
      newIndex.add(current);
      newIndex.add(model);
      this.index[value] = newIndex;
    } else {
      throw "Could not add model to index: " + model.clientId;
    }
    return (this._count += 1);
  }

  // Most of the time you do not need to set value -- it'll
  // get the value from the model that's passed. However, sometimes,
  // for instance, when the set() method needs to remove the item with
  // a specific value after the model's own attributes have been
  // udpated, a value should be passed.
  //
  // Because we can't tell the difference between a value of undefined
  // (an allowed value) and whether or not the value variable was
  // passed to this function -- in both cases we'd get undefined --
  // instead, if a specific value is to be passed, it must be passed
  // inside an array. The existence of an array will be used to detect
  // that a value was passed, and then the first item in the array
  // will be treated as the actual value to find an item.
  remove(model, value) {
    if (!this.has(model, value)) {
      return;
    }
    if (value instanceof Array) {
      value = value[0];
    } else {
      value = model[this.indexOn];
    }
    const current = this.index[value];
    this._count -= 1;
    if (current instanceof Model) {
      delete this.index[value];

      return true;
    }
    if (current instanceof ModelIndex) {
      const returnVal = current.remove(model, value);
      if (current.count() === 0) {
        delete this.index[value];
      }
      return returnVal;
    }
    throw "Couldn't remove model from index: " + model.clientId;
  }

  // See comments for remove() for parameter information.
  has(model, value) {
    if (value instanceof Array) {
      value = value[0];
    } else {
      value = model[this.indexOn];
    }
    const current = this.index[value];
    if (current instanceof Model) {
      return current.equals(model);
    }
    if (current instanceof ModelIndex) {
      return current.has(model);
    }
    return false;
  }

  each(fn) {
    for (let key in this.index) {
      if (!this.index.hasOwnProperty(key)) {
        continue;
      }
      const value = this.index[key];
      if (value instanceof ModelIndex) {
        if (value.each(fn) === false) {
          return false;
        }
      } else if (value instanceof Model) {
        const model = value;
        if (fn(model) === false) {
          return false;
        }
      }
    }
    return true;
  }

  eachValue(fn) {
    const keys = Object.keys(this.index);
    let i = 0;

    while (i < keys.length) {
      const value = keys[i];
      const index = this.get(value);
      const returnVal = fn(value, index);
      if (returnVal === false) {
        return false;
      }
      i++;
    }
    return true;
  }

  // Note: this will have O(n) performance in older browsers.
  first() {
    const keys = Object.keys(this.index);
    const key = keys[0];
    return this.index[key];
  }

  // Note: this will have O(n) performance on older browsers.
  last() {
    const keys = Object.keys(this.index);
    const key = keys[keys.length - 1];
    return this.index[key];
  }

  count() {
    return this._count;
  }
}

export { ModelIndex };
