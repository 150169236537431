import Rails from '@rails/ujs'

import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";

import { AccessControl } from "./AccessControl";
import { Config } from "src/config";

import { Attachment } from "src/models/Attachment";
import { MathExtras } from "src/utils/MathExtras";

class UploadManager {
  uppy;

  constructor(fnStart, fnProgress, fnSuccess) {
    this.uppy = new Uppy({
      autoProceed: false,
      maxNumberOfFiles: 1
    });

    this.uppy.use(XHRUpload, this.getXhrOptions());

    this.addStartHandler(fnStart);
    this.addProgressHandler(fnProgress);
    this.addSuccessHandler(fnSuccess);

    this.addErrorHandler();
  }

  getXhrOptions = () => {
    const board = Config.get("board");
    return {
      endpoint: `/cork/${board.string_identifier}/attachment`,
      headers: {
        "X-Csrf-Token": Rails.csrfToken()
      },
      fieldName: "file",
      formData: true
    };
  }

  addStartHandler(fnStart) {
    this.uppy.on('file-added', file => {
      // Check permissions here because this is the last line of
      // defense if not checked elsewhere.
      const allowed = this.checkPermissions();
      if (!allowed) {
        return false;
      }

      // Check file size here because this is the last line of
      // defense if not checked elsewhere.
      if (!this.checkFileSize(file.data)) {
        return false;
      }

      // Update options here, just in case the identifier changes between
      // watch()/handle() time and drop time.
      this.uppy.getPlugin("XHRUpload").setOptions(this.getXhrOptions());

      // For the datastore; since we can't use the datastore through this
      // plugin, let's alert the datastore we have requests processing.
      if (Config.isSet("datastore")) {
        Config.get("datastore").requestStarted();
      }

      this.uppy.upload();
      fnStart(file);
    });
  }

  addProgressHandler(fnProgress) {
    this.uppy.on('upload-progress', (file, progress) => {
      const percentage = progress.bytesUploaded / progress.bytesTotal * 100;
      // Note that since we turned singleFileUploads on, we receive this
      // for each request, meaning there's only one file per event.
      fnProgress(file, percentage);
    });
  }

  addSuccessHandler(fnSuccess) {
    this.uppy.on('upload-success', (file, result) => {
      // Note that since we turned singleFileUploads on, we receive this
      // for each request, meaning there's only one file per event.
      const { attachment } = result.body;

      // Add the attachment to the main list.
      const model = new Attachment(attachment);
      Attachment.add(model);

      // For the datastore; since we can't use the datastore through this
      // plugin, let's alert the datastore the request is finished.
      if (Config.isSet("datastore")) {
        Config.get("datastore").requestFinished();
      }

      fnSuccess(model);
    });
  }

  addErrorHandler() {
    this.uppy.on('upload-error', data => {
      if (Config.isSet("datastore")) {
        Config.get("datastore").requestFinished();
      }

      const humanReadable = MathExtras.megabytes(Config.get("maximumFileSize"));

      alert(`Error processing upload. Make sure files are less than ${humanReadable} Mb each. If you continue to see this error, contact support at support@noteapp.com.`);
    });
  }

  addFile({ source, name, type, data }) {
    this.uppy.addFile({ source, name, type, data });
  }

  checkFileSize(file) {
    // Ensure no files added exceed the limit.
    if (file.size > Config.get("maximumFileSize")) {
      const humanReadable = MathExtras.megabytes(
        Config.get("maximumFileSize")
      );

      alert(`Can't upload: Files must be less than ${humanReadable} Mb each.`);
      return false;
    }

    return true;
  }

  checkPermissions() {
    // If we're running inside the dashboard, the dashboard
    // will manage the permissions.
    if (!Config.isSet("application")) {
      return true;
    }

    const app = Config.get("application");
    if (!AccessControl.postAndEditAllowed()) {
      app.showReadOnlyMessage();
      return false;
    }
    if (!AccessControl.uploadAllowed()) {
      app.showReadOnlyMessage(
        "You don't have permission to upload files and images. Contact the owner of this board for more information."
      );
      return false;
    }
    return true;
  }
}

export { UploadManager };
