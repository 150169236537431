import React from "react";

class Popup extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.containerRef = React.createRef();
    this.tailRef = React.createRef();

    this.state = {
      visible: props.visible,
      containerStyle: {},
      tailStyle: {}
    };
  }

  componentDidMount() {
    if (this.props.visible) {
      this.positioningFn();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({ visible: this.props.visible }, () => {
        if (this.props.visible) {
          this.positioningFn();
        }
      });
    }
  }

  isShowing() {
    return this.state.visible;
  }

  positioningFn() {
    if (this.props.type.includes("edit")) {
      this.setState({
        containerStyle: {
          marginTop: -45,
          left: "auto",
          right: 0
        }
      });
      return;
    }

    // Popup should be centered horizontally with button.
    // Unfortunately "left" is relative to the bar.
    const parentWidth = this.props.buttonWidth;

    // Include the margin in the calculation for extra positioning cues.
    const width = this.containerRef.current.offsetWidth;

    // Choose a center base point by default.
    let baseHorizontalPoint = parentWidth / 2;

    // If this popup is set to be positioned left or right,
    // use those respective points for calcuation.
    if (this.props.type.includes("left")) {
      baseHorizontalPoint = width / 2;
    } else if (this.props.type.includes("right")) {
      baseHorizontalPoint = parentWidth - width / 2;
    }

    const relativeLeft = baseHorizontalPoint - width / 2;

    let tailLeft =
      this.containerRef.current.offsetWidth / 2 -
      this.tailRef.current.offsetWidth / 2;

    // Position tail correctly if we have a right positioned popup.
    if (this.props.type.includes("right")) {
      tailLeft += width / 2 - parentWidth / 2;
    }
    if (this.props.type.includes("left")) {
      tailLeft -= width / 2 - parentWidth / 2;
    }

    // We're a bit looser with the vertical position.
    // It's set specifically in CSS, but you can pass a 'top' class.
    // Note that we subtract half the height of the tail for visual correctness.
    if (this.props.type.includes("top")) {
      const height = this.containerRef.outerHeight(true);
      const tailHeight = this.tailRef.outerHeight(true);
      this.setState({ containerStyle: { top: -(height + tailHeight / 2) } });
    }

    // If we want to keep this popup visible, then let's
    // pay attention to the window. On by default.
    let xdiff = 0;
    if (this.props.hideAutomatically) {
      const offset = this.containerRef.offset();
      const windowWidth = window.innerWidth;

      if (offset.left + width > windowWidth) {
        xdiff = offset.left + width - windowWidth;
      }
      if (offset.left < 0) {
        xdiff = offset.left;
      }
    }

    this.setState({
      containerStyle: { left: relativeLeft - xdiff },
      tailStyle: { left: tailLeft + xdiff }
    });
  }

  setTailSize(size) {
    this.setState({
      tailStyle: {
        borderWidth: size,
        borderBottomWidth: 0
      }
    });
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    return (
      <div
        ref={this.containerRef}
        style={this.state.containerStyle}
        className={`popup no-user-select ${this.props.type} ${this.props.className}`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <div className="content">{this.props.children}</div>
        <div
          className="tail"
          style={this.state.tailStyle}
          ref={this.tailRef}
        ></div>
      </div>
    );
  }
}

export { Popup };
